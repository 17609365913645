import CarTypeEnum from '@/enums/car/CarTypeEnum';

export interface Car {
  id: string;
  type: CarTypeEnum;
  license: string;
  description: string;
  engineType: string;
  freeSeats: number;
  loadCapacity: number;
  isDefaultCar: boolean;
  isTemporary: boolean;
  imageURL: string;
}

export interface CarState {
  cars: Array<Car>;
}

const state = (): CarState => ({
  cars: [],
});

export default state;

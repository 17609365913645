import { http } from '@/services/http/HTTPService';
import { APIGEE_DOMAIN, USER_SERVICE_URL, USER_PROPERTIES_URL } from '@/common/config';
import UserPostModel from '@/models/user/UserPostModel';
import { UserResponse } from '@/models/user/response/UserResponseModel';
import mapUserResponseToModel from '@/models/user/MapUserResponseToModel';
import UserPutModel from '@/models/user/UserPutModel';
import BaseResponse from '@/models/IBaseResponse';

const BASE_URL = `${APIGEE_DOMAIN}/${USER_SERVICE_URL}/users`;
const BASE_USER_PROPERTIES_URL = `${APIGEE_DOMAIN}/${USER_PROPERTIES_URL}/users`;

export const fetchImage = async (url: string): Promise<string> => {
  if (url && url.indexOf('Default') === -1) {
    const { data, status } = await http.get(url, {
      responseType: 'arraybuffer',
    });

    if (status === 200) return `data:image/png;base64,${Buffer.from(data, 'binary').toString('base64')}`;
  }

  return '';
};

export const createUser = async (user: UserPostModel): Promise<UserResponse> => {
  try {
    const { status } = await http.post(BASE_URL, user);

    return { status };
  } catch (error) {
    return { status: 400 };
  }
};

export const getUserById = async (id: string): Promise<UserResponse> => {
  try {
    const { status, data } = await http.get(`${BASE_URL}/${id}`);

    return { status, data: mapUserResponseToModel(data.result) };
  } catch (error) {
    return { status: 404 };
  }
};

export const updateUser = async (user: UserPutModel): Promise<UserResponse> => {
  try {
    const { status, data } = await http.put(BASE_URL, user);

    return { status, data: mapUserResponseToModel(data.result) };
  } catch (error) {
    return { status: 400, data: error.response };
  }
};

export const deleteUser = async (userId: string): Promise<BaseResponse> => {
  try {
    const { status } = await http.delete(`${BASE_URL}/${userId}`);

    return { status };
  } catch (error) {
    return { status: 400, message: error.message };
  }
};

export const deleteUserImage = async (userId: string): Promise<UserResponse> => {
  const { status, data } = await http.delete(`${BASE_URL}/${userId}/images`);

  return {
    status,
    data: mapUserResponseToModel(data.result),
  };
};

export const sendSms = async (detail: string, userId: string): Promise<BaseResponse> => {
  try {
    const { status } = await http.post(
      `${BASE_URL}/contacts/resend`,
      { detail },
      { params: { userId } },
    );

    return { status };
  } catch (error) {
    return { status: 400 };
  }
};

export const verifySms = async (
  Sms: { contactId: string; verificationCode: string },
  userId: string,
): Promise<BaseResponse> => {
  try {
    const { status } = await http.post(
      `${BASE_URL}/contacts/verify`,
      Sms,
      { params: { userId } },
    );

    return { status };
  } catch (error) {
    return { status: error.response.status };
  }
};

export const assignSite = async (userId: string, siteId: string): Promise<BaseResponse> => {
  try {
    const { status } = await http.post(
      `${BASE_URL}/${userId}/assign`,
      { siteId },
      { params: { userId } },
    );

    return { status };
  } catch (error) {
    return { status: error.response.status };
  }
};

export const assignSiteByToken = async (userId: string, siteToken: string): Promise<UserResponse> => {
  try {
    const { status, data } = await http.post(
      `${BASE_URL}/${userId}/assign`,
      { siteToken, externalId: userId },
    );

    return { status, data: mapUserResponseToModel(data.result) };
  } catch (error) {
    return { status: error.response.status };
  }
};

export const addUserProperty = async (
  userId: string,
  property: { key: string; value: string },
): Promise<BaseResponse> => {
  try {
    const { status } = await http.put(
      `${BASE_USER_PROPERTIES_URL}/${userId}/properties`,
      property,
    );

    return { status };
  } catch (error) {
    return { status: error.response.status };
  }
};

export const deleteUserProperty = async (
  userId: string,
  propertyKey: string,
): Promise<BaseResponse> => {
  try {
    const { status } = await http.delete(`${BASE_USER_PROPERTIES_URL}/${userId}/properties/${propertyKey}`);

    return { status };
  } catch (error) {
    return { status: error.response.status };
  }
};

export default createUser;

import { render, staticRenderFns } from "./OrganismForgotPassword.vue?vue&type=template&id=79ba9e76&scoped=true"
import script from "./OrganismForgotPassword.vue?vue&type=script&lang=ts"
export * from "./OrganismForgotPassword.vue?vue&type=script&lang=ts"
import style0 from "./OrganismForgotPassword.vue?vue&type=style&index=0&id=79ba9e76&prod&lang=scss"
import style1 from "./OrganismForgotPassword.vue?vue&type=style&index=1&id=79ba9e76&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79ba9e76",
  null
  
)

export default component.exports

import Vue from 'vue';
import AtomButton, { ButtonSize, ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';

export interface SelectButtonChangeEvent {
  id: string;
  isSelected: boolean;
}

export default Vue.extend({
  name: 'AtomSelectButton',
  components: { AtomButton, AtomSvgIcon },
  props: {
    id: {
      type: String,
      required: false,
    },
    isoCode: {
      type: String,
      required: true,
    },
    defaultName: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ButtonSize,
      ButtonVariant,
    };
  },
});

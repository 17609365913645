
import Vue from 'vue';
import { BForm } from 'bootstrap-vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomTextInput from '@/components/atoms/AtomInput/AtomTextInput.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import { InitResetPasswordModel } from '@twogo/cidaas/src/lib/models/user/request/InitResetPasswordModel';
import { initiateResetPassword } from '@twogo/cidaas/src/lib/CidaasService';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';

export default Vue.extend({
  name: 'OrganismForgotPassword',
  components: {
    AtomButton,
    AtomTextInput,
    AtomText,
    BForm,
    AtomSvgIcon,
  },
  data: () => ({
    ButtonVariant,
    email: '',
    wasSent: false,
    validFields: {
      email: false,
    },
    isLoading: false,
  }),
  methods: {
    debugToggleSent() {
      this.wasSent = !this.wasSent;
    },
    async initiatePasswordReset() {
      if (!this.validateInputs()) {
        return;
      }
      this.isLoading = true;
      const resetModel: InitResetPasswordModel = {
        email: this.email,
        resetMedium: 'email',
      };
      try {
        await initiateResetPassword(resetModel);
        this.wasSent = !this.wasSent;
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    validateInputs(): boolean {
      const validFields = Object.values(this.validFields).filter((field) => field).length;
      return validFields === Object.keys(this.validFields).length;
    },
    async resendEmail() {
      await this.initiatePasswordReset();
    },
  },
  computed: {
    resendLink(): string {
      return `/${this.$i18n.locale}/verification/resend`;
    },
  },
});

import { POI_SERVICE_URL, APIGEE_DOMAIN } from '@/common/config';
import { http } from '@/services/http/HTTPService';
import { PoiResponse, PoiResponseModel } from '@/models/geolocation/poi/response/PoiResponseModel';
import mapPoiResponseToModel from '@/models/geolocation/poi/MapPoiResponseToModel';
import PoiTypesEnum from '@/enums/geolocation/PoiTypesEnum';
import PoiPostModel from '@/models/geolocation/poi/PoiPostModel';
import IBaseResponse from '@/models/IBaseResponse';

const API_URL = `${APIGEE_DOMAIN}/${POI_SERVICE_URL}/pois`;

export const getPoi = async (userId: string, type: PoiTypesEnum = PoiTypesEnum.USER): Promise<PoiResponse> => {
  const options = {
    params: {
      ownerId: userId,
      type,
    },
  };
  const { status, data } = await http.get(API_URL, options);

  return {
    status,
    data: (data?.result || []).map(
      (intent: PoiResponseModel) => mapPoiResponseToModel(intent),
    ),
  };
};

export const postPoi = async (poi: PoiPostModel): Promise<PoiResponse> => {
  // Mock implementation
  const { status, data } = await http.post(API_URL, poi);

  return {
    status,
    data: [mapPoiResponseToModel(data.result || [])],
  };
};

export const editPoi = async (poi: PoiPostModel): Promise<PoiResponse> => {
  // Mock implementation
  const { status, data } = await http.put(API_URL, poi);

  return {
    status,
    data: [mapPoiResponseToModel(data.result || [])],
  };
};

export const deletePoi = async (poiId: string, type: PoiTypesEnum): Promise<IBaseResponse> => {
  const options = {
    params: {
      type,
    },
  };

  const { status } = await http.delete(`${API_URL}/${poiId}`, options);

  return { status };
};

export default getPoi;

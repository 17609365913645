import AgreementEnum from '@/enums/AgreementEnum';
import RolesEnum from '@/enums/rides/RolesEnum';
import { UserResponseModel } from '@/models/user/response/UserResponseModel';
import UserModel from './UserModel';

const mapUserResponseToModel = (user: UserResponseModel): UserModel => ({
  id: user.id,
  userId: user.userId,
  externalId: user.externalId,
  firstName: user.firstName,
  lastName: user.lastName,
  displayName: user.displayName,
  logonName: user.logonName,
  email: user.email,
  sms: user.sms || {
    id: '',
    verified: false,
    detail: '',
    type: '',
  },
  phoneNumber: user.phoneNumber || '+4074000000',
  imageURL: user.imageURL,
  decodedImage: '',
  gender: user.gender,
  homePOI: user.homePOI,
  follower: {
    intents: user.follower ? user.follower.intents : [],
    members: user.follower ? user.follower.members : [],
    communityId: user.follower ? user.follower.communityId : '' },
  following: {
    intents: user.following ? user.following.intents : [],
    members: user.following ? user.following.members : [],
    communityId: user.following ? user.following.communityId : '',
  },
  gamification: {
    points: {
      experience: user.gamification.points.experience,
      experienceLast30: user.gamification.points.experienceLast30,
      experienceThisMonth: user.gamification.points.experienceThisMonth,
      savedCO2: user.gamification.points.savedCO2,
      sharedDistance: user.gamification.points.sharedDistance,
      sharedDistanceKM: user.gamification.points.sharedDistanceKM,
    },
    status: user.gamification.status ? {
      experience: user.gamification.status.experience as AgreementEnum,
      leaderboard: user.gamification.status.leaderboard as AgreementEnum,
      lottery: user.gamification.status.lottery as AgreementEnum,
      sharedDistance: user.gamification.status.sharedDistance,
    } : undefined,
  },
  locale: user.locale,
  unitOfLengthLocale: user.unitOfLengthLocale,
  site: user.site,
  siteCompanyName: user.siteCompanyName,
  siteName: user.siteName,
  rideSettings: {
    matchingGroups: user.rideSettings ? user.rideSettings.matchingGroups : [],
    role: user.rideSettings ? user.rideSettings.role : RolesEnum.DRIVER_AND_PASSENGER,
    minSharedDurationPercentage: user.rideSettings ? user.rideSettings.minSharedDurationPercentage : 30,
    maxDetourMinutes: user.rideSettings ? user.rideSettings.maxDetourMinutes : 20,
    lightSequence: user.rideSettings ? user.rideSettings.lightSequence : false,
    automaticShiftOnly: user.rideSettings ? user.rideSettings.automaticShiftOnly : false,
    claimsPayment: user.rideSettings ? user.rideSettings.claimsPayment : false,
    offersPayment: user.rideSettings ? user.rideSettings.offersPayment : false,
  },
  features: user.features,
  payment: user.payment,
  availableMatchingGroups: user.availableMatchingGroups,
  b2cFree: user.b2cFree,
  properties: user.properties ? user.properties : {},
  dateLocale: user.dateLocale,
  timeLocale: user.timeLocale,
});

export default mapUserResponseToModel;

enum StepStatusesEnum {
  INACTIVE = 'Inactive',
  CURRENT = 'Current',
  COMPLETED = 'Completed',
  UNCOMPLETED = 'Uncompleted',
}

export enum StepperSteps {
  ROLES = 'ROLES',
  OUTBOUND_TRIP = 'OUTBOUND_TRIP',
  OUTBOUND_TRIP_TIME = 'OUTBOUND_TRIP_TIME',
  INBOUND_TRIP = 'INBOUND_TRIP',
  INBOUND_TRIP_TIME = 'INBOUND_TRIP_TIME',
  CAR_SELECTION = 'CAR_SELECTION',
  OTHER = 'OTHER',
  OVERVIEW = 'OVERVIEW',
}

export default StepStatusesEnum;

import { TimetableDataState, TimetableState as State } from '@/store/modules/timetable/state';
import { MutationTree } from 'vuex';

export type Mutations<S = State> = {
  setToken(state: S, payload: string): void;
  setTimetableData(state: S, payload: TimetableDataState): void;
};

const mutations: MutationTree<State> & Mutations = {
  setToken(state, payload) {
    state.token = payload;
  },
  setTimetableData(state, payload) {
    state.data = payload;
  },
};

export default mutations;


import Vue from 'vue';
import InlineSvg from 'vue-inline-svg';
import getImageURL from '@/services/assets/AssetsService';

export default Vue.extend({
  name: 'AtomSvgIcon',
  components: {
    InlineSvg,
  },
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: false,
      default: 'tg-svg-icon',
    },
    width: {
      type: String,
      required: false,
    },
    height: {
      type: String,
      required: false,
    },
    action: {
      type: Function,
      required: false,
    },
  },
  computed: {
    imageSource(): string {
      return getImageURL(this.imageSrc) || '';
    },
  },
});

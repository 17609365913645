
import Vue, { PropType } from 'vue';
import { BAlert } from 'bootstrap-vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';

export enum NotificationVariant {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  INFORMATION = 'info',
  CAMPAIGN_PROMOTION = 'promotion-campaign',
  CAMPAIGN_EVENT = 'event-campaign',
  CAMPAIGN_GAMIFICATION = 'gamification-campaign',
}

enum NotificationSize {
  DEFAULT = 'DEFAULT',
  BIG = 'BIG',
}

export default Vue.extend({
  name: 'AtomNotification',
  components: {
    BAlert,
    AtomSvgIcon,
  },
  props: {
    variant: {
      type: String as PropType<NotificationVariant>,
      validator: (val: NotificationVariant) => Object.values(NotificationVariant).includes(val),
      default: NotificationVariant.INFORMATION,
    },
    big: {
      type: Boolean,
      default: false,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    show: true,
    NotificationSize,
  }),
  computed: {
    getSize(): NotificationSize {
      return this.big ? NotificationSize.BIG : NotificationSize.DEFAULT;
    },
    hasClickListener(): boolean {
      return !!this.$listeners && !!this.$listeners.click;
    },
    getClasses(): { clickable: boolean; fullWidth: boolean } {
      return {
        clickable: this.hasClickListener,
        fullWidth: this.big,
      };
    },
  },
  methods: {
    close() {
      this.show = false;
      this.$emit('dismiss');
    },
    onClick() {
      this.$emit('click');
    },
    getImageURL(variant: string): string {
      switch (variant) {
        case NotificationVariant.DANGER:
        case NotificationVariant.WARNING:
          return 'icons/icon-triangle-warning.svg';
        case NotificationVariant.SUCCESS:
          return 'icons/icon-circle-check.svg';
        case NotificationVariant.INFORMATION:
          return 'icons/information.svg';
        case NotificationVariant.CAMPAIGN_PROMOTION:
          return 'icons/icon-megaphone.svg';
        case NotificationVariant.CAMPAIGN_EVENT:
          return 'icons/icon-calendar.svg';
        case NotificationVariant.CAMPAIGN_GAMIFICATION:
          return 'icons/icon-cup.svg';
        default:
          return 'icons/information.svg';
      }
    },
  },
  mounted() {
    if (this.hasClickListener) {
      (this.$refs.alertElement as Vue).$el.addEventListener('click', this.onClick);
    }
  },
  beforeDestroy() {
    if (this.hasClickListener) {
      (this.$refs.alertElement as Vue).$el.removeEventListener('click', this.onClick);
    }
  },
});

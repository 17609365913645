
import Vue from 'vue';
import InlineSvg from 'vue-inline-svg';
import AtomBaseInput from '@/components/atoms/AtomInput/AtomBaseInput.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';

export default Vue.extend({
  name: 'AtomPasswordInput',
  extends: AtomBaseInput,
  data() {
    return {
      showPlaintextPassword: false,
    };
  },
  // Using InlineSvg here as AtomSvgIcon creates caching problem -> no icons will change
  // TODO: Investigate
  components: { AtomSvgIcon, InlineSvg },
});

import {
  Car,
  CarState as State,
} from '@/store/modules/car/state';
import { MutationTree } from 'vuex';

export type Mutations<S = State> = {
  addCars(state: S, payload: Car[]): void;
  addCar(state: S, payload: Car): void;
  removeCar(state: S, payload: string): void;
  removeAllCars(state: S): void;
  setAvailableSeats(state: S, payload: { id: string; value: number }): void;
}

const mutations: MutationTree<State> & Mutations = {
  addCars(state, cars: Car[]) {
    const defaultCar = cars.find((car) => car.isDefaultCar);
    state.cars = defaultCar ? [defaultCar, ...cars.filter(({ isDefaultCar }) => !isDefaultCar)] : cars;
  },
  addCar(state, payload: Car) {
    if (payload.isDefaultCar) {
      state.cars = state.cars.map((car) => ({ ...car, isDefaultCar: false }));
      state.cars.unshift(payload);
    } else {
      state.cars.push(payload);
    }
  },
  updateCar(state, payload: Car) {
    if (payload.isDefaultCar) {
      state.cars = state.cars.map((car) => ({ ...car, isDefaultCar: false }));
    }
    Object.assign(state.cars.filter((car) => car.id === payload.id)[0], payload);
  },
  removeCar(state, payload: string) {
    state.cars = state.cars.filter((car) => car.id !== payload);
  },
  removeAllCars(state) {
    state.cars = [];
  },
  setAvailableSeats(state, payload: { id: string; value: number }) {
    state.cars = state.cars.map((car) => {
      if (car.id === payload.id) {
        return { ...car, freeSeats: payload.value };
      }
      return car;
    });
  },
};

export default mutations;

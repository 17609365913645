
import Vue from 'vue';
import { BDropdown } from 'bootstrap-vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';

export default Vue.extend({
  name: 'MoleculeDropdown',
  components: {
    AtomButton,
    BDropdown,
  },
  props: {
    right: {
      type: Boolean,
      default: false,
    },
    dropup: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    ButtonVariant,
    dropdownShown: false,
  }),
  mounted() {
    this.$root
      .$on('bv::dropdown::show', () => {
        this.dropdownShown = true;
      })
      .$on('bv::dropdown::hide', () => {
        this.dropdownShown = false;
      });
  },
});

/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex, { createLogger } from 'vuex';
import rideIntent from './modules/ride-intent';
import rides from './modules/rides';
import user from './modules/user';
import car from './modules/car';
import createCar from './modules/create-car';
import poi from './modules/poi';
import timetable from './modules/timetable';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

// TODO: investigate this type error
const store = new Vuex.Store({
  modules: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    rideIntent, // Wizard
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    rides,
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    user,
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    car,
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    createCar,
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    poi,
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    timetable,
  },
  strict: debug,
  plugins: [createLogger()],
});

export default store;
export const useStore = () => store;


import Vue from 'vue';

export default Vue.extend({
  name: 'AtomLogo',
  props: {
    logoSrc: {
      type: String,
      required: true,
    },
  },
});

// eslint-disable-next-line import/no-cycle
import { LocationModel } from '@/models/timetable/LocationModel';
import { TimetableModel } from '@/models/timetable/TimetableModel';

export interface ArrivalState {
  date: string;
  role: string;
  isMatched: boolean;
  freeSeats: number;
  createUrl: string;
  location: {
    zip: string;
    city: string;
    district?: string;
  };
  isBusinessTrip: boolean;
  id: number;
}

export interface DepartureState {
  date: string;
  role: string;
  isMatched: boolean;
  freeSeats: number;
  createUrl: string;
  location: {
    zip: string;
    city: string;
    district?: string;
  };
  isBusinessTrip: boolean;
  id: number;
}

export interface SitesState {
  siteId: number;
  siteLocation: string;
  siteName: string;
}

export interface TimetableDataState {
  arrivals: TimetableModel[];
  departures: TimetableModel[];
  location: LocationModel;
}

export interface TimetableState {
  data: TimetableDataState;
  token: string;
}

const state = (): TimetableState => ({
  data: {
    arrivals: [],
    departures: [],
    location: {
      address: '',
      name: '',
      sites: {
        siteId: 0,
        siteLocation: '',
        siteName: '',
      }[0],
    },
  },
  token: '',
});

export default state;

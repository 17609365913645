import { getUserSupportedLocale } from '@/services/translation/TranslationService';
import { getUserProfile } from '@twogo/cidaas/src/lib/CidaasService';
import codeParser from '@twogo/cidaas/src/lib/utils/CodeParser';
import store from '@/store';
import { NavigationGuardNext, Route } from 'vue-router';
import RouteNamesEnum from '../RouteNamesEnum';

export const ACCESS_TOKEN = 'access_token';

const publicRoutes = [
  RouteNamesEnum.LOGIN_REGISTRATION,
  RouteNamesEnum.SETTINGS_HELP,
  RouteNamesEnum.LOTTERY_TOP,
  RouteNamesEnum.TIMETABLE_CONFIG_KIOSK,
  RouteNamesEnum.TIMETABLE_CONFIG_PORTAL,
  RouteNamesEnum.TIMETABLE_KIOSK,
  RouteNamesEnum.TIMETABLE_PORTAL,
  RouteNamesEnum.TIMETABLE_POI_KIOSK,
  RouteNamesEnum.TIMETABLE_POI_PORTAL,
];

export const authGuard = async (to: Route, _from: Route, next: NavigationGuardNext) => {
  let accessToken = window.localStorage.getItem(ACCESS_TOKEN);

  if (window.location.hash.includes('access_token=')) {
    accessToken = codeParser();

    store.commit('user/setAccessToken', accessToken);
    window.localStorage.setItem(ACCESS_TOKEN, accessToken);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  if (accessToken && (!store.state.user.cidaasUserData || !store.state.user.accessToken)) {
    store.commit('user/setAccessToken', accessToken);
    try {
      const userProfile = await getUserProfile(accessToken);

      if (userProfile.error) {
        throw new Error(userProfile.error);
      }
      store.commit('user/setCidaasUserData', userProfile);
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (!store.state.user.user && userProfile.customFields?.twogoRegistrationCompleted === 'true') {
        store.dispatch('user/fetchUser', userProfile.sub);
      }
    } catch (error) {
      // Remove AccessToken from local storage if token is invalid or there is a other error
      store.commit('user/setAccessToken', undefined);
      store.commit('user/setUser', null);
      window.localStorage.removeItem(ACCESS_TOKEN);
      next({
        path: `/${getUserSupportedLocale()}/login`,
      });
    }
  }

  // If the User is not logged in redirect him to the LoginRegistration page
  // All pages that a not logged in user should access need to be added here
  if (!accessToken && !publicRoutes.includes(to.name as RouteNamesEnum)) {
    try {
      sessionStorage.setItem('authMiddlewareTo', JSON.stringify(to));
    } catch (error) {
      sessionStorage.removeItem('authMiddlewareTo');
    }

    next({
      path: `/${getUserSupportedLocale()}/login`,
      query: to.query,
    });
  } else if (accessToken && (to.name as RouteNamesEnum) === RouteNamesEnum.LOGIN_REGISTRATION) {
    next({
      name: RouteNamesEnum.HOME,
      params: { locale: to.params.locale || getUserSupportedLocale() },
    });
  } else {
    next();
  }
};

export default authGuard;

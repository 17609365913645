
import Vue, { PropType } from 'vue';

export enum AvatarSize {
  SMALL = 'SMALL',
  DEFAULT = 'DEFAULT',
  BIG = 'BIG',
}

export default Vue.extend({
  name: 'AtomAvatar',
  data: () => ({
    AvatarSize,
  }),
  props: {
    size: {
      type: String as PropType<AvatarSize>,
      validator: (val: AvatarSize) => Object.values(AvatarSize).includes(val),
      default: AvatarSize.DEFAULT,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    company: {
      type: String,
      required: false,
    },
    imgSrc: {
      type: String,
      required: false,
    },
  },
  computed: {
    showCompany(): boolean {
      return this.size !== AvatarSize.SMALL && !!this.company;
    },
    getInitials(): string {
      const splitName = this.name.split(' ');
      if (splitName.length === 1) return splitName[0].charAt(0);
      if (splitName.length >= 2) return `${splitName[0].charAt(0)}${splitName[1].charAt(0)}`;
      return ' ';
    },
  },
});

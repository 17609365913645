
import Vue, { PropType } from 'vue';
import InlineSvg from 'vue-inline-svg';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import { EMAIL_REGEX } from '@/common/config';

export interface InputRequirement {
  regex: RegExp;
  description: string;
}

interface InputRequirementDisplay {
  status: boolean;
  description: string;
}

export default Vue.extend({
  name: 'AtomInput',
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    requiredError: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    value: {
      type: String,
    },
    info: {
      type: String,
      default: '',
    },
    instruction: {
      type: String,
      default: '',
    },
    requirements: {
      type: Array as PropType<InputRequirement[]>,
    },
    validateOnInit: {
      type: Boolean,
      default: false,
    },
    upperCase: {
      type: Boolean,
      default: false,
    },
    length: {
      type: Number,
      default: Infinity,
    },
  },
  data() {
    return {
      isActive: false,
      isInvalid: false,
      inputValue: this.value,
      showPlaintextPassword: false,
      inputRequirementDisplay: [] as InputRequirementDisplay[],
    };
  },
  // Using InlineSvg here as AtomSvgIcon creates caching problem -> no icons will change
  // TODO: Investigate
  components: { AtomSvgIcon, InlineSvg },
  watch: {
    value: 'initInput',
    required: 'validateInput',
  },
  methods: {
    initInput(val: string) {
      this.inputValue = val;
      if (this.inputValue) this.validateInput();
    },
    onInputInput(event: InputEvent) {
      const target = event.target as HTMLInputElement;
      if (this.upperCase) {
        const selection = target.selectionStart;
        target.value = target.value.toUpperCase();
        if (selection) target.setSelectionRange(selection, selection);
      }
      this.$emit('input', target.value);
      this.validateInput();
    },
    onInputFocus(event: FocusEvent) {
      this.$emit('focus', event);
      this.isActive = true;
    },
    onInputClick(event) {
      this.$emit('click', event);
      this.isActive = true;
    },
    onInputBlur(event: FocusEvent) {
      this.$emit('blur', event);
      this.isActive = false;
      this.validateInput();
    },
    validateInput() {
      if (this.requirements) {
        let failCount = 0;
        this.inputRequirementDisplay = [];
        this.requirements.forEach((requirement) => {
          const status = requirement.regex.test(this.inputValue);
          if (!status) {
            failCount += 1;
          }
          this.inputRequirementDisplay.push({
            status,
            description: requirement.description,
          });
        });
        this.setInvalid(failCount > 0);
      } else {
        switch (this.type) {
          case 'email': {
            this.setInvalid(!EMAIL_REGEX.test(this.inputValue));
            break;
          }
          default: {
            if (this.required && !this.inputValue) {
              this.setInvalid(true);
            } else {
              this.setInvalid(false);
            }
            break;
          }
        }
      }
    },
    setInvalid(isInvalid: boolean) {
      this.isInvalid = isInvalid;
      this.$emit('validate', !isInvalid);
    },
    getClasses() {
      return {
        'input-field': true,
        'input-field--active': this.isActive,
        'input-field--filled': !!this.inputValue,
        'input-field--disabled': this.disabled,
        'input-field--invalid': this.isInvalid,
      };
    },
  },
  mounted() {
    if (this.validateOnInit) {
      this.validateInput();
    }
  },
});

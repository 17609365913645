import { http } from '@/services/http/HTTPService';
import { APIGEE_DOMAIN, CAR_SERVICE_URL } from '@/common/config';
import { CarsResponse, CarResponseModel, CarResponse } from '@/models/car/response/CarResponseModel';
import { PostCarModel, UpdateCarModel } from '@/models/car/CarModel';
import mapCarResponseToModel from '@/models/car/MapCarResponseToModel';

export const fetchCarImage = async (url: string): Promise<string> => {
  if (url.indexOf('Default') === -1) {
    const { data, status } = await http.get(url, {
      responseType: 'arraybuffer',
    });

    if (status === 200) return `data:image/png;base64, ${Buffer.from(data, 'binary').toString('base64')}`;
  }

  return '';
};

export const getAllCars = async (
  userId: string,
): Promise<CarsResponse> => {
  const options = {
    params: {
      userId,
    },
  };
  const { status, data } = await http.get(
    `${APIGEE_DOMAIN}/${CAR_SERVICE_URL}/cars`,
    options,
  );
  const cars = (data.result || []).map(
    async (car: CarResponseModel) => mapCarResponseToModel(car, fetchCarImage),
  );

  return {
    status,
    data: await Promise.all(cars),
  };
};

export const createCar = async (
  userId: string,
  car: PostCarModel,
): Promise<CarResponse> => {
  const options = {
    params: {
      userId,
    },
  };
  const { status, data } = await http.post(
    `${APIGEE_DOMAIN}/${CAR_SERVICE_URL}/cars`,
    car,
    options,
  );
  return {
    status,
    data: await mapCarResponseToModel(data.result, fetchCarImage),
  };
};

export const updateCar = async (
  userId: string,
  car: UpdateCarModel,
): Promise<CarResponse> => {
  const options = {
    params: {
      userId,
    },
  };
  const { status, data } = await http.put(
    `${APIGEE_DOMAIN}/${CAR_SERVICE_URL}/cars`,
    car,
    options,
  );
  return {
    status,
    data: await mapCarResponseToModel(data.result, fetchCarImage),
  };
};

export const deleteCarImage = async (carId: string): Promise<CarResponse> => {
  const { status, data } = await http.delete(`${APIGEE_DOMAIN}/${CAR_SERVICE_URL}/cars/${carId}/images`);

  return {
    status,
    data: await mapCarResponseToModel(data.result, fetchCarImage),
  };
};

export const deleteCarById = async (carId: string): Promise<CarResponse> => {
  try {
    const { status } = await http.delete(`${APIGEE_DOMAIN}/${CAR_SERVICE_URL}/cars/${carId}`);

    return { status };
  } catch (error) {
    return { status: error.response.status || 404 };
  }
};

enum FeatureEnum {
  BUSINESSTRIP = 'BusinessTrip',
  MANAGEPOOLCARS = 'ManagePoolCars',
  TEMPORARYCARS = 'TemporaryCars',
  SOCIALSHARINGOPTOUT = 'SocialSharingOptOut_Twitter',
  SOCIALSHARINGOPTOUT_GOOGLE = 'SocialSharingOptOut_Google',
  SOCIALSHARINGOPTOUT_FACEBOOK = 'SocialSharingOptOut_Facebook',
  SOCIALSHARINGOPTOUT_EMAIL = 'SocialSharingOptOut_eMail',
  GAMIFICATION_UI_LEADERBOARD = 'Gamification_UILeaderboard',
  GAMIFICATIONOPTOUT_LOTTERY = 'GamificationOptOut_Lottery',
  GAMIFICATIONOPTOUT_EXPERIENCE = 'GamificationOptOut_Experience',
  UPGRADEB2C = 'UpgradeB2C',
  HIDECOMPANYINFORMATION = 'HideCompanyInformation',
  BILLING_DISPLAYCOSTS = 'Billing_DisplayCosts',
  BILLING_DONATION = 'Billing_Donation',
  GAMIFICATIONOPTOUT_SHAREDDISTANCE = 'GamificationOptOut_SharedDistance',
  RATINGS_ENABLED = 'RatingsEnabled',
  PARKING_OPTIONS = 'PreferredParkingPlaceReservation',
  SIXT_BANNER = 'SixtBanner',
}

export default FeatureEnum;

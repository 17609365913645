import { http } from '@/services/http/HTTPService';
import { APIGEE_DOMAIN, RIDE_SERVICE_URL } from '@/common/config';
import { RideResponse } from '@/models/ride/response/RideResponse';
import mapRideResponseToModel from '@/models/ride/MapRideResponseToModel';

const BASE_URL = `${APIGEE_DOMAIN}/${RIDE_SERVICE_URL}`;

export const getRides = async (userId: string): Promise<RideResponse> => {
  const options = {
    params: {
      userId,
    },
  };
  try {
    const { status, data } = await http.get(BASE_URL, options);

    return {
      status,
      data: (data?.result || []).map((ride) => mapRideResponseToModel(ride, userId)),
    };
  } catch (error) {
    return { status: error?.response?.status || 404, data: [] };
  }
};

export default getRides;

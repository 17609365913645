import BlockedUserModel from '@/models/community/BlockedUserModel';
import { RideUserModel } from '@/models/ride/RideModel';
import UserModel from '@/models/user/UserModel';

export interface CidaasUserData {
  name: string;
  familyName: string;
  givenName: string;
  nickName: string;
  preferredUsername: string;
  email: string;
  locale: string;
  provider: string;
  sub: string;
  lastAccessedAt: number;
  lastUsedIdentityId: string;
  updatedAt: number;
  userStatus: string;
  roles: Array<string>;
  groups: {groupId: string; roles: string[]}[];
  customFields: {
    twogoRegistrationCompleted: any;
    registrationToken?: string;
    [key: string]: any;
  };
}

export interface UserState {
  accessToken: string | null;
  accessCode: string | null;
  cidaasUserData: CidaasUserData | null;
  user: UserModel | null;
  otherUsers: RideUserModel[];
  avatars: { [key: string]: string };
  blockedUsers: BlockedUserModel[];
}

const state = (): UserState => ({
  accessToken: null,
  accessCode: null,
  cidaasUserData: null,
  user: null,
  otherUsers: [],
  avatars: {},
  blockedUsers: [],
});

export default state;


import Vue from 'vue';
import { BForm } from 'bootstrap-vue';
import AtomNotification from '@/components/atoms/AtomNotification.vue';
import AtomText from '@/components/atoms/AtomText.vue';

export default Vue.extend({
  name: 'OrganismVerificationSent',
  components: {
    AtomNotification,
    AtomText,
    BForm,
  },
  data: () => ({
    password: '',
  }),
});

import {
  CreateCar,
} from '@/store/modules/create-car/state';
import { GetterTree } from 'vuex';

export type Getters = {
  getAll(state: CreateCar): (CreateCar);
  getId(state: CreateCar): (string | null);
  getImage(state: CreateCar): (string | null);
  getLicensePlate(state: CreateCar): (string | null);
  getDescription(state: CreateCar): (string | null);
  getEngineType(state: CreateCar): (string | null);
  getFreeSeats(state: CreateCar): (number);
  getIsDefaultCar(state: CreateCar): (boolean);
  getSkipImage(state: CreateCar): (boolean);
}

const getters: GetterTree<CreateCar, CreateCar> & Getters = {
  getAll: (state) => state,
  getId: (state) => state.id,
  getImage: (state) => state.image,
  getLicensePlate: (state) => state.licensePlate,
  getDescription: (state) => state.description,
  getEngineType: (state) => state.engineType,
  getFreeSeats: (state) => state.freeSeats,
  getIsDefaultCar: (state) => state.isDefaultCar,
  getSkipImage: (state) => state.skipImage,
};

export default getters;

import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueSelect from 'vue-select';
import VueMeta from 'vue-meta';
import VueLottiePlayer from 'vue-lottie-player';
import i18nRoute from '@/services/translation/TranslationService';
import FirebaseService from '@/services/firebase/FirebaseService';
import initialize from '@twogo/cidaas/src';
import cidaasConfig from '@/common/CidaasConfig';
import firebase from 'firebase/app';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';
import '@/assets/scss/main.scss';
import { addCustomVueDirectives } from '@/common/VueDirectives';

if (!Vue.config.devtools && firebase.messaging.isSupported()) {
  const firebaseService = new FirebaseService();
  firebaseService.requestPermission();
}

if (cidaasConfig.authority !== '') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore-next-line
  initialize(cidaasConfig);
} else {
  console.log('Error while initializing cidaas service, check your env variables');
}

Vue.config.productionTip = false;
Vue.prototype.$i18nRoute = i18nRoute;

Vue.use(BootstrapVue);
Vue.use(VueLottiePlayer);
Vue.use(VueMeta);
Vue.component('v-select', VueSelect);

addCustomVueDirectives();

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

import { render, staticRenderFns } from "./MoleculeLanguageSelectButton.vue?vue&type=template&id=67d2d74a&scoped=true"
import script from "./MoleculeLanguageSelectButton.vue?vue&type=script&lang=ts"
export * from "./MoleculeLanguageSelectButton.vue?vue&type=script&lang=ts"
import style0 from "./MoleculeLanguageSelectButton.vue?vue&type=style&index=0&id=67d2d74a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67d2d74a",
  null
  
)

export default component.exports
import { ENVIRONMENT, EnvironmentEnum } from '@/common/config';

/**
 * List of App feature to be hidden during development
 */
export enum Features {
  SETTINGS_REGION = 'SETTINGS_REGION',
  SETTINGS_RIDE = 'SETTINGS_RIDE',
  SETTINGS_SECURITY = 'SETTINGS_SECURITY',
  SETTINGS_NOTIFICATIONS = 'SETTINGS_NOTIFICATIONS',
  LOGO_PADDING = 'LOGO_PADDING',
  NOTIFICATION_CENTER = 'NOTIFICATION_CENTER', // The Bell on the header, chat, etc
  LEADER_BOARD_UI = 'LEADER_BOARD_UI',
  GAMIFICATION_LOTTERY = 'GAMIFICATION_LOTTERY',
  RIDES_SEARCH = 'RIDES_SEARCH',
  RIDES_SHARING = 'RIDES_SHARING',
}

/**
 * List of the default environments to show the features.
 */
const defaultEnvs = [
  EnvironmentEnum.DEVELOPMENT,
];

/**
 * Here can be added the envs where a specific feature can be enabled
 */
const enabledOn = {
  [Features.SETTINGS_REGION]: [...defaultEnvs, EnvironmentEnum.STAGING, EnvironmentEnum.PRODUCTION],
  [Features.SETTINGS_RIDE]: [...defaultEnvs, EnvironmentEnum.STAGING, EnvironmentEnum.PRODUCTION],
  [Features.SETTINGS_SECURITY]: [...defaultEnvs, EnvironmentEnum.STAGING, EnvironmentEnum.PRODUCTION],
  [Features.SETTINGS_NOTIFICATIONS]: [...defaultEnvs, EnvironmentEnum.STAGING, EnvironmentEnum.PRODUCTION],
  [Features.LOGO_PADDING]: [...defaultEnvs, EnvironmentEnum.STAGING, EnvironmentEnum.PRODUCTION],
  [Features.NOTIFICATION_CENTER]: [...defaultEnvs, EnvironmentEnum.STAGING, EnvironmentEnum.PRODUCTION],
  [Features.LEADER_BOARD_UI]: [...defaultEnvs, EnvironmentEnum.STAGING, EnvironmentEnum.PRODUCTION],
  [Features.GAMIFICATION_LOTTERY]: [...defaultEnvs, EnvironmentEnum.STAGING, EnvironmentEnum.PRODUCTION],
  [Features.RIDES_SEARCH]: [...defaultEnvs, EnvironmentEnum.STAGING, EnvironmentEnum.PRODUCTION],
  [Features.RIDES_SHARING]: [...defaultEnvs, EnvironmentEnum.STAGING, EnvironmentEnum.PRODUCTION],
};

const isEnvReady = (feature: Features, env = ENVIRONMENT): boolean => enabledOn[feature].includes(env);

export default isEnvReady;

import RideTypeEnum from '@/enums/rides/RideTypeEnum';
import RideSummaryModel from '@/models/ride/RideSummaryModel';
import { RideModel } from './RideModel';

const mapRideToSummaryModel = (ride: RideModel): RideSummaryModel => ({
  matchId: ride.matchId,
  intentId: ride.intentId,
  origin: ride.origin,
  destination: ride.destination,
  type: RideTypeEnum.MATCHED,
  eventType: ride.eventType,
  role: ride.role,
  carLicensePlate: ride.sections[0].vehicle.sign,
  occupants: ride.users,
  tooltip: '',
  hasParkingLotAssigned: false,
});

export default mapRideToSummaryModel;

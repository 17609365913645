import axios from 'axios';
import { TIMETABLE_SERVICE_URL } from '@/common/config';
import mapTimetableResponseToModel from '@/models/timetable/MapDeparturesResponseToModel';
import { TimetableResponse } from '@/models/timetable/response/TimetableResponseModel';

const address = `${TIMETABLE_SERVICE_URL}`;

export enum TimetableType {
  FIND_BY_SITE_ID = 'findBySiteId',
  FIND_BY_SITE_POI_ID = 'findBySitePoiId',
  FIND_BY_CONFIGURATION_ID = 'findByConfigurationId',
}

export const getToken = async (siteId: string, type: TimetableType): Promise<string> => {
  try {
    const { data } = await axios.post(
      address,
      {
        method: type,
        params: [siteId],
        id: 1,
      },
      {
        headers: {
          'X-CSRF-Token': 'Fetch',
        },
      },
    );
    const token = data?.result?.header?.value ?? '' as string;

    return token;
  } catch (error) {
    return '';
  }
};

export const getDeparturesAndArrivals = async (
  siteId: string,
  token: string,
  type: TimetableType,
): Promise<TimetableResponse> => {
  try {
    const { status, data } = await axios.post(
      address,
      {
        method: type,
        params: [siteId],
        id: 1,
      },
      {
        headers: {
          'X-CSRF-Token': token, // state.token,
        },
      },
    );

    return {
      status,
      data: {
        arrivals: data.result[0].arrivals.map(mapTimetableResponseToModel),
        departures: data.result[0].departures.map(mapTimetableResponseToModel),
        location: data.result[0].location,
      },
    };
  } catch (error) {
    // console.log(error);
    return {
      status: 400,
      data: {
        arrivals: [],
        departures: [],
        location: {
          address: '',
          name: '',
          sites: {
            siteId: 0,
            siteLocation: '',
            siteName: '',
          }[0],
        },
      },
    };
  }
};

export enum EnvironmentEnum {
  PRODUCTION = 'prod',
  STAGING = 'staging',
  DEVELOPMENT = 'dev',
}
/** The latest App version TEST-5 */
export const APP_VERSION = '3.5.6';

export const ENVIRONMENT = process.env.VUE_APP_ENVIRONMENT as EnvironmentEnum; // 'dev', 'staging', 'prod'

export const SUPPORTED_LOCALES = ['de', 'en-US', 'en-GB', 'bg', 'cs', 'da', 'el', 'es', 'et', 'fi', 'fr', 'hr', 'hu', 'it', 'lb', 'lt', 'lv', 'nl', 'pl', 'pt', 'ro', 'ru', 'sk', 'sl', 'sr', 'sv', 'tr'];
export const DEFAULT_LOCALE = 'en-US';
export const PASSWORD_REGEX = [/^\S{8,}$/, /(?=.*?[A-Z])(?=.*?[a-z])/, /(?=.*?[0-9])/];
export const EMAIL_REGEX = /[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]+/;
export const NAME_REGEX = /^(?:[\p{L}]+(?: |\. |\.$|-|')?)+$/u;
export const LOCATION_REGEX = /^[a-zA-Z0-9_][a-zA-Z0-9_]*$/;
// hardcode the api settings for testing purposes
export const HERE_MAPS_API_KEY = process.env.VUE_APP_HERE_MAPS_API_KEY;
export const GEOSERVICE_BASE_URL = 'https://twogo-geo-service-staging.apps.01.cf.eu01.stackit.cloud/api/geo-service/v1'; // process.env.VUE_APP_GEOSERVICE_BASE_URL;
export const GEOSERVICE_API_KEY = '1e6f4407-f330-4d4a-b849-e0874ad411d9'; // process.env.VUE_APP_GEOSERVICE_API_KEY;
export const MAX_NR_OF_WAYPOINTS = 5;

export const APIGEE_DOMAIN = process.env.VUE_APP_APIGEE_DOMAIN;
export const TIMETABLE_SERVICE_URL = process.env.VUE_APP_TIMETABLE_SERVICE_URL;
export const RIDE_INTENT_SERVICE_URL = 'twogo-rideintentservice/v1/intents';
export const RIDE_SERVICE_URL = 'twogo-tripservice/v1/trips';
export const CAR_SERVICE_URL = 'twogo-carservice/v1';
export const SITE_SERVICE_URL = 'twogo-siteservice/v1';
export const POI_SERVICE_URL = 'twogo-poiservice/v1';
export const GEOLOCATION_SERVICE_URL = 'twogo-geolocationservice/v1';
export const USER_SERVICE_URL = 'twogo-userdataservice/v1';
export const USER_PROPERTIES_URL = 'twogo-userpropertyservice/v1';
export const COMMUNITY_SERVICE_URL = 'twogo-communityservice/v1';
export const NOTIFICATIONS_SERVICE_URL = 'twogo-nconfigservice/v1';
export const GAMIFICATION_SERVICE_URL = 'twogo-gamificationservice/v1/';
export const RATING_SERVICE_URL = 'twogo-ratingservice/v1';
export const CAMPAIGNS_SERVICE_URL = '/twogo-campaignservice/v1';
export const RIDE_SEARCH_SERVICE_URL = '/twogo-proposalservice/v1';

export const NEWSLETTER_BASE_URL = process.env.VUE_APP_NEWSLETTER_API_BASE_URL;
export const NEWSLETTER_API_KEY = process.env.VUE_APP_NEWSLETTER_API_KEY;

export const RIDE_INTENT_BASE_URL = 'https://twogo-ride-service-dev.apps.01.cf.eu01.stackit.cloud/api/ride-service/v1/intents';
export const RIDE_SUMMARY_BASE_URL = 'https://twogo-ride-service-dev.apps.01.cf.eu01.stackit.cloud/api/ride-service/v1/rides/summary';

export const BRANDING_DEFAULT_COMPANY_ID = process.env.VUE_APP_DEFAULT_BRANDING_COMPANY_ID; // [265] for testing: 12917, 28, 46750. 529, 15

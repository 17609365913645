import BlockedUserModel from './BlockedUserModel';
import { BlockedUserResponseModel } from './response/BlockedUserResponseModel';

const mapBlockedUserResponseToModel = (b: BlockedUserResponseModel): BlockedUserModel => ({
  firstName: b.firstName,
  lastName: b.lastName,
  id: b.blacklistEntryId,
});

export default mapBlockedUserResponseToModel;

import { ActionContext as DefaultActionContext, ActionTree } from 'vuex';
import { TimetableState as State } from '@/store/modules/timetable/state';
import { Mutations } from '@/store/modules/poi/mutations';
import { getToken, getDeparturesAndArrivals, TimetableType } from '@/api/timetable/timetableApi';

type ActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
  ): ReturnType<Actions[K]>;
} & Omit<DefaultActionContext<State, State>, 'commit | dispatch'>;

export interface Actions {
  fetchToken({ commit }: ActionContext, payload: { siteId: string; type: TimetableType }): void;
  fetchData({ commit }: ActionContext, payload: { siteId: string; type: TimetableType }): void;
}

const actions: ActionTree<State, State> & Actions = {
  async fetchToken({ commit }, payload) {
    const { siteId, type } = payload;
    const XCSRFToken = await getToken(siteId, type);
    commit('setToken', XCSRFToken);
  },
  async fetchData({ commit, state }, payload) {
    const { siteId, type } = payload;
    const { token } = state;
    const { data } = await getDeparturesAndArrivals(siteId, token, type);
    commit('setTimetableData', data);
  },
};

export default actions;

import {
  Car,
  CarState as State,
} from '@/store/modules/car/state';
import { GetterTree } from 'vuex';

export type Getters = {
  getAllCars(state: State): (Array<Car> | null);
  getDefaultCar(state: State): Car|null;
  getCarById(state: State): Function;
}

const getters: GetterTree<State, State> & Getters = {
  getAllCars: (state) => state.cars,
  getDefaultCar: (state) => state.cars.find((car) => car.isDefaultCar) || null,
  getCarById(state): Function {
    return (id: string) => state.cars.find((car) => car.id === id);
  },
};

export default getters;

/**
 * Get asset path
 *
 * @param {String} imagePath Path of the asset relative to "src/assets/images/"
 *
 * @example getImageUrl("icons/switch") will use by default the .svg extension.
 *          getImageUrl("icons/switch.svg") will use the indicated extension.
 *
 * @returns {String} The asset path.
 */
export const getImageUrl = (imagePath: string): string => {
  const images = require.context(
    '../../assets/images/',
    true,
  );
  const [asset, extension = 'svg'] = imagePath.split('.');
  const relativePath = `./${asset}.${extension}`;

  if (images.keys().includes(relativePath)) {
    return images(relativePath);
  }

  return '';
};

export default getImageUrl;

import { PoiModel } from '@/models/geolocation/poi/PoiModel';

export interface PoiState {
  poi: PoiModel[];
}

const state = (): PoiState => ({
  poi: [],
});

export default state;

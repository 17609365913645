import SourceEnum from '@/enums/geolocation/SourceEnum';
import PoiTypesEnum from '@/enums/geolocation/PoiTypesEnum';
import { PoiModel } from './PoiModel';
import { PoiResponseModel } from './response/PoiResponseModel';

const mapPoiResponseToModel = (poi: PoiResponseModel): PoiModel => ({
  id: poi.poiId,
  ownerId: poi.ownerId,
  addressType: poi.type as SourceEnum,
  source: SourceEnum.FAVORITES,
  name: poi.name,
  comment: poi.comment,
  addressText: poi.addressText,
  address: {
    label: poi.address.label,
    countryCode: poi.address.countryCode,
    countryName: poi.address.countryName,
    city: poi.address.city,
    street: poi.address.street,
    postalCode: poi.address.postalCode,
    houseNumber: poi.address.houseNumber,
    county: poi.address.county,
    district: poi.address.district,
  },
  location: {
    lat: poi.location.latitude,
    lng: poi.location.longitude,
  },
  poiType: poi.type as PoiTypesEnum,
  parkingSpaces: poi.parkingSpaces,
});

export default mapPoiResponseToModel;

import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import StepStatusesEnum, { StepperSteps } from '@/enums/stepper/StepperEnum';
import RolesEnum from '@/enums/rides/RolesEnum';
import DateValidations from '@/enums/date/DateValidationEnum';
import CarTypeEnum from '@/enums/car/CarTypeEnum';
import GenericAddressModel from '@/models/geolocation/GenericAddressModel';

export enum TripFrequency {
  NEVER = 'NEVER',
  RECURRENT = 'WEEKLY',
  SPECIFIC_DAYS = 'SPECIFIC_DAYS',
}

export interface Step {
  id: string;
  title: string;
  status: StepStatusesEnum;
  componentName: string;
  isValidated: boolean;
  nextBtnName: string;
  prevBtnName: string;
}

export interface Location {
  id: string;
  location: GenericAddressModel | null;
}

export interface Trip {
  id: string|null; // available on Intent Edit mode
  recurrenceId: string|null;
  sequenceId: string|null;
  startPoint: Location;
  endPoint: Location;
  waypoints: (Location)[];
  earliestDeparture: moment.Moment;
  latestArrival: moment.Moment;
  validationErrors: DateValidations | null;
  duration: number; // Minutes
  meetingPoint: string;
}

export interface SelectedCar {
  id: string;
  type: CarTypeEnum;
  freeSeats?: number;
  plating?: string;
  model?: string;
  color?: string;
}

export interface OtherDetails {
  businessTrip: boolean;
  claimsPayment: boolean;
  offersPayment: boolean;
  ladiesOnly: boolean;
  detour: number;
  organizationId: string;
  repeat: {
    frequency: TripFrequency;
    on: string[];
    until: null|moment.Moment;
  };
}

export enum CarRentalData {
  LICENCE_PLATE = 'LICENCE_PLATE',
  MODEL = 'MODEL',
  COLOR = 'COLOR',
  SEATS = 'SEATS',
}

export enum RideIntentCreationState {
  NOT_TRIED,
  TRIED_AND_SUCCEEDED,
  TRIED_AND_FAILED,
}

export interface CreatedRideIntent {
  state: RideIntentCreationState;
  shareLink: string | null;
}

export interface RideIntentState {
  steps: Step[];
  role: RolesEnum | null;
  poolCarRequest: boolean;
  outboundTrip: Trip;
  returnTrip: Trip;
  selectedCar: SelectedCar | null;
  other: OtherDetails;
  createdRideIntent: CreatedRideIntent;
  error: string;
}

const defaultOutboundTrip = (): Trip => {
  const earliestDeparture = moment();
  const latestArrival = moment();
  earliestDeparture.add(1, 'day');
  earliestDeparture.hours(7);
  earliestDeparture.minutes(0);
  latestArrival.add(1, 'day');
  latestArrival.hours(9);
  latestArrival.minutes(0);
  return {
    id: null,
    recurrenceId: null,
    sequenceId: null,
    earliestDeparture,
    latestArrival,
    validationErrors: null,
    startPoint: { id: uuidv4(), location: null },
    endPoint: { id: uuidv4(), location: null },
    waypoints: [],
    // TODO: Calculate this upon route request.
    duration: 21,
    meetingPoint: '',
  };
};

const defaultReturnTrip = (): Trip => {
  const earliestDeparture = moment();
  const latestArrival = moment();
  earliestDeparture.add(1, 'day');
  earliestDeparture.hours(17);
  earliestDeparture.minutes(0);
  latestArrival.add(1, 'day');
  latestArrival.hours(19);
  latestArrival.minutes(0);
  return {
    id: null,
    recurrenceId: null,
    sequenceId: null,
    earliestDeparture,
    latestArrival,
    validationErrors: null,
    startPoint: { id: uuidv4(), location: null },
    endPoint: { id: uuidv4(), location: null },
    waypoints: [],
    // TODO: Calculate this upon route request.
    duration: 19,
    meetingPoint: '',
  };
};

const NEXT_BTN = 'rideIntent.next';
const PREV_BTN = 'rideIntent.back';

const state = (): RideIntentState => ({
  steps: [
    {
      id: StepperSteps.ROLES,
      title: 'rideIntent.wizard.role',
      status: StepStatusesEnum.CURRENT,
      componentName: 'OrganismRoleSelection',
      isValidated: false,
      nextBtnName: NEXT_BTN,
      prevBtnName: PREV_BTN,
    },
    {
      id: StepperSteps.OUTBOUND_TRIP,
      title: 'rideIntent.wizard.outbound',
      status: StepStatusesEnum.UNCOMPLETED,
      componentName: 'OrganismOutboundTrip',
      isValidated: false,
      nextBtnName: NEXT_BTN,
      prevBtnName: PREV_BTN,
    },
    {
      id: StepperSteps.OUTBOUND_TRIP_TIME,
      title: 'rideIntent.wizard.outboundDateTime',
      status: StepStatusesEnum.UNCOMPLETED,
      componentName: 'OrganismOutboundDateSelection',
      isValidated: true,
      nextBtnName: NEXT_BTN,
      prevBtnName: PREV_BTN,
    },
    {
      id: StepperSteps.INBOUND_TRIP,
      title: 'rideIntent.wizard.inbound',
      status: StepStatusesEnum.UNCOMPLETED,
      componentName: 'OrganismReturnTrip',
      isValidated: false,
      nextBtnName: NEXT_BTN,
      prevBtnName: PREV_BTN,
    },
    {
      id: StepperSteps.INBOUND_TRIP_TIME,
      title: 'rideIntent.wizard.inboundDateTime',
      status: StepStatusesEnum.INACTIVE,
      componentName: 'OrganismReturnTripDateSelection',
      isValidated: true,
      nextBtnName: NEXT_BTN,
      prevBtnName: PREV_BTN,
    },
    {
      id: StepperSteps.CAR_SELECTION,
      title: 'rideIntent.wizard.carSelection',
      status: StepStatusesEnum.UNCOMPLETED,
      componentName: 'OrganismCarSelection',
      isValidated: true,
      nextBtnName: NEXT_BTN,
      prevBtnName: PREV_BTN,
    },
    {
      id: StepperSteps.OTHER,
      title: 'rideIntent.wizard.other',
      status: StepStatusesEnum.UNCOMPLETED,
      componentName: 'OrganismExtras',
      isValidated: true,
      nextBtnName: 'rideIntent.wizard.toTheSummary',
      prevBtnName: PREV_BTN,
    },
    {
      id: StepperSteps.OVERVIEW,
      title: 'rideIntent.wizard.overview',
      status: StepStatusesEnum.UNCOMPLETED,
      componentName: 'OrganismSummary',
      isValidated: true,
      nextBtnName: 'rideIntent.wizard.createRideIntent',
      prevBtnName: PREV_BTN,
    },
  ],
  role: null,
  outboundTrip: defaultOutboundTrip(),
  returnTrip: defaultReturnTrip(),
  selectedCar: null,
  poolCarRequest: false,
  other: {
    businessTrip: false,
    ladiesOnly: false,
    claimsPayment: false,
    offersPayment: false,
    detour: 0,
    organizationId: '0',
    repeat: {
      frequency: TripFrequency.NEVER,
      on: [],
      until: null,
    },
  },
  createdRideIntent: {
    state: RideIntentCreationState.NOT_TRIED,
    shareLink: null,
  },
  error: '',
});

export default state;

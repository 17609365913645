import axios from 'axios';
import { NEWSLETTER_API_KEY, NEWSLETTER_BASE_URL } from '@/common/config';
import { NewsletterRegisterModel } from '@/models/newsletter/NewsletterRegisterModel';
import BaseResponse from '@/models/IBaseResponse';

export const registerNewsletter = async (subscriber: NewsletterRegisterModel): Promise<BaseResponse> => {
  const url = `${NEWSLETTER_BASE_URL}/addresses?api-key=${NEWSLETTER_API_KEY}`;
  try {
    const { status } = await axios.post(url, subscriber);
    return { status };
  } catch (error) {
    return { status: error?.response?.status || 500 };
  }
};

export default registerNewsletter;

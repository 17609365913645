import { CreateCar } from '@/store/modules/create-car/state';
import { Mutations } from '@/store/modules/user/mutations';
import { ActionContext as DefaultActionContext, ActionTree } from 'vuex';

type ActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1]
  ): ReturnType<Actions[K]>;
} & Omit<DefaultActionContext<CreateCar, CreateCar>, 'commit | dispatch'>

export interface Actions {
  loadCar({ commit }: ActionContext, payload: CreateCar): void;
  setImage({ commit }: ActionContext, payload: string): void;
  setLicensePlate({ commit }: ActionContext, payload: string): void;
  setDescription({ commit }: ActionContext, payload: string): void;
  setEngineType({ commit }: ActionContext, payload: string): void;
  setFreeSeats({ commit }: ActionContext, payload: number): void;
  setIsDefaultCar({ commit }: ActionContext, payload: boolean): void;
  setSkipImage({ commit }: ActionContext, payload: boolean): void;
}

const actions: ActionTree<CreateCar, CreateCar> & Actions = {
  loadCar({ commit }, payload: CreateCar) {
    commit('loadCar', payload);
  },
  setImage({ commit }, payload: string) {
    commit('setImage', payload);
    commit('setSkipImage', false);
  },
  setSkipImage({ commit }, payload: boolean) {
    commit('setSkipImage', payload);
    if (payload) {
      commit('setImage', null);
    }
  },
  setLicensePlate({ commit }, payload: string) {
    commit('setLicensePlate', payload);
  },
  setDescription({ commit }, payload: string) {
    commit('setDescription', payload);
  },
  setEngineType({ commit }, payload: string) {
    commit('setEngineType', payload);
  },
  setFreeSeats({ commit }, payload: number) {
    commit('setFreeSeats', payload);
  },
  setIsDefaultCar({ commit }, payload: boolean) {
    commit('setIsDefaultCar', payload);
  },
  resetCarState({ commit }: ActionContext) {
    commit('resetCarState');
  },
};

export default actions;


import Vue from 'vue';

export default Vue.extend({
  name: 'AtomText',
  props: {
    tag: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      default: '',
    },
    wrap: {
      type: Boolean,
      required: false,
      default: false,
    },
    noMargin: {
      type: Boolean,
      required: false,
      default: false,
    },
    bold: {
      type: Boolean,
      required: false,
      default: false,
    },
    textCenter: {
      type: Boolean,
      required: false,
      default: false,
    },
    className: {
      type: String,
      default: '',
      required: false,
    },
  },
  computed: {
    hasSlot(): boolean {
      return !!this.$slots.default;
    },
    getClasses(): any {
      const className = this.className ? `${this.tag} ${this.className}` : this.tag;
      return {
        [className]: true,
        wrap: this.wrap,
        'no-margin': this.noMargin,
        'text-center': this.textCenter,
        bold: this.bold,
      };
    },
  },
});

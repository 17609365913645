import CarTypeEnum from '@/enums/car/CarTypeEnum';
import EventTypeEnum from '@/enums/rides/EventsTypeEnum';
import RideDetailsType from '@/enums/rides/RideDetailsTypeEnum';
import moment from 'moment';
import { RideIntentResponseModel } from './response/RideIntentResponse';
import RideIntentModel from './RideIntentModel';

export const getEventType = (date: moment.Moment): EventTypeEnum => {
  const now = moment();

  return (date.isSameOrAfter(now, 'second')
    ? EventTypeEnum.FUTURE_RIDE_INTENT
    : EventTypeEnum.PAST_RIDE_INTENT);
};

const mapRideIntentResponseToModel = (intent: RideIntentResponseModel): RideIntentModel => ({
  intentId: intent.intentId,
  sequenceId: intent.sequenceId,
  inviteUrl: intent.inviteUrl,
  nextSequenceLeg: intent.nextSequenceLeg,
  sequencePos: intent.sequencePos,
  recurringIntentId: intent.recurringIntentId,
  recurringIntent: intent.recurringIntent,
  eventType: getEventType(moment(intent.destination.dateTime)),
  type: RideDetailsType.UNMATCHED_RIDE, // TODO: Make it dynamic when alternatives are ready.
  userId: intent.userId,
  subject: intent.subject,

  origin: {
    ...intent.origin,
    id: `${intent.origin.location.latitude}-${intent.origin.location.longitude}`,
    dateTime: moment(intent.origin.dateTime),
    position: { lat: intent.origin.location.latitude, lng: intent.origin.location.longitude },
    dropOffIds: [],
    pickUpIds: [],
    providesParkingPlace: intent.origin.providesParkingPlace,
  },
  destination: {
    ...intent.destination,
    id: `${intent.destination.location.latitude}-${intent.destination.location.longitude}`,
    dateTime: moment(intent.destination.dateTime),
    position: { lat: intent.destination.location.latitude, lng: intent.destination.location.longitude },
    dropOffIds: [],
    pickUpIds: [],
    providesParkingPlace: intent.destination.providesParkingPlace,
  },
  earliestDeparture: moment(intent.origin.dateTime),
  latestArrival: moment(intent.destination.dateTime),
  waypoints: intent.waypoints || [],
  car: intent.car ? {
    id: intent.car.carId.toString(),
    carId: intent.car.carId.toString(),
    freeSeats: intent.car.freeSeats,
    type: intent.car.type as CarTypeEnum,
  } : null,
  parkingSpace: intent.parkingSpace || null,
  role: intent.role,
  status: '',
  acceptedGender: intent.acceptedGender,
  acceptedHabit: intent.acceptedHabit,
  offersPayment: intent.offersPayment,
  claimsPayment: intent.claimsPayment,
  maxDetourMinutes: intent.maxDetourMinutes,
  minSharedDurationPercentage: intent.minSharedDurationPercentage,
  anyRoute: intent.anyRoute,
  lightSequence: intent.lightSequence,
  binding: intent.binding,
  businessTrip: intent.businessTrip,
  poolCarRequest: intent.poolCarRequest,
  tooltip: '',
});

export default mapRideIntentResponseToModel;

enum SourceEnum {
  ALL = 'ALL',
  HOME = 'HOME',
  WORK = 'WORK',
  HISTORY = 'history',
  FAVORITES = 'favorites',
  SEARCH = 'search',
  POI = 'poi',
  SITE = 'site',
}

export const SourceSortOrder = {
  [SourceEnum.HOME]: 1,
  [SourceEnum.WORK]: 2,
  [SourceEnum.FAVORITES]: 3,
  [SourceEnum.SITE]: 4,
  [SourceEnum.HISTORY]: 5,
  [SourceEnum.SEARCH]: 6,
};

export const SourceQuantityInList = {
  [SourceEnum.HOME]: 1,
  [SourceEnum.WORK]: 1,
  [SourceEnum.FAVORITES]: 4,
  [SourceEnum.SITE]: 2,
  [SourceEnum.HISTORY]: 4,
  [SourceEnum.SEARCH]: 4,
};

export default SourceEnum;

import { http } from '@/services/http/HTTPService';
import { APIGEE_DOMAIN, GAMIFICATION_SERVICE_URL } from '@/common/config';
import { UserResponse } from '@/models/user/response/UserResponseModel';
import mapUserResponseToModel from '@/models/user/MapUserResponseToModel';
import mapLeaderBoardResponseToModel from '@/models/gamification/MapLeaderBoardResponseToModel';
import { CategorizedLeaderBoardResponse } from '@/models/gamification/response/LeaderBoardResponse';
import GamificationTypeEnum from '@/enums/gamification/GamificationTypeEnum';

export const setOptIn = async (type: GamificationTypeEnum): Promise<UserResponse> => {
  try {
    const { status, data } =
      await http.post(
        `${APIGEE_DOMAIN}/${GAMIFICATION_SERVICE_URL}/users/gamification/optin`,
        { type },
      );

    return { status, data: mapUserResponseToModel(data.result) };
  } catch (error) {
    return { status: (error as any).response.status };
  }
};

export const removeOptIn = async (type: GamificationTypeEnum) => {
  try {
    const { status, data } =
    await http.delete(`${APIGEE_DOMAIN}/${GAMIFICATION_SERVICE_URL}/users/gamification/optin?type=${type}`);

    return { status, data: mapUserResponseToModel(data.result) };
  } catch (error) {
    return { status: (error as any).response.status };
  }
};

export const getCategorizedLeaderBoard = async (): Promise<CategorizedLeaderBoardResponse> => {
  try {
    const { status, data: { result } } =
      await http.get(`${APIGEE_DOMAIN}/${GAMIFICATION_SERVICE_URL}/users/categorized-leaderboard`);
    return {
      status,
      data: {
        thisMonth: result.thisMonth.map((el) => mapLeaderBoardResponseToModel(el)),
        lastMonth: result.lastMonth.map((el) => mapLeaderBoardResponseToModel(el)),
        allTime: result.allTime.map((el) => mapLeaderBoardResponseToModel(el)),
      },
    };
  } catch (error) {
    return { status: (error as any).response.status };
  }
};

export const getMyCategorizedLeaderBoard = async (userId: string): Promise<CategorizedLeaderBoardResponse> => {
  try {
    const { status, data: { result } } =
      await http.get(`${APIGEE_DOMAIN}/${GAMIFICATION_SERVICE_URL}/users/${userId}/categorized-leaderboard`);
    return {
      status,
      data: {
        thisMonth: [mapLeaderBoardResponseToModel(result.thisMonth)],
        lastMonth: [mapLeaderBoardResponseToModel(result.lastMonth)],
        allTime: [mapLeaderBoardResponseToModel(result.allTime)],
      },
    };
  } catch (error) {
    return { status: (error as any).response.status };
  }
};

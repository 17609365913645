import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import multiguard from 'vue-router-multiguard';
import AuthMiddleware from '@/router/middleware/AuthMiddleware';
import { getUserSupportedLocale } from '@/services/translation/TranslationService';
import { Features } from '@/services/utilities/EnvFeatureService';
import { TimetableType } from '@/api/timetable/timetableApi';
import LoginRegistration from '../views/LoginRegistration.vue';
import { routeMiddleware } from './middleware/localeMiddleware';
import { detectDevice } from './middleware/DeviceMiddleware';
import { enablingMiddleware } from './middleware/EnablingMiddleware';
import RouteNamesEnum from './RouteNamesEnum';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: {
      render: (h) => h('router-view'),
    },
    children: [
      {
        path: '/:locale?/login/:token?',
        alias: [
          '/:locale?/register/:token?',
          '/:locale?/forgotpassword',
          '/:locale?/resetpassword',
          '/:locale?/verification/resend',
        ],
        name: RouteNamesEnum.LOGIN_REGISTRATION,
        component: LoginRegistration,
      },
      {
        path: '/:locale/edit/ride/:id?',
        alias: [
          '/:locale/edit/recurrences/:id',
          '/:locale/create/ride',
        ],
        name: RouteNamesEnum.WIZARD_RIDE,
        component: () => import(/* webpackChunkName: "CreateRide" */ '@/views/CreateRide.vue'),
      },
      {
        path: '/:locale?/main',
        name: RouteNamesEnum.USER_INFO,
        component: () => import(/* webpackChunkName: "UserInfo" */ '@/components/templates/profile/UserProfile.vue'),
      },
      {
        path: '/:locale?/share',
        beforeEnter: (to, from, next) => {
          // const locale = localStorage.getItem('locale');
          next({
            name: RouteNamesEnum.WIZARD_RIDE,
            query: to.query,
            params: { locale: to.params.locale || 'de' },
          });
        },
      },
      {
        path: '/:locale/home',
        name: RouteNamesEnum.HOME,
        alias: [
          '/:locale/home/pastRides',
          '/:locale/home/welcome',
        ],
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue'),
      },
      {
        path: '/:locale/search/rides',
        name: RouteNamesEnum.SEARCH_RIDES,
        meta: {
          feature: Features.RIDES_SEARCH,
        },
        component: () => import(/* webpackChunkName: "SearchRides" */ '@/views/SearchRides.vue'),
      },
      {
        path: '/:locale/search/ride/:ride?',
        name: RouteNamesEnum.SEARCH_RIDE_DETAIL,
        component: () => import(/* webpackChunkName: "TemplateSearchRideDetails" */ '@/components/templates/rides/TemplateSearchRideDetails.vue'),
        props: true,
      },
      {
        path: '/:locale/shared/rides',
        name: RouteNamesEnum.SHARED_RIDES,
        meta: {
          feature: Features.RIDES_SHARING,
        },
        component: () => import(/* webpackChunkName: "SharedRides" */ '@/views/SharedRides.vue'),
      },
      {
        path: '/:locale/shared/ride/:ride?',
        name: RouteNamesEnum.SHARED_RIDE_DETAIL,
        component: () => import(/* webpackChunkName: "TemplateSharedRidesDetail" */ '@/components/templates/rides/TemplateSharedRidesDetail.vue'),
        props: true,
      },
      {
        path: '/:locale/home/details/:id',
        name: RouteNamesEnum.RIDE_DETAILS,
        component: () => import(/* webpackChunkName: "RideDetails" */ '@/views/RideDetails.vue'),
      },
      {
        path: '/:locale/profile/:id?',
        name: RouteNamesEnum.PROFILE,
        component: () => import(/* webpackChunkName: "Profile" */ '@/views/Profile.vue'),
      },
      {
        path: '/:locale/locations',
        name: RouteNamesEnum.LOCATIONS,
        component: () => import(/* webpackChunkName: "MyLocations" */ '@/views/MyLocations.vue'),
      },
      {
        path: '/:locale/locations/create/:name?',
        name: RouteNamesEnum.LOCATIONS_CREATE,
        component: () => import(/* webpackChunkName: "MyLocations" */ '@/views/MyLocations.vue'),
      },
      {
        path: '/:locale/locations/edit/:id',
        name: RouteNamesEnum.LOCATIONS_EDIT,
        component: () => import(/* webpackChunkName: "MyLocations" */ '@/views/MyLocations.vue'),
      },
      {
        path: '/:locale/cars',
        name: RouteNamesEnum.CARS,
        component: () => import(/* webpackChunkName: "MyCars" */ '@/views/MyCars.vue'),
      },

      {
        path: '/:locale/portal/site_:id',
        name: RouteNamesEnum.TIMETABLE_PORTAL,
        meta: {
          timetableType: TimetableType.FIND_BY_SITE_ID,
        },
        component: () => import(/* webpackChunkName: "TimetablePortal" */ '@/views/TimetablePortal.vue'),
      },
      {
        path: '/:locale/kiosk/site_:id',
        name: RouteNamesEnum.TIMETABLE_KIOSK,
        meta: {
          timetableType: TimetableType.FIND_BY_SITE_ID,
        },
        component: () => import(/* webpackChunkName: "TimetableKiosk" */ '@/views/TimetableKiosk.vue'),
      },
      {
        path: '/:locale/portal/cfg_:id',
        name: RouteNamesEnum.TIMETABLE_CONFIG_PORTAL,
        meta: {
          timetableType: TimetableType.FIND_BY_CONFIGURATION_ID,
        },
        component: () => import(/* webpackChunkName: "TimetablePortal" */ '@/views/TimetablePortal.vue'),
      },
      {
        path: '/:locale/kiosk/cfg_:id',
        name: RouteNamesEnum.TIMETABLE_CONFIG_KIOSK,
        meta: {
          timetableType: TimetableType.FIND_BY_CONFIGURATION_ID,
        },
        component: () => import(/* webpackChunkName: "TimetableKiosk" */ '@/views/TimetableKiosk.vue'),
      },
      {
        path: '/:locale/portal/sitepoi_:id',
        name: RouteNamesEnum.TIMETABLE_POI_PORTAL,
        meta: {
          timetableType: TimetableType.FIND_BY_SITE_POI_ID,
        },
        component: () => import(/* webpackChunkName: "TimetablePortal" */ '@/views/TimetablePortal.vue'),
      },
      {
        path: '/:locale/kiosk/sitepoi_:id',
        name: RouteNamesEnum.TIMETABLE_POI_KIOSK,
        meta: {
          timetableType: TimetableType.FIND_BY_SITE_POI_ID,
        },
        component: () => import(/* webpackChunkName: "TimetableKiosk" */ '@/views/TimetableKiosk.vue'),
      },

      {
        path: '/:locale?/mobileDevice',
        name: RouteNamesEnum.MOBILE_DEVICE,
        component: () => import(/* webpackChunkName: "MobileDevice" */ '@/views/MobileDevice.vue'),
      },
      {
        path: '/:locale/profile/user/:id',
        name: RouteNamesEnum.PROFILE_USER,
        component: () => import(/* webpackChunkName: "ProfileUser" */ '@/components/templates/profile/TemplateForeignProfile.vue'),
      },
      {
        path: '/:locale/settings',
        name: RouteNamesEnum.SETTINGS,
        component: () => import(/* webpackChunkName: "Settings" */ '@/views/Settings.vue'),
      },
      {
        path: '/:locale/help',
        name: RouteNamesEnum.SETTINGS_HELP,
        component: () => import(/* webpackChunkName: "Help" */ '@/views/Help.vue'),
      },
      {
        path: '/:locale?/lotteryTermsOfParticipation',
        name: RouteNamesEnum.LOTTERY_TOP,
        component: () => import(/* webpackChunkName: "LotteryTermsOfParticipation" */ '@/views/LotteryTermsOfParticipation.vue'),
      },
      {
        path: '/:locale/settings/rides',
        name: RouteNamesEnum.SETTINGS_RIDE,
        meta: {
          feature: Features.SETTINGS_RIDE,
        },
        // eslint-disable-next-line max-len
        component: () => import(/* webpackChunkName: "TemplateSettingsRides" */ '@/components/templates/settings/TemplateSettingsRides.vue'),
      },
      {
        path: '/:locale/settings/security',
        name: RouteNamesEnum.SETTINGS_SECURITY,
        meta: {
          feature: Features.SETTINGS_SECURITY,
        },
        // eslint-disable-next-line max-len
        component: () => import(/* webpackChunkName: "TemplateSettingsSecurity" */ '@/components/templates/settings/TemplateSettingsSecurity.vue'),
      },
      {
        path: '/:locale/settings/notifications',
        name: RouteNamesEnum.SETTINGS_NOTIFICATIONS,
        meta: {
          feature: Features.SETTINGS_NOTIFICATIONS,
        },
        // eslint-disable-next-line max-len
        component: () => import(/* webpackChunkName: "TemplateSettingsNotifications" */ '@/components/templates/settings/TemplateSettingsNotifications.vue'),
      },
      {
        path: '/:locale/settings/region',
        name: RouteNamesEnum.SETTINGS_REGION,
        meta: {
          feature: Features.SETTINGS_REGION,
        },
        // eslint-disable-next-line max-len
        component: () => import(/* webpackChunkName: "TemplateSettingsRegion" */ '@/components/templates/settings/TemplateSettingsRegion.vue'),
      },
      {
        path: '/:locale/settings/account',
        name: RouteNamesEnum.SETTINGS_ACCOUNT,
        // meta: {
        //   feature: Features.SETTINGS_REGION,
        // },
        // eslint-disable-next-line max-len
        component: () => import(/* webpackChunkName: "TemplateSettingsAccount" */ '@/components/templates/settings/TemplateSettingsAccount.vue'),
      },
    ],
  },
  {
    path: '*', // If no match, redirect to Home
    beforeEnter({ query }: Route, _from: Route, next: Function) {
      next({
        path: `${getUserSupportedLocale()}/home`,
        query,
      });
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

router.beforeEach(multiguard([
  enablingMiddleware,
  AuthMiddleware,
  detectDevice,
  routeMiddleware,
]));

export default router;

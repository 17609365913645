export interface CreateCar {
  id: string | null;
  image: string | null;
  decodedImageURL: string;
  licensePlate: string | null;
  description: string | null;
  engineType: string | null;
  freeSeats: number;
  isDefaultCar: boolean;
  skipImage: boolean;
}

const state = (): CreateCar => ({
  id: null,
  image: null,
  decodedImageURL: '',
  licensePlate: null,
  description: null,
  engineType: null,
  freeSeats: 3,
  isDefaultCar: true,
  skipImage: false,
});

export default state;

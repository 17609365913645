
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { BForm } from 'bootstrap-vue';
import { login, initiateAccountVerification } from '@twogo/cidaas/src/lib/CidaasService';
import AtomActionText from '@/components/atoms/AtomActionText.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomCheckbox from '@/components/atoms/AtomCheckbox.vue';
import AtomNotification, { NotificationVariant } from '@/components/atoms/AtomNotification.vue';
import AtomPasswordInput from '@/components/atoms/AtomInput/AtomPasswordInput.vue';
import AtomRouterLink from '@/components/atoms/AtomRouterLink.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomTextInput from '@/components/atoms/AtomInput/AtomTextInput.vue';
import LoginError from '@/enums/login-registration/LoginErrorEnum';

export default Vue.extend({
  name: 'OrganismLogin',
  components: {
    AtomActionText,
    AtomButton,
    AtomCheckbox,
    AtomNotification,
    AtomPasswordInput,
    AtomRouterLink,
    AtomText,
    AtomTextInput,
    BForm,
  },
  data: () => ({
    LoginError,
    ButtonVariant,
    NotificationVariant,
    email: '',
    password: '',
    state: 0,
    validFields: {
      email: false,
      password: false,
    },
    isLoading: false,
  }),
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.email = urlParams.get('username') ?? '';

    const error = urlParams.get('error');
    if (error) {
      if (error.includes('invalid_username_password')) {
        this.state = LoginError.WRONG_DATA;
      } else if (error.includes('email_not_verified')) {
        this.state = LoginError.WARNING_VERIFY_EMAIL;
      }
    } else if (urlParams.get('password-reset') === 'true') {
      this.state = LoginError.SUCCESS_PASSWORD_REST;
    } else if (
      urlParams.get('requestId') &&
      urlParams.get('accvid') &&
      urlParams.get('verificationMedium')
    ) {
      this.state = LoginError.SUCCESS_EMAIL_VERIFY;
    } else if (urlParams.get('passwordChanged')) {
      this.state = LoginError.SUCCESS_PASSWORD_CHANGED;
      this.resetState();
      localStorage.removeItem('access_token');
    } else {
      this.state = LoginError.NO_ERROR;
    }
  },
  methods: {
    ...mapActions('user', ['resetState']),
    async login() {
      if (!this.validateInputs()) {
        this.state = LoginError.WRONG_DATA;
        return;
      }
      this.isLoading = true;
      try {
        await login(this.email, 'email', this.password);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    validateInputs(): boolean {
      const validFields = Object.values(this.validFields).filter((field) => field).length;
      return validFields === Object.keys(this.validFields).length;
    },
    async resendEmail() {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('requestId');
      const sub = urlParams.get('sub');
      if (token && sub) {
        const response = await initiateAccountVerification(token, sub);
        if (!response.isError) {
          this.state = LoginError.INFO_VERIFICATION_SENT;
        } else {
          this.state = LoginError.ALERT_ERROR;
        }
      }
    },
  },
  computed: {
    ...mapGetters('user', ['accessToken', 'user']),
    resetLink(): string {
      return `/${this.$i18n.locale}/forgotpassword`;
    },
    areRequiredFieldsCompleted(): boolean {
      return !Object.values(this.validFields).includes(false);
    },
  },
});

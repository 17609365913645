import { GetterTree } from 'vuex';
import { RidesState as State } from '@/store/modules/rides/state';
import RideIntentModel from '@/models/ride-intent/RideIntentModel';
import RideSummaryModel from '@/models/ride/RideSummaryModel';
import { RideModel } from '@/models/ride/RideModel';
import UnratedTripModel from '@/models/ratings/UnratedTripModel';
import mapRideToSummaryModel from '@/models/ride/MapRideToSummaryModel';
import mapIntentsToSummaryModel from '@/models/ride-intent/MapIntentsToSummaryModel';
import EventTypeEnum from '@/enums/rides/EventsTypeEnum';

export interface Getters {
  getIntents(state: State): RideIntentModel[];
  getCommunityIntents(state: State): RideIntentModel[];
  getIntentById(state: State, getters): (id: string) => RideIntentModel|undefined;
  getSequenceIntent(state: State): (id: string, sequencePos: number) => RideIntentModel|undefined;
  getRides(state: State): RideModel[];
  getRideById(state: State): (id: string) => RideModel|undefined;
  getRidesSummary(state: State): RideSummaryModel[];
  getIntentsSummary(state: State): RideSummaryModel[];
  getSummary(state: State, getters): RideSummaryModel[];
  getGetUnratedRidesById(state: State): (id: string) => UnratedTripModel[];
}

const getters: GetterTree<State, State> & Getters = {
  getIntents: (state) => state.intents,
  getCommunityIntents: (state) =>
    state.communityIntents.filter((intent) => intent.eventType === EventTypeEnum.FUTURE_RIDE_INTENT),
  getIntentById: ({ intents }) => (id) => intents.find((intent) => intent.intentId === id),
  getSequenceIntent: ({ intents }) =>
    (id, sequencePos) => intents.find((intent) => intent.sequenceId === id && intent.sequencePos === sequencePos),
  getRides: (state) => state.rides,
  getRideById: ({ rides }) => (id) => rides.find((ride) => ride.intentId === id),
  getRidesSummary: ({ rides }) => rides.map(mapRideToSummaryModel),
  getIntentsSummary: ({ intents }) => intents.map(mapIntentsToSummaryModel),
  getSummary: (_, g) => {
    const summary = g.getRidesSummary as RideSummaryModel[];
    const intentsSummary = (g.getIntentsSummary as RideSummaryModel[])
      .filter((i) => !summary.find((s) => s.intentId === i.intentId));

    return [...intentsSummary, ...summary];
  },
  getGetUnratedRidesById: ({ unratedRides }) => (id: string) => unratedRides.filter((ride) => ride.matchId === id),
  hasUnratedRides: ({ unratedRides }) => !!unratedRides.length,
};

export default getters;

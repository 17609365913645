import { render, staticRenderFns } from "./AtomNotification.vue?vue&type=template&id=28fba268&scoped=true"
import script from "./AtomNotification.vue?vue&type=script&lang=ts"
export * from "./AtomNotification.vue?vue&type=script&lang=ts"
import style0 from "./AtomNotification.vue?vue&type=style&index=0&id=28fba268&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28fba268",
  null
  
)

export default component.exports
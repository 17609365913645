import { PoiModel } from '@/models/geolocation/poi/PoiModel';
import {
  PoiState as State,
} from '@/store/modules/poi/state';
import { MutationTree } from 'vuex';

export type Mutations<S = State> = {
  setPoi(state: S, payload: PoiModel[]): void;
}

const mutations: MutationTree<State> & Mutations = {
  setPoi(state, payload) {
    const { poi } = state;
    state.poi = [
      ...payload.reduce((cont, p) => {
        if (!(poi.find((poiAddress) => poiAddress.id === p.id))) cont.push(p);
        return cont;
      }, [] as PoiModel[]),
      ...poi,
    ];
  },
  addPoi(state, payload) {
    state.poi.push(payload);
  },
  editPoi(state, payload) {
    const { poi } = state;
    const pois = poi.filter((p) => p.id !== payload.id);
    state.poi = [...pois, payload];
  },
  deletePoi(state, payload) {
    const { poi } = state;
    state.poi = poi.filter((p) => p.id !== payload);
  },
};

export default mutations;


import Vue from 'vue';
import { BForm } from 'bootstrap-vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import { PASSWORD_REGEX } from '@/common/config';
import AtomInput, { InputRequirement } from '@/components/atoms/AtomInput.vue';
import { resetPassword } from '@twogo/cidaas/src/lib/CidaasService';
import { ResetPasswordModel } from '@twogo/cidaas/src/lib/models/user/request/ResetPasswordModel';

export default Vue.extend({
  name: 'OrganismResetPassword',
  components: {
    AtomButton,
    AtomInput,
    AtomText,
    BForm,
  },
  data: () => ({
    ButtonVariant,
    password: '',
    rprq: '',
    exchangeId: '',
    validFields: {
      password: false,
    },
    isLoading: false,
  }),
  mounted() {
    this.parseResetRequestId();
  },
  methods: {
    getPasswordRequirements(): InputRequirement[] {
      const inputRequirements = [] as InputRequirement[];
      PASSWORD_REGEX.forEach((regex, key) =>
        inputRequirements.push({
          regex,
          description: this.$t(`register.passwordRequirements.option-${key}`).toString(),
        }),
      );
      return inputRequirements;
    },
    parseResetRequestId(): void {
      const urlParams = new URLSearchParams(window.location.search);
      this.rprq = urlParams.get('rprq') || '';
      this.exchangeId = urlParams.get('exchangeid') || '';
    },
    async resetPassword() {
      if (!this.validFields.password) {
        return;
      }
      this.isLoading = true;
      const resetParams: ResetPasswordModel = {
        password: this.password,
        confirmPassword: this.password,
        exchangeId: this.exchangeId,
        resetRequestId: this.rprq,
      };
      // FIXME: This is temporary because the CIDAAS SDK is to old and doesn't send an answer
      setTimeout(async () => {
        this.isLoading = false;
        await this.$router.push(`/${this.$i18n.locale}/login?password-reset=true`);
      }, 2000);
      const response = await resetPassword(resetParams);
      this.isLoading = false;
      if (!response.isError) {
        await this.$router.push(`/${this.$i18n.locale}/login?password-reset=true`);
      }
    },
  },
});

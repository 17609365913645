import { MutationTree } from 'vuex';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';
import DefaultState, {
  RideIntentState as State,
  Location,
  SelectedCar,
  CarRentalData,
  TripFrequency,
  RideIntentCreationState,
} from '@/store/modules/ride-intent/state';
import StepStatusesEnum, { StepperSteps } from '@/enums/stepper/StepperEnum';
import SwitchEnum from '@/enums/switch/SwitchEnum';
import RolesEnum from '@/enums/rides/RolesEnum';
import DateValidations from '@/enums/date/DateValidationEnum';
import CarTypeEnum from '@/enums/car/CarTypeEnum';
import GenericAddressModel from '@/models/geolocation/GenericAddressModel';

export type Mutations<S = State> = {
  jumpToStep(state: S, payload: StepperSteps): void;
  setSelectedRole(state: S, payload: RolesEnum): void;
  setOutboundIntentId(state: S, payload: string): void;
  setOutboundRecurrenceId(state: S, payload: string): void;
  validateRoleStep(state: S): void;
  toggleCarSelectionStep(state: S): void;
  toggleOutwardTripSteps(state: S, payload: SwitchEnum): void;
  addStartPointLocation(state: S, payload: GenericAddressModel | null): void;
  addEndPointLocation(state: S, payload: GenericAddressModel | null): void;
  addWaypoint(state: S, payload: GenericAddressModel | null): void;
  updateWaypoint(state: S, payload: { location: GenericAddressModel | null; index: number }): void;
  removeWaypoint(state: S, payload: number): void;
  clearWaypoints(state: S): void;
  clearReturnWaypoints(state: S): void;
  autoCompleteReturnTripDetails(state: S): void;
  setOutboundEarliestDeparture(state: S, payload: { value: moment.Moment; keepLatestArrivalInSync: boolean }): void;
  setOutboundLatestArrival(state: S, payload: moment.Moment): void;
  addMeetingPoint(state: S, payload: string): void;
  validateOutboundTripRoute(state: S): void;
  validateOutboundTrip(state: S): void;
  setReturnTripEarliestDeparture(state: S, payload: { value: moment.Moment; keepLatestArrivalInSync: boolean }): void;
  setReturnTripLatestArrival(state: S, payload: moment.Moment): void;
  addReturnMeetingPoint(state: S, payload: string): void;
  validateReturnTrip(state: S): void;
  setSelectedCar(state: S, payload: SelectedCar): void;
  addCarRentalData(state: S, payload: { value: string; type: CarRentalData }): void;
  addCarRentalSeats(state: S, payload: number): void;
  validateCarSelection(state: S): void;
  setBusinessTrip(state: S, payload: boolean): void;
  setLadiesOnly(state: S, payload: boolean): void;
  setOffersPayment(state: S, payload: boolean): void;
  setClaimsPayment(state: S, payload: boolean): void;
  setFrequencyType(state: S, payload: TripFrequency): void;
  removeFrequency(state: S, payload: string): void;
  setFrequency(state: S, payload: string): void;
  setBulkFrequency(state: S, payload: string[]): void;
  setFrequencyEnd(state: S, payload: moment.Moment): void;
  clearFrequency(state: S): void;
  setOrganizationId(state: S, payload: string): void;
  setDetour(state: S, payload: number): void;
  setDefaultDetour(state: S): void;
  updateRideIntentCreationState(state: S, payload: RideIntentCreationState): void;
  setCreatedRideIntentShareLink(state: S, payload: string): void;
  resetState(state: S): void;
}

const mutations: MutationTree<State> & Mutations = {
  jumpToStep: (state, payload) => {
    const { steps } = state;
    let hittedIdx: number;
    state.steps = steps.map((step, idx) => {
      if (step.status === StepStatusesEnum.INACTIVE) return step;
      if (step.id === payload) {
        hittedIdx = idx;
        return { ...step, status: StepStatusesEnum.CURRENT };
      }
      if (hittedIdx === undefined) return { ...step, status: StepStatusesEnum.COMPLETED };
      return { ...step, status: StepStatusesEnum.UNCOMPLETED };
    });
  },
  setOutboundIntentId: (state, id) => {
    state.outboundTrip.id = id;
  },
  setInboundIntentId: (state, id) => {
    state.returnTrip.id = id;
  },
  setOutboundRecurrenceId: (state, id) => {
    state.outboundTrip.recurrenceId = id;
  },
  setInboundRecurrenceId: (state, id) => {
    state.returnTrip.recurrenceId = id;
  },
  setOutboundSequenceId: (state, id) => {
    state.outboundTrip.sequenceId = id;
  },
  setInboundSequenceId: (state, id) => {
    state.returnTrip.sequenceId = id;
  },
  // Role selection
  setSelectedRole: (state, payload) => {
    const { role } = state;
    state.role = payload === role ? null : payload;
  },
  validateRoleStep: (state) => {
    const { role } = state;
    state.steps[0].isValidated = role !== null;
  },
  toggleCarSelectionStep: (state) => {
    const { role } = state;
    state.steps[5].status = role === RolesEnum.PASSENGER
      ? StepStatusesEnum.INACTIVE
      : StepStatusesEnum.UNCOMPLETED;
  },
  setIsPoolCarRequest: (state, payload) => {
    state.poolCarRequest = payload;
  },
  // Mutations for Outbound trip details
  toggleOutwardTripSteps: (state, payload) => {
    const stepStatus = payload === SwitchEnum.OFF
      ? StepStatusesEnum.INACTIVE
      : StepStatusesEnum.UNCOMPLETED;

    state.steps[4].status = stepStatus;
  },
  addStartPointLocation: (state, payload) => {
    state.outboundTrip.startPoint.location = payload;
  },
  addEndPointLocation: (state, payload) => {
    state.outboundTrip.endPoint.location = payload;
  },
  addWaypoint: (state, payload = null) => {
    const location: Location = {
      id: uuidv4(),
      location: payload,
    };
    state.outboundTrip.waypoints.push(location);
  },
  updateWaypoint: (state, payload) => {
    const { location, index } = payload;
    const { outboundTrip: { waypoints: intermediatePoints } } = state;
    intermediatePoints[index].location = location;
    state.outboundTrip.waypoints = [...intermediatePoints];
  },
  removeWaypoint: (state, index) => {
    const { outboundTrip: { waypoints: intermediatePoints } } = state;
    state.outboundTrip.waypoints = intermediatePoints.filter((el, i) => index !== i);
  },
  clearWaypoints: (state) => {
    state.outboundTrip.waypoints = [];
  },
  switchOriginAndArrival: (state) => {
    const {
      endPoint: { location: endLocation },
      startPoint: { location: startLocation },
    } = state.outboundTrip;
    state.outboundTrip.endPoint.location = startLocation;
    state.outboundTrip.startPoint.location = endLocation;
  },
  addMeetingPoint: (state, payload) => {
    state.outboundTrip.meetingPoint = payload;
  },
  addReturnMeetingPoint: (state, payload) => {
    state.returnTrip.meetingPoint = payload;
  },
  // Mutations for Return trip details
  switchReturnOriginAndArrival: (state) => {
    const {
      endPoint: { location: endLocation },
      startPoint: { location: startLocation },
    } = state.returnTrip;
    state.returnTrip.endPoint.location = startLocation;
    state.returnTrip.startPoint.location = endLocation;
  },
  autoCompleteReturnTripDetails: (state) => {
    const { endPoint, startPoint, waypoints } = state.outboundTrip;
    state.returnTrip.startPoint.location = cloneDeep(endPoint.location);
    state.returnTrip.endPoint.location = cloneDeep(startPoint.location);
    state.returnTrip.waypoints = [...waypoints].reverse().map((w) => cloneDeep(w));
  },
  addReturnStartPointLocation: (state, payload) => {
    state.returnTrip.startPoint.location = payload;
  },
  addReturnEndPointLocation: (state, payload) => {
    state.returnTrip.endPoint.location = payload;
  },
  addReturnWaypoint: (state, payload = null) => {
    const location: Location = {
      id: uuidv4(),
      location: payload,
    };
    state.returnTrip.waypoints.push(location);
  },
  updateReturnWaypoint: (state, payload) => {
    const { location, index } = payload;
    const { returnTrip: { waypoints: intermediatePoints } } = state;
    intermediatePoints[index].location = location;
    state.returnTrip.waypoints = [...intermediatePoints];
  },
  removeReturnWaypoint: (state, index) => {
    const { returnTrip: { waypoints: intermediatePoints } } = state;
    state.returnTrip.waypoints = intermediatePoints.filter((el, i) => index !== i);
  },
  clearReturnWaypoints: (state) => {
    state.returnTrip.waypoints = [];
  },
  // Mutations for Outbound Time and Date
  setOutboundEarliestDeparture: (state, { value, keepLatestArrivalInSync = true }) => {
    if (keepLatestArrivalInSync) {
      state.outboundTrip.latestArrival = value.clone().add(2, 'hours');
    }
    state.outboundTrip.earliestDeparture = value;
  },
  setOutboundLatestArrival: (state, payload: moment.Moment) => {
    state.outboundTrip.latestArrival = payload;
  },
  validateOutboundTrip: (state) => {
    const { outboundTrip } = state;
    const isEditMode = !!outboundTrip.id || !!outboundTrip.recurrenceId;
    const today = moment();

    if (outboundTrip.earliestDeparture.isBefore(today, 'minute') && isEditMode) {
      state.outboundTrip.validationErrors = DateValidations.DATE_IN_PAST;
      state.steps[2].isValidated = false;
    } else if (outboundTrip.latestArrival.isSameOrBefore(outboundTrip.earliestDeparture, 'minute')) {
      state.outboundTrip.validationErrors = DateValidations
        .LATEST_ARRIVAL_BEFORE_EARLIEST_DEPARTURE;
      state.steps[2].isValidated = false;
    } else {
      state.outboundTrip.validationErrors = null;
      state.steps[2].isValidated = true;
    }
  },
  setReturnTripEarliestDeparture(state, payload) {
    const { value, keepLatestArrivalInSync = true } = payload;
    if (keepLatestArrivalInSync) {
      state.returnTrip.latestArrival = value.clone().add(2, 'hours');
    }
    state.returnTrip.earliestDeparture = value;
  },
  setReturnTripLatestArrival(state, payload: moment.Moment) {
    state.returnTrip.latestArrival = payload;
  },
  validateReturnTrip(state) {
    const { outboundTrip, returnTrip } = state;
    if (returnTrip.earliestDeparture.isBefore(outboundTrip.latestArrival, 'minute')) {
      state.returnTrip.validationErrors = DateValidations.RETURN_TRIP_BEFORE_OUTBOUND;
      state.steps[4].isValidated = false;
    } else if (returnTrip.latestArrival.isSameOrBefore(returnTrip.earliestDeparture, 'minute')) {
      state.returnTrip.validationErrors = DateValidations.LATEST_ARRIVAL_BEFORE_EARLIEST_DEPARTURE;
      state.steps[4].isValidated = false;
    } else {
      state.returnTrip.validationErrors = null;
      state.steps[4].isValidated = true;
    }
  },
  validateOutboundTripRoute(state) {
    const {
      outboundTrip: {
        startPoint,
        endPoint,
        waypoints: intermediatePoints,
      },
    } = state;
    const isRouteComplete = ![...intermediatePoints, startPoint, endPoint]
      .some(({ location }) => location === null);
    const sameLocations = startPoint.location?.id === endPoint.location?.id;

    state.steps[1].isValidated = isRouteComplete && !sameLocations;
  },
  validateReturnTripRoute(state) {
    const {
      returnTrip: {
        startPoint,
        endPoint,
        waypoints: intermediatePoints,
      },
    } = state;
    const isRouteComplete = ![...intermediatePoints, startPoint, endPoint]
      .some(({ location }) => location === null);

    state.steps[3].isValidated = isRouteComplete;
  },
  setSelectedCar(state, payload: SelectedCar) {
    state.selectedCar = payload;
    if (payload.type === CarTypeEnum.POOL_CAR) {
      state.other.businessTrip = true;
    } else {
      state.other.businessTrip = false;
    }
  },
  addCarRentalData(state, payload) {
    const { value, type } = payload;
    if (state.selectedCar) {
      switch (type) {
        case CarRentalData.COLOR:
          state.selectedCar.color = value;
          break;
        case CarRentalData.MODEL:
          state.selectedCar.model = value;
          break;
        case CarRentalData.LICENCE_PLATE:
          state.selectedCar.plating = value;
          break;
        default:
          break;
      }
    }
  },
  addCarRentalSeats(state, payload) {
    if (state.selectedCar) {
      state.selectedCar.freeSeats = payload;
    }
  },
  validateCarSelection(state) {
    let isValid = false;
    const car = state.selectedCar;
    if (car && car.type === CarTypeEnum.POOL_CAR) {
      const hasReturnTrip = (
        state.steps[3].status === StepStatusesEnum.COMPLETED
        && state.steps[4].status === StepStatusesEnum.COMPLETED
      );
      const hasCorrectRole = state.role === RolesEnum.DRIVER_AND_PASSENGER;

      isValid = hasReturnTrip && hasCorrectRole;
    } else if (car && car.type === CarTypeEnum.RENTAL_CAR) {
      isValid = !!car.model;
    } else if (car && car.type === CarTypeEnum.OWN_CAR) {
      isValid = true;
    } else {
      isValid = false;
    }

    state.steps[5].isValidated = isValid;
  },
  setBusinessTrip(state, payload) {
    state.other.businessTrip = payload;
  },
  setLadiesOnly(state, payload) {
    state.other.ladiesOnly = payload;
  },
  setClaimsPayment(state, payload) {
    state.other.claimsPayment = payload;
  },
  setOffersPayment(state, payload) {
    state.other.offersPayment = payload;
  },
  setFrequencyType(state, payload) {
    state.other.repeat.frequency = payload;
  },
  setFrequency(state, payload) {
    state.other.repeat.on.push(payload);
  },
  setBulkFrequency(state, payload) {
    state.other.repeat.on = payload;
  },
  setFrequencyEnd(state, payload) {
    state.other.repeat.until = payload;
  },
  removeFrequency(state, payload) {
    state.other.repeat.on = state.other.repeat.on.filter((f) => f !== payload);
  },
  clearFrequency(state) {
    state.other.repeat.on = [];
  },
  setOrganizationId(state, payload) {
    state.other.organizationId = payload;
  },
  setDetour(state, payload) {
    state.other.detour = payload;
  },
  setDefaultDetour(state) {
    const { duration } = state.outboundTrip;
    if (duration > 20) {
      state.other.detour = 20;
    } else {
      state.other.detour = Math.round((duration / 2) / 5) * 5;
    }
  },
  setCreatedRideIntentShareLink(state, payload: string) {
    state.createdRideIntent.shareLink = payload;
  },
  updateRideIntentCreationState(state, payload: RideIntentCreationState) {
    state.createdRideIntent.state = payload;
  },
  resetState(state) {
    Object.assign(state, DefaultState());
  },
  setErrorCode(state, payload: string) {
    state.error = payload;
  },
};

export default mutations;

import DefaultState, {
  CreateCar,
} from '@/store/modules/create-car/state';
import { MutationTree } from 'vuex';

export type Mutations<S = CreateCar> = {
  loadCar(state: S, payload: CreateCar): void;
  setImage(state: S, payload: string): void;
  setLicensePlate(state: S, payload: string): void;
  setDescription(state: S, payload: string): void;
  setEngineType(state: S, payload: string): void;
  setFreeSeats(state: S, payload: number): void;
  setIsDefaultCar(state: S, payload: boolean): void;
  setSkipImage(state: S, payload: boolean): void;
}

const mutations: MutationTree<CreateCar> & Mutations = {
  loadCar(state, payload: CreateCar) {
    Object.assign(state, payload);
  },
  setImage(state, payload: string) {
    state.image = payload;
  },
  setLicensePlate(state, payload: string) {
    state.licensePlate = payload;
  },
  setDescription(state, payload: string) {
    state.description = payload;
  },
  setEngineType(state, payload: string) {
    state.engineType = payload;
  },
  setFreeSeats(state, payload: number) {
    state.freeSeats = payload;
  },
  setIsDefaultCar(state, payload: boolean) {
    state.isDefaultCar = payload;
  },
  setSkipImage(state, payload: boolean) {
    state.skipImage = payload;
  },
  resetCarState(state) {
    Object.assign(state, DefaultState());
  },
};

export default mutations;

import RideTypeEnum from '@/enums/rides/RideTypeEnum';
import RideSummaryModel from '@/models/ride/RideSummaryModel';
import RideIntentModel from './RideIntentModel';

const mapIntentsToSummaryModel = (intent: RideIntentModel): RideSummaryModel => ({
  matchId: '',
  intentId: intent.intentId || '',
  origin: {
    label: intent.origin.label,
    comment: intent.origin.comment,
    dateTime: intent.origin.dateTime,
    location: intent.origin.position,
  },
  destination: {
    label: intent.destination.label,
    comment: intent.destination.comment,
    dateTime: intent.destination.dateTime,
    location: intent.destination.position,
  },
  type: RideTypeEnum.UNMATCHED,
  eventType: intent.eventType,
  role: intent.role,
  tooltip: '',
  hasParkingLotAssigned: !!intent.parkingSpace,
});

export default mapIntentsToSummaryModel;

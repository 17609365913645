import { http } from '@/services/http/HTTPService';
import { APIGEE_DOMAIN, RATING_SERVICE_URL } from '@/common/config';
import { RatingResponse, UnratedTripsResponse } from '@/models/ratings/response/RatingsResponse';
import mapRatingResponseToModel from '@/models/ratings/MapRatingsResponseToModel';
import mapUnratedTripResponseToModel from '@/models/ratings/MapUnratedTripResponseToModel';
import BaseResponse from '@/models/IBaseResponse';

interface CreateRatingModel {
  matchId: string;
  userId: string;
  rating: number;
  annotations: string[];
}

interface CreateSkipRatingModel {
  matchId: string;
  userId: string;
}

export const getUserRating = async (userId: string): Promise<RatingResponse> => {
  const options = {
    params: {
      userId,
    },
  };
  try {
    const { status, data } = await http.get(
      `${APIGEE_DOMAIN}/${RATING_SERVICE_URL}/ratings`,
      options,
    );
    const rating = mapRatingResponseToModel(data.result);

    return {
      status,
      data: rating,
    };
  } catch (error) {
    return {
      status: (error as any).response.status,
      message: (error as any).response.data.error.errorCode,
    };
  }
};

export const getUnratedTrips = async (userId: string): Promise<UnratedTripsResponse> => {
  const options = {
    params: {
      userId,
    },
  };
  try {
    const { status, data } = await http.get(
      `${APIGEE_DOMAIN}/${RATING_SERVICE_URL}/ratings/unratedtrips`,
      options,
    );
    const ratings = data.result.map(mapUnratedTripResponseToModel);

    return {
      status,
      data: ratings,
    };
  } catch (error) {
    return {
      status: (error as any).response.status,
      message: (error as any).response.data.error.errorCode,
    };
  }
};

export const rateUser = async (payload: CreateRatingModel, authorId: string): Promise<BaseResponse> => {
  const options = {
    params: {
      authorId,
    },
  };
  try {
    const { status } = await http.post(
      `${APIGEE_DOMAIN}/${RATING_SERVICE_URL}/ratings`,
      payload,
      options,
    );

    return {
      status,
    };
  } catch (error) {
    return {
      status: (error as any).response.status,
      message: (error as any).response.data.error.errorCode,
    };
  }
};

export const skipUserRating = async (payload: CreateSkipRatingModel, authorId: string): Promise<BaseResponse> => {
  const options = {
    params: {
      authorId,
    },
  };
  try {
    const { status } = await http.post(
      `${APIGEE_DOMAIN}/${RATING_SERVICE_URL}/ratings/skip`,
      payload,
      options,
    );

    return {
      status,
    };
  } catch (error) {
    return {
      status: (error as any).response.status,
      message: (error as any).response.data.error.errorCode,
    };
  }
};

export const suggestAnnotations = async (payload: string[]): Promise<BaseResponse> => {
  try {
    const { status } = await http.post(
      `${APIGEE_DOMAIN}/${RATING_SERVICE_URL}/ratings/annotations/suggest`,
      payload,
    );

    return {
      status,
    };
  } catch (error) {
    return {
      status: (error as any).response.status,
      message: (error as any).response.data.error.errorCode,
    };
  }
};

export default getUserRating;


import Vue from 'vue';
import moment from 'moment';
import AtomButton, { ButtonVariant, ButtonSize } from '@/components/atoms/AtomButton.vue';
import AtomCircularButton from '@/components/atoms/AtomCircularButton.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import MoleculeDropdown from '@/components/molecules/dropdown/MoleculeDropdown.vue';
import OrganismLanguageSelectorModal, {
  LanguageSelector,
} from '@/components/organisms/language/OrganismLanguageSelectorModal.vue';
import { changeLocale } from '@/services/translation/TranslationService';

export default Vue.extend({
  name: 'OrganismFooter',
  components: {
    AtomButton,
    AtomCircularButton,
    AtomSvgIcon,
    MoleculeDropdown,
    OrganismLanguageSelectorModal,
  },
  data() {
    return {
      ButtonVariant,
      ButtonSize,
      footerNavItems: [
        {
          key: 'footer.tippsAndFAQ',
          href: 'https://www.twogo.com/faq',
        },
        {
          key: 'footer.licenses',
          href: 'https://www.twogo.com/web/content/Doc/:locale?name=used_licenses',
        },
        {
          key: 'footer.privacy',
          href: 'https://www.twogo.com/privacy',
        },
        {
          key: 'footer.cookies',
          href: 'https://www.twogo.com/cookies',
        },
        {
          key: 'footer.termsOfUse',
          href: 'https://www.twogo.com/termsofuse',
        },
        {
          key: 'footer.imprint',
          href: 'https://www.twogo.com/imprint',
        },
      ],
    };
  },
  computed: {
    getCurrentLocale() {
      return this.$t(`dictionary.locales.${this.$i18n.locale.replace('-', '_')}`);
    },
    getCopyright() {
      return `© ${moment().year()} twogo.com`;
    },
  },
  methods: {
    switchLocale({ locale }: LanguageSelector) {
      if (this.$i18n.locale !== locale) {
        const to = this.$router.resolve({ params: { locale } });
        changeLocale(locale).then(() => {
          this.$router.push(to.location);
        });
      }
    },
  },
});

enum RouteNamesEnum {
  LOGIN_REGISTRATION = 'LoginRegistration',
  HOME = 'Home',
  SEARCH_RIDES = 'SearchRides',
  SEARCH_RIDE_DETAIL = 'SearchRidesDetails',
  WIZARD_RIDE = 'CreateRide',
  RIDE_DETAILS = 'RideDetails',
  PROFILE = 'Profile',
  PROFILE_USER = 'ProfileUser',
  USER_INFO = 'User Information',
  LOCATIONS = 'MyLocations',
  LOCATIONS_CREATE = 'CreateMyLocations',
  LOCATIONS_EDIT = 'EditMyLocations',
  CARS = 'MyCars',
  TIMETABLE_KIOSK = 'TimetableKiosk',
  TIMETABLE_PORTAL = 'TimetableWeb',
  TIMETABLE_CONFIG_PORTAL = 'TimetableConfigPortal',
  TIMETABLE_CONFIG_KIOSK = 'TimetableConfigKiosk',
  TIMETABLE_POI_PORTAL = 'TimetablePoiPortal',
  TIMETABLE_POI_KIOSK = 'TimetablePoiKiosk',
  SETTINGS = 'Settings',
  SETTINGS_HELP = 'Help',
  LOTTERY_TOP = 'LotteryTermsOfParticipation',
  SETTINGS_RIDE = 'RideSettings',
  SETTINGS_SECURITY = 'SecuritySettings',
  SETTINGS_NOTIFICATIONS = 'SecurityNotifications',
  MOBILE_DEVICE = 'MobileDevice',
  SETTINGS_REGION = 'RegionSettings',
  SETTINGS_ACCOUNT = 'AccountSettings',
  SHARED_RIDES = 'SharedRides',
  SHARED_RIDE_DETAIL = 'SHARED_RIDE_DETAIL',
}

export default RouteNamesEnum;

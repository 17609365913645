import Vue from 'vue';

export const addCustomVueDirectives = () => {
  Vue.directive('visible', (el, binding) => {
    // eslint-disable-next-line no-param-reassign
    el.style.visibility = binding.value ? 'visible' : 'hidden';
  });
};

export default addCustomVueDirectives;

import { NavigationGuardNext, Route } from 'vue-router';
import { isLocaleSupported, getUserSupportedLocale, changeLocale } from '@/services/translation/TranslationService';

/**
 * Middleware to change the locale based on :locale param
 *
 * @param to Route
 * @param from Route
 * @param next NavigationGuardNext
 */
export const routeMiddleware = (to: Route, _from: Route, next: NavigationGuardNext) => {
  const { path, query, params: { locale } } = to;

  // Check for 404 page
  if (to.matched.some((match) => match.path === '*')) {
    return next();
  }

  if (path === (!locale ? '/main' : `/${locale}/main`)) {
    const cachedLocale = localStorage.getItem('locale') || locale;
    const cameFrom = sessionStorage.getItem('authMiddlewareTo');

    if (cameFrom) {
      const nextRoute = JSON.parse(cameFrom);
      nextRoute.params.locale = cachedLocale || getUserSupportedLocale();

      return next(nextRoute);
    }
    return next({
      path: !cachedLocale ? `/${getUserSupportedLocale()}/home` : `/${cachedLocale}/home`,
    });
  }

  if (path === '/resetpassword') {
    return next({
      path: `/${getUserSupportedLocale()}/resetpassword`,
      query,
    });
  }

  if (locale === undefined) {
    return next({
      path: getUserSupportedLocale() + path,
      query,
    });
  }

  if (!isLocaleSupported(locale)) {
    const [_, ...rest] = path.split('/').slice(1);
    return next({
      path: `${getUserSupportedLocale()}/${rest.join('/')}`,
      query,
    });
  }

  return changeLocale(locale).then(() => {
    next({
      query,
    });
  });
};

export default routeMiddleware;

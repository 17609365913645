import {
  ActionContext as DefaultActionContext,
  ActionTree,
} from 'vuex';
import moment from 'moment';
import {
  RideIntentState as State,
  SelectedCar,
  CarRentalData,
  RideIntentCreationState,
} from '@/store/modules/ride-intent/state';
import SwitchEnum from '@/enums/switch/SwitchEnum';
import RolesEnum from '@/enums/rides/RolesEnum';
import { StepperSteps } from '@/enums/stepper/StepperEnum';
import RideIntentsTypeEnum from '@/enums/rides/RideIntentsTypeEnum';
import CarTypeEnum from '@/enums/car/CarTypeEnum';
import GenericAddressModel from '@/models/geolocation/GenericAddressModel';
import { deleteIntentById, deleteRecurrencesById, deleteRecurringRoundtripById, deleteRoundtripById, getIntentInviteById, getIntentRecurrenceById } from '@/api/ride-intent/rideIntentApi';
import { revGeoCode } from '@twogo/geolocation/src/lib/geolocationService';
import { getGenericAddress } from '@/services/geolocation/genericAddressBuilder';
import RideIntentModel from '@/models/ride-intent/RideIntentModel';
import { Mutations } from './mutations';

type ActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1]
  ): ReturnType<Actions[K]>;
  rootState: any;
} & Omit<DefaultActionContext<State, State>, 'commit | dispatch'>

export interface Actions {
  hydrateWizard(
    { commit, rootState, rootGetters }: ActionContext,
    { id, editIntention, sequenceId }: {id: string; sequenceId: string; editIntention: RideIntentsTypeEnum[] },
  ): Promise<void>;
  jumpToStep({ commit }: ActionContext, payload: StepperSteps): void;
  setOutboundIntentId({ commit }: ActionContext, payload: string): void;
  setInboundIntentId({ commit }: ActionContext, payload: string): void;
  setSelectedRole({ commit }: ActionContext, payload: RolesEnum): Promise<void>;
  selectRole({ dispatch, commit }: ActionContext, payload: RolesEnum): Promise<void>;
  toggleOutwardTripSteps({ commit }: ActionContext, payload: SwitchEnum): void;
  addStartPointLocation({ commit }: ActionContext, payload: GenericAddressModel | null): void;
  addWaypoint({ commit }: ActionContext, payload: GenericAddressModel | null): void;
  updateWaypoint(
    { commit }: ActionContext, payload: { location: GenericAddressModel | null; index: number }
  ): void;
  removeWaypoint({ commit }: ActionContext, payload: number): void;
  validateOutboundTripRoute({ commit }: ActionContext): void;
  autoCompleteReturnTripDetails({ commit }: ActionContext): void;
  setOutboundEarliestDeparture(
    { commit }: ActionContext, payload: { value: moment.Moment; keepLatestArrivalInSync: boolean }
  ): void;
  setOutboundLatestArrival({ commit }: ActionContext, payload: moment.Moment): void;
  setReturnTripEarliestDeparture(
    { commit }: ActionContext, payload: { value: moment.Moment; keepLatestArrivalInSync: boolean }
  ): void;
  setReturnTripLatestArrival({ commit }: ActionContext, payload: moment.Moment): void;
  setSelectedCar({ commit }: ActionContext, payload: SelectedCar): void;
  addCarRentalData(
    { commit }: ActionContext, payload: { value: string; type: CarRentalData }
  ): void;
  addCarRentalSeats({ commit }: ActionContext, payload: number): void;
  validateCarSelection({ commit }: ActionContext): void;
  shareCreatedRideIntent(): void;
  rideIntentCreationSuccessful({ commit }: ActionContext, shareLink: string): void;
  rideIntentCreationFailed({ commit }: ActionContext): void;
  resetState({ commit }: ActionContext): void;
  setDefaultDetour({ commit }: ActionContext): void;
  setDetour({ commit }: ActionContext, payload: number): void;
  setClaimsPayment({ commit }: ActionContext, payload: boolean): void;
  setOffersPayment({ commit }: ActionContext, payload: boolean): void;
  setMeetingPoint({ commit }: ActionContext, payload: string): void;
  setReturnMeetingPoint({ commit }: ActionContext, payload: string): void;
  deleteIntentById({ commit }: ActionContext, id: string): void;
  deleteRoundtripById({ commit }: ActionContext, id: string): void;
  setErrorCode({ commit }: ActionContext, payload: string): void;
}

const actions: ActionTree<State, State> & Actions = {
  async applyFirstLegOnWizard({ dispatch, commit }, intent: RideIntentModel) {
    const {
      role,
      origin,
      destination,
      waypoints,
      poolCarRequest,
      earliestDeparture,
      latestArrival,
      car,
      maxDetourMinutes,
      businessTrip,
      offersPayment,
      claimsPayment,
      acceptedGender,
    } = intent;

    if (poolCarRequest) {
      dispatch('selectRole', RolesEnum.DRIVER_AND_PASSENGER);
    } else {
      dispatch('selectRole', role);
    }

    dispatch('addStartPointLocation', getGenericAddress(origin));
    dispatch('addEndPointLocation', getGenericAddress(destination));
    waypoints.forEach(async ({ location: { latitude: lat, longitude: lng } }) => {
      const { data: geocode } = await revGeoCode({ at: { lat, lng } });
      dispatch('addWaypoint', geocode[0]);
    });
    dispatch('validateOutboundTripRoute');

    dispatch('setOutboundEarliestDeparture', { value: earliestDeparture, keepLatestArrivalInSync: false });
    dispatch('setOutboundLatestArrival', latestArrival);

    commit('setIsPoolCarRequest', poolCarRequest);
    if (role !== RolesEnum.PASSENGER) {
      if (poolCarRequest && car) {
        car.type = CarTypeEnum.POOL_CAR;
        car.freeSeats += 1;
      }
      dispatch('setSelectedCar', car);
    } else dispatch('setSelectedCar', null);

    dispatch('setDetour', maxDetourMinutes);
    dispatch('setBusinessTrip', businessTrip);
    dispatch('setClaimsPayment', claimsPayment);
    dispatch('setOffersPayment', offersPayment);
    dispatch('setLadiesOnly', acceptedGender === 'female');
  },
  applySecondLegOnWizard({ dispatch }, intent: RideIntentModel) {
    const {
      origin,
      destination,
      waypoints,
      earliestDeparture,
      latestArrival,
    } = intent;
    dispatch('addReturnStartPointLocation', getGenericAddress(origin));
    dispatch('addReturnEndPointLocation', getGenericAddress(destination));
    waypoints.forEach(async ({ location: { latitude: lat, longitude: lng } }) => {
      const { data: geocode } = await revGeoCode({ at: { lat, lng } });
      dispatch('addReturnWaypoint', geocode[0]);
    });
    dispatch('validateReturnTripRoute');
    dispatch('setReturnTripEarliestDeparture', { value: earliestDeparture, keepLatestArrivalInSync: false });
    dispatch('setReturnTripLatestArrival', latestArrival);
  },
  async hydrateWizard(
    {
      dispatch,
      commit,
      rootGetters,
    },
    { id, editIntention },
  ) {
    const getIntentLegs = (intentId: string|number, getters) => {
      const tripLegs = {
        firstLeg: undefined as undefined|RideIntentModel,
        secondLeg: undefined as undefined|RideIntentModel,
      };
      // Determine to which Leg belongs the edited Intent.
      const intent = getters['rides/getIntentById'](intentId) as RideIntentModel;
      if (intent.sequencePos === 1) tripLegs.firstLeg = intent;
      if (intent.sequencePos === 2) tripLegs.secondLeg = intent;
      // Determine if it is a Sequence Trip (Outward-Return trip) and find it.
      if (intent.sequenceId && intent.sequencePos === 1) {
        const intent2 = getters['rides/getIntentById'](intent.nextSequenceLeg) as RideIntentModel;
        tripLegs.secondLeg = intent2;
      } else if (intent.sequenceId && intent.sequencePos === 2) {
        // getSequenceIntent
        const intent1 = getters['rides/getSequenceIntent'](intent.sequenceId, 1) as RideIntentModel;
        tripLegs.firstLeg = intent1;
      }

      return tripLegs;
    };

    const { firstLeg, secondLeg } = getIntentLegs(id, rootGetters);

    if (!firstLeg) return Promise.reject(Error('Ride intent could not be fetched!'));

    // console.log(intents, editIntention, sequenceId);
    // console.log(getIntentLegs(id, rootGetters));

    // 2. If the intention is to edit the Recurrence, get the recurrence schedule.
    if (editIntention.includes(RideIntentsTypeEnum.RECURRENT)) {
      const { data: recurrence, status: recurrenceStatus } = await getIntentRecurrenceById(firstLeg.recurringIntentId || '');
      if (recurrenceStatus !== 200 || !recurrence) return Promise.reject(Error('The recurrence of the ride intent could not be fetched!'));
      if (!recurrence?.recurringIntent) Promise.reject(Error('The recurrence has no schedule assigned'));

      dispatch('setFrequencyType', recurrence?.recurringIntent?.schedule.frequency);
      dispatch('setBulkFrequency', recurrence?.recurringIntent?.schedule.weekday.split(','));
      dispatch('setFrequencyEnd', recurrence?.recurringIntent?.schedule.until ? moment(recurrence?.recurringIntent?.schedule.until) : null);
      dispatch('applyFirstLegOnWizard', recurrence);
    } else {
      dispatch('applyFirstLegOnWizard', firstLeg);
    }

    if (editIntention.includes(RideIntentsTypeEnum.SEQUENCE)) {
      if (!secondLeg) return Promise.reject(Error('Return trip could not be fetched'));

      commit('setInboundSequenceId', secondLeg.sequenceId);
      commit('setOutboundSequenceId', firstLeg.sequenceId);
      if (editIntention.includes(RideIntentsTypeEnum.SINGLE_INTENT)) dispatch('setInboundIntentId', secondLeg.intentId);
      else if (editIntention.includes(RideIntentsTypeEnum.RECURRENT)) dispatch('setInboundRecurrenceId', secondLeg.recurringIntentId);

      if (editIntention.includes(RideIntentsTypeEnum.RECURRENT)) {
        const { data: recurrenceLeg2, status: recurrenceStatusLeg2 } = await getIntentRecurrenceById(secondLeg.recurringIntentId || '');
        if (recurrenceStatusLeg2 !== 200 || !recurrenceLeg2) return Promise.reject(Error('The return recurrence of the ride intent could not be fetched!'));
        if (!recurrenceLeg2?.recurringIntent) Promise.reject(Error('The return recurrence has no schedule assigned'));
        dispatch('applySecondLegOnWizard', recurrenceLeg2);
      } else {
        dispatch('applySecondLegOnWizard', secondLeg);
      }
    }

    const { sequencePos } = firstLeg;
    if (editIntention.includes(RideIntentsTypeEnum.SINGLE_INTENT)) dispatch('setOutboundIntentId', firstLeg.intentId);
    else if (editIntention.includes(RideIntentsTypeEnum.RECURRENT)) dispatch('setOutboundRecurrenceId', firstLeg.recurringIntentId);
    dispatch(
      'toggleOutwardTripSteps',
      sequencePos === 1 && editIntention.includes(RideIntentsTypeEnum.SEQUENCE) ? SwitchEnum.ON : SwitchEnum.OFF,
    );

    return Promise.resolve();
  },

  async hydrateWizardWithInvite({ dispatch }, inviteId: string) {
    // ...
    const { data: intent } = await getIntentInviteById(inviteId);
    dispatch('applyFirstLegOnWizard', intent);
    console.log(intent);
  },
  // ***** end *****
  jumpToStep({ commit }, payload) {
    commit('jumpToStep', payload);
  },
  setOutboundIntentId({ commit }, payload) {
    commit('setOutboundIntentId', payload);
  },
  setInboundIntentId({ commit }, payload) {
    commit('setInboundIntentId', payload);
  },
  setOutboundRecurrenceId({ commit }, payload) {
    commit('setOutboundRecurrenceId', payload);
  },
  setInboundRecurrenceId({ commit }, payload) {
    commit('setInboundRecurrenceId', payload);
  },
  async setSelectedRole({ commit }, payload) {
    commit('setSelectedRole', payload);
  },
  async selectRole({ dispatch, commit }, payload) {
    await dispatch('setSelectedRole', payload);
    commit('validateRoleStep');
    commit('toggleCarSelectionStep');
    if (payload === RolesEnum.PASSENGER) {
      commit('clearWaypoints');
      commit('clearReturnWaypoints');
    }
  },
  toggleOutwardTripSteps({ commit }, payload) {
    commit('toggleOutwardTripSteps', payload);
  },
  addStartPointLocation: ({ commit }, payload) => {
    commit('addStartPointLocation', payload);
  },
  addEndPointLocation: ({ commit }, payload) => {
    commit('addEndPointLocation', payload);
  },
  addWaypoint: ({ commit }, payload) => {
    commit('addWaypoint', payload);
  },
  updateWaypoint: ({ commit }, payload) => {
    commit('updateWaypoint', payload);
  },
  removeWaypoint: ({ commit }, payload) => {
    commit('removeWaypoint', payload);
  },
  switchOriginAndArrival: ({ commit }) => {
    commit('switchOriginAndArrival');
  },
  validateOutboundTripRoute: ({ commit }) => {
    commit('validateOutboundTripRoute');
  },
  // Mutations for Return trip details
  switchReturnOriginAndArrival: ({ commit }) => {
    commit('switchReturnOriginAndArrival');
  },
  autoCompleteReturnTripDetails: ({ commit }) => {
    commit('autoCompleteReturnTripDetails');
  },
  addReturnStartPointLocation: ({ commit }, payload) => {
    commit('addReturnStartPointLocation', payload);
  },
  addReturnEndPointLocation: ({ commit }, payload) => {
    commit('addReturnEndPointLocation', payload);
  },
  addReturnWaypoint: ({ commit }, payload) => {
    commit('addReturnWaypoint', payload);
  },
  updateReturnWaypoint: ({ commit }, payload) => {
    commit('updateReturnWaypoint', payload);
  },
  removeReturnWaypoint: ({ commit }, payload) => {
    commit('removeReturnWaypoint', payload);
  },
  validateReturnTripRoute: ({ commit }) => {
    commit('validateReturnTripRoute');
  },
  // Date time selection
  async setOutboundEarliestDeparture({ commit }, payload) {
    commit('setOutboundEarliestDeparture', payload);
    commit('validateOutboundTrip');
  },
  setOutboundLatestArrival({ commit, dispatch, state }, payload: moment.Moment) {
    commit('setOutboundLatestArrival', payload);
    if (!payload.isSame(state.returnTrip.earliestDeparture, 'day')) {
      dispatch(
        'setReturnTripEarliestDeparture',
        { value: moment(payload).hours(17).minutes(0), keepLatestArrivalInSync: true },
      );
      dispatch('setReturnTripLatestArrival', moment(payload).hours(19).minutes(0));
    }
    commit('validateOutboundTrip');
    commit('validateReturnTrip');
  },
  setReturnTripEarliestDeparture({ commit }: ActionContext, payload) {
    commit('setReturnTripEarliestDeparture', payload);
    commit('validateReturnTrip');
  },
  setReturnTripLatestArrival({ commit }: ActionContext, payload: moment.Moment) {
    commit('setReturnTripLatestArrival', payload);
    commit('validateReturnTrip');
  },
  setSelectedCar({ commit }: ActionContext, payload: SelectedCar) {
    commit('setSelectedCar', payload);
    commit('validateCarSelection');
  },
  addCarRentalData({ commit }: ActionContext, payload) {
    commit('addCarRentalData', payload);
    commit('validateCarSelection');
  },
  addCarRentalSeats({ commit }: ActionContext, payload) {
    commit('addCarRentalSeats', payload);
  },
  validateCarSelection({ commit }: ActionContext) {
    commit('validateCarSelection');
  },
  setBusinessTrip({ commit }, payload) {
    commit('setBusinessTrip', payload);
  },
  setLadiesOnly({ commit }, payload) {
    commit('setLadiesOnly', payload);
  },
  setClaimsPayment({ commit }, payload) {
    commit('setClaimsPayment', payload);
  },
  setOffersPayment({ commit }, payload) {
    commit('setOffersPayment', payload);
  },
  setFrequencyType({ commit }, payload) {
    commit('setFrequencyType', payload);
    commit('clearFrequency');
  },
  setFrequency({ commit }, payload) {
    commit('setFrequency', payload);
  },
  removeFrequency({ commit }, payload) {
    commit('removeFrequency', payload);
  },
  setBulkFrequency({ commit }, payload) {
    commit('setBulkFrequency', payload);
  },
  setFrequencyEnd({ commit }, payload) {
    commit('setFrequencyEnd', payload);
  },
  clearFrequency({ commit }) {
    commit('clearFrequency');
  },
  setOrganizationId({ commit }, payload) {
    commit('setOrganizationId', payload);
  },
  setDetour({ commit }, payload) {
    commit('setDetour', payload);
  },
  setDefaultDetour({ commit }) {
    commit('setDefaultDetour');
  },
  shareCreatedRideIntent() {
    // TODO: implement sharing, probably call backend service
  },
  rideIntentCreationFailed({ commit }: ActionContext) {
    commit('updateRideIntentCreationState', RideIntentCreationState.TRIED_AND_FAILED);
  },
  rideIntentCreationSuccessful({ commit }: ActionContext, shareLink: string) {
    commit('setCreatedRideIntentShareLink', shareLink);
    commit('updateRideIntentCreationState', RideIntentCreationState.TRIED_AND_SUCCEEDED);
  },
  resetState({ commit }: ActionContext) {
    commit('resetState');
  },
  setMeetingPoint({ commit }, payload) {
    commit('addMeetingPoint', payload);
  },
  setReturnMeetingPoint({ commit }, payload) {
    commit('addReturnMeetingPoint', payload);
  },
  setErrorCode({ commit }, payload) {
    commit('setErrorCode', payload);
  },
  async deleteIntentById(_, id) {
    const { status } = await deleteIntentById(id);
    if (status === 200) {
      // commit('deleteIntentById', id);

      return Promise.resolve({ status });
    }

    return Promise.reject(Error('Delete Intent failed.'));
  },
  async deleteRoundtripById(_, id) {
    const { status } = await deleteRoundtripById(id);
    if (status === 200) {
      // commit('deleteIntentById', id);

      return Promise.resolve({ status });
    }

    return Promise.reject(Error('Delete Intent failed.'));
  },
  async deleteRecurrencesById(_, id) {
    const { status } = await deleteRecurrencesById(id);
    if (status === 200) {
      // commit('deleteIntentById', id);

      return Promise.resolve({ status });
    }

    return Promise.reject(Error('Delete Intent failed.'));
  },
  async deleteRecurringRoundtripById(_, id) {
    const { status } = await deleteRecurringRoundtripById(id);
    if (status === 200) {
      // commit('deleteIntentById', id);

      return Promise.resolve({ status });
    }

    return Promise.reject(Error('Delete Intent failed.'));
  },
};

export default actions;

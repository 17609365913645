
import Vue from 'vue';

export default Vue.extend({
  name: 'AtomActionText',
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    action: {
      type: Function,
      required: true,
    },
    className: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});


import Vue from 'vue';
import { BForm } from 'bootstrap-vue';
import i18n from '@/i18n';
import { registerNewsletter } from '@/api/newsletter/newsletterApi';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomCheckbox from '@/components/atoms/AtomCheckbox.vue';
import AtomNotification, { NotificationVariant } from '@/components/atoms/AtomNotification.vue';
import AtomPasswordInput from '@/components/atoms/AtomInput/AtomPasswordInput.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomTextInput from '@/components/atoms/AtomInput/AtomTextInput.vue';
import { InputRequirement } from '@/components/atoms/AtomInput/AtomBaseInput.vue';
import { EMAIL_REGEX, PASSWORD_REGEX, NAME_REGEX } from '@/common/config';
import { directRegister } from '@twogo/cidaas/src/lib/CidaasService';
import { UserRegisterModel } from '@twogo/cidaas/src/lib/models/register/request/UserRegisterModel';
import { NewsletterRegisterModel } from '@/models/newsletter/NewsletterRegisterModel';
import NewsletterTypeEnum from '@/models/newsletter/NewsletterTypeEnum';
import RegistrationError from '@/enums/login-registration/RegistrationErrorEnum';

export interface RegisterComplete {
  email: string;
  newsletterError: boolean;
}

export default Vue.extend({
  name: 'OrganismRegister',
  components: {
    AtomButton,
    AtomCheckbox,
    AtomNotification,
    AtomPasswordInput,
    AtomText,
    AtomTextInput,
    BForm,
  },
  data: () => ({
    ButtonVariant,
    NotificationVariant,
    email: '',
    firstName: '',
    name: '',
    password: '',
    newsletter: false,
    state: 0,
    passwordReqMatch: false,
    validFields: {
      firstName: false,
      name: false,
      password: false,
      email: false,
    },
    emailRequirements: [{
      validator: (input) => EMAIL_REGEX.test(input),
      showOnValid: false,
      description: i18n.t('register.emailRequirements').toString(),
    }],
    nameRequirements: [{
      validator: (input) => NAME_REGEX.test(input) && (input.length >= 2 && input.length <= 50),
      showOnValid: false,
      description: i18n.t('register.namesRequirements').toString(),
      formatDescriptionAsHTML: true,
    }],
    registerTriggered: false,
    isUserAlreadyRegistered: false,
    isEmailRestricted: false,
    isLoading: false,
  }),
  computed: {
    areRequiredFieldsCompleted(): boolean {
      return !Object.values(this.validFields).includes(false);
    },
  },
  methods: {
    getPasswordRequirements(): InputRequirement[] {
      const inputRequirements = [] as InputRequirement[];
      PASSWORD_REGEX.forEach((regex, key) => inputRequirements.push({
        validator: (input) => regex.test(input || ''),
        showOnValid: true,
        description: i18n.t(`register.passwordRequirements.option-${key}`).toString(),
      }));
      return inputRequirements;
    },
    validateInputs(): boolean {
      const validFields = Object.values(this.validFields).filter((field) => field).length;
      return validFields === Object.keys(this.validFields).length;
    },
    async registerUser() {
      this.registerTriggered = true;
      this.isLoading = true;
      if (!this.areRequiredFieldsCompleted) {
        return;
      }

      const { token: registrationToken } = this.$router.currentRoute.params;
      const user: UserRegisterModel = {
        email: this.email,
        givenName: this.firstName.trim(),
        familyName: this.name.trim(),
        password: this.password,
        passwordEcho: this.password,
        mobileNumber: '',
        provider: 'SELF',
        registrationToken,
      };

      try {
        const { status } = await directRegister('de-de', user);
        if (status === 200) {
          const update: RegisterComplete = {
            email: this.email,
            newsletterError: false,
          };

          if (this.newsletter) {
            const { status: success } = await this.subscribeNewsletter();
            update.newsletterError = success !== 201;
          }

          this.$emit('sent', update);
        } else if (status === 409) {
          this.isUserAlreadyRegistered = true;
        } else if (status === 507 || status === 10000) {
          this.state = RegistrationError.INVALID_EMAIL;
        } else if (status === 400) {
          this.isEmailRestricted = true;
        }
        this.isLoading = false;
      } catch (err) {
        console.warn(err);
      }
    },
    async subscribeNewsletter() {
      const user: NewsletterRegisterModel = {
        email: this.email,
        newsletterType: NewsletterTypeEnum.TWOGO_CARPOOL,
        language: this.$i18n.locale,
      };
      return registerNewsletter(user);
    },
    checkboxChange(change) {
      this.newsletter = change;
    },
  },
});

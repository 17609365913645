import { http } from '@/services/http/HTTPService';
import { APIGEE_DOMAIN, COMMUNITY_SERVICE_URL } from '@/common/config';
import CommunityUserResponse from '@/models/community/response/CommunityUserResponseModel';
import mapBlockedUserResponseToModel from '@/models/community/MapBlockedUserResponseToModel';
import mapCommunityUserResponseToModel from '@/models/community/MapCommunityUserResponseToModel';
import BlockedUserResponse from '@/models/community/response/BlockedUserResponseModel';
import BlockedUsersResponse from '@/models/community/response/BlockedUsersResponseModel';
import BaseResponse from '@/models/IBaseResponse';
import RideIntentsResponse from '@/models/ride-intent/response/RideIntentResponse';
import mapRideIntentResponseToModel from '@/models/ride-intent/MapRideIntentResponseToModel';

export const follow = async (userId: string, following: string[]): Promise<CommunityUserResponse> => {
  const { status, data } = await http.post(
    `${APIGEE_DOMAIN}/${COMMUNITY_SERVICE_URL}/communities/follow`,
    { following, userId },
    { params: { userId } },
  );
  return { status, data: (data.result || []).map(mapCommunityUserResponseToModel) };
};

export const unfollow = async (userId: string, unfollowing: string): Promise<CommunityUserResponse> => {
  const { status, data } = await http.post(
    `${APIGEE_DOMAIN}/${COMMUNITY_SERVICE_URL}/communities/unfollow`,
    { unfollowing },
    { params: { userId } },
  );
  return { status, data: (data.result || []).map(mapCommunityUserResponseToModel) };
};

export const block = async (userId: string, firstName: string, lastName: string): Promise<BlockedUserResponse> => {
  const { status, data } = await http.post(
    `${APIGEE_DOMAIN}/${COMMUNITY_SERVICE_URL}/communities/blacklist`,
    { firstName, lastName, userId },
    { params: { userId } },
  );
  return {
    status,
    data: await mapBlockedUserResponseToModel(data.result) || {},
  };
};

export const unblock = async (blacklistEntryId: string): Promise<{ status: unknown; data: unknown }> => {
  try {
    const { status, data } = await http.delete(
      `${APIGEE_DOMAIN}/${COMMUNITY_SERVICE_URL}/communities/blacklist/${blacklistEntryId}`,
    );

    return {
      status,
      data,
    };
  } catch (error) {
    return { status: error?.response?.status || 404, data: {} };
  }
};

export const getBlockedUsers = async (userId: string): Promise<BlockedUsersResponse> => {
  try {
    const { status, data } = await http.get(
      `${APIGEE_DOMAIN}/${COMMUNITY_SERVICE_URL}/communities/blacklist`,
      { params: { userId } },
    );
    return { status, data: (data.result || []).map(mapBlockedUserResponseToModel) };
  } catch (error) {
    return { status: error?.response?.status || 404, data: [] };
  }
};

export const shareRideWithFollowers = async (communityId: string, intentId: string): Promise<BaseResponse> => {
  try {
    const { status } = await http.post(
      `${APIGEE_DOMAIN}/${COMMUNITY_SERVICE_URL}/communities/${communityId}/intents/${intentId}`,
      {
        communityId,
        intentId,
      },
    );
    return { status };
  } catch (error) {
    return { status: error?.response?.status || 404 };
  }
};

export const fetchCommunityIntents = async (communityId: string): Promise<RideIntentsResponse> => {
  try {
    const { status, data } =
      await http.get(`${APIGEE_DOMAIN}/${COMMUNITY_SERVICE_URL}/communities/${communityId}/intents`);
    return { status, data: (data.result || []).map(mapRideIntentResponseToModel) };
  } catch (error) {
    return { status: error?.response?.status || 404 };
  }
};


import Vue from 'vue';
import {
  getBrandedCompanyLogo,
  getBrandingById,
  injectBrandingIntoDocument,
} from '@/services/branding/BrandingService';
import OrganismNavigation from '@/components/organisms/navigation/OrganismNavigation.vue';
import OrganismFooter from '@/components/organisms/footer/OrganismFooter.vue';
import isMobileDevice from '@/services/utilities/deviceDetection';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'App',
  metaInfo: {
    titleTemplate: 'twogo%s',
  },
  components: {
    OrganismNavigation,
    OrganismFooter,
  },
  data() {
    return {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      unsubscribe: () => {},
      isOnMobilePage: false,
      pathWizard: false,
    };
  },
  computed: {
    ...mapGetters('user', ['site']),
    isMobilePage(): boolean {
      return this.isOnMobilePage;
    },
    getCompanyLogo() {
      const company = getBrandingById(this.site?.companyId);
      return company ? getBrandedCompanyLogo(company) : undefined;
    },
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'user/setUser') {
        if (mutation.payload) {
          const company = getBrandingById(mutation.payload.site.companyId);
          if (company) {
            injectBrandingIntoDocument(company);
          }
        }
      }
    });
  },
  beforeMount() {
    this.isOnMobilePage = isMobileDevice();
  },
  watch: {
    $route(to) {
      this.isOnMobilePage = to.name === 'MobileDevice';
      if (this.$route.path.indexOf('create/ride') >= 0) {
        this.pathWizard = true;
      } else this.pathWizard = false;
    },
  },
});

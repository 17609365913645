
import Vue from 'vue';
import InlineSvg from 'vue-inline-svg';
import AtomBaseInput from '@/components/atoms/AtomInput/AtomBaseInput.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';

export default Vue.extend({
  name: 'AtomTextInput',
  extends: AtomBaseInput,
  props: {
    button: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    deleteButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ButtonVariant,
    };
  },
  // Using InlineSvg here as AtomSvgIcon creates caching problem -> no icons will change
  // TODO: Investigate
  components: {
    AtomButton,
    AtomSvgIcon,
    InlineSvg,
  },
  watch: {
    hasError() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.validateInput();
    },
  },
});

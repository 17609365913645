
import Vue from 'vue';
import { BForm } from 'bootstrap-vue';
import AtomText from '@/components/atoms/AtomText.vue';
import MoleculeVerifyEmailText from '@/components/molecules/verify-email/MoleculeVerifyEmailText.vue';
import AtomNotification, { NotificationVariant } from '@/components/atoms/AtomNotification.vue';
import { NewsletterRegisterModel } from '@/models/newsletter/NewsletterRegisterModel';
import { registerNewsletter } from '@/api/newsletter/newsletterApi';
import NewsletterTypeEnum from '@/models/newsletter/NewsletterTypeEnum';
import AtomActionText from '@/components/atoms/AtomActionText.vue';

export default Vue.extend({
  name: 'OrganismVerifyEmail',
  components: {
    AtomActionText,
    AtomText,
    MoleculeVerifyEmailText,
    AtomNotification,
    BForm,
  },
  props: {
    email: {
      type: String,
      required: true,
    },
    newsletterError: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    newsletterSuccess: false,
    NotificationVariant,
  }),
  mounted() {
    this.newsletterSuccess = !this.newsletterError;
  },
  methods: {
    async resend() {
      this.newsletterSuccess = true;
      const user: NewsletterRegisterModel = {
        email: this.email,
        newsletterType: NewsletterTypeEnum.TWOGO_CARPOOL,
        language: this.$i18n.locale,
      };
      const { status: success } = await registerNewsletter(user);
      this.newsletterSuccess = success === 201;
    },
  },
});

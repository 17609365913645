
import Vue from 'vue';
import { BButton } from 'bootstrap-vue';

export default Vue.extend({
  name: 'AtomCircularButton',
  components: {
    BButton,
  },
});

import { http } from '@/services/http/HTTPService';
import RideIntentsResponse, { RideIntentResponse, RideIntentResponseModel } from '@/models/ride-intent/response/RideIntentResponse';
import { RideIntentState, Trip, Location, SelectedCar, TripFrequency } from '@/store/modules/ride-intent/state';
import { formatAddress } from '@/common/GeoFormatters';
import GenericAddressModel from '@/models/geolocation/GenericAddressModel';
import mapRideIntentResponseToModel from '@/models/ride-intent/MapRideIntentResponseToModel';
import { APIGEE_DOMAIN, RIDE_INTENT_SERVICE_URL } from '@/common/config';
import RolesEnum from '@/enums/rides/RolesEnum';
import CarTypeEnum from '@/enums/car/CarTypeEnum';
import RideIntentModel from '@/models/ride-intent/RideIntentModel';

const BASE_URL = `${APIGEE_DOMAIN}/${RIDE_INTENT_SERVICE_URL}`;

export const getIntents = async (userId: string): Promise<RideIntentsResponse> => {
  const options = {
    params: {
      userId,
    },
  };
  try {
    const { status, data } = await http.get(
      BASE_URL,
      options,
    );

    return {
      status,
      data: (data?.result || []).map(
        (intent: RideIntentResponseModel) => mapRideIntentResponseToModel(intent),
      ),
    };
  } catch (error) {
    return { status: error?.response?.status || 404, data: [] };
  }
};

export const getIntentById = async (id: string): Promise<RideIntentResponse> => {
  try {
    const { status, data: { result } } = await http.get(
      `${BASE_URL}/${id}`,
    );

    return {
      status,
      data: mapRideIntentResponseToModel(result),
    };
  } catch (error) {
    return {
      status: error.response.status || 404,
    };
  }
};

export const getIntentInviteById = async (id: string): Promise<RideIntentResponse> => {
  try {
    const { status, data: { result } } = await http.get(
      `${BASE_URL}/invite/${id}`,
    );

    return {
      status,
      data: mapRideIntentResponseToModel(result),
    };
  } catch (error) {
    return {
      status: error.response.status || 404,
    };
  }
};

export const getIntentRecurrenceById = async (id: string): Promise<RideIntentResponse> => {
  try {
    const { status, data: { result } } = await http.get(
      `${BASE_URL}/recurrences/${id}`,
    );

    return {
      status,
      data: mapRideIntentResponseToModel(result),
    };
  } catch (error) {
    console.error(error);
    return {
      status: error.response.status || 404,
    };
  }
};

const getWaypoint = ({ location }: Location) => ({
  label: formatAddress({ address: location?.address } as GenericAddressModel),
  comment: '',
  location: {
    latitude: location?.position.lat,
    longitude: location?.position.lng,
  },
  poiName: location?.name,
  externalId: '',
});

export type PoolCar = { poolCar: { poolcarrequest: true; freeSeats: number} };
export type OwnCar = { ownCar: { id: string; carId: string; freeSeats: number } };
export type TempCar = { tempCar: { licensePlate?: string; description?: string; freeSeats: number } };
const getCarDetails = (selectedCar: SelectedCar | null): PoolCar | OwnCar | TempCar | null => {
  if (!selectedCar) return null;
  const { freeSeats = 3, id } = selectedCar;
  if (selectedCar.type === CarTypeEnum.POOL_CAR) {
    return {
      poolCar: {
        poolcarrequest: true,
        freeSeats: freeSeats - 1,
      },
    };
  }
  if ((selectedCar.type === CarTypeEnum.OWN_CAR)) {
    return { ownCar: { id, carId: id, freeSeats } };
  }
  if ((selectedCar.type === CarTypeEnum.RENTAL_CAR)) {
    return {
      tempCar: {
        licensePlate: selectedCar.plating,
        description: selectedCar.model,
        freeSeats,
      },
    };
  }

  return null;
};

const getTripData = (trip: Trip, role: RolesEnum | null, selectedCar: SelectedCar | null) => ({
  origin: {
    ...getWaypoint(trip.startPoint),
    comment: trip.meetingPoint,
    dateTime: trip.earliestDeparture.format('YYYY-MM-DDTHH:mm:ssZ'),
  },
  destination: {
    ...getWaypoint(trip.endPoint),
    dateTime: trip.latestArrival.format('YYYY-MM-DDTHH:mm:ssZ'),
  },
  waypoints: trip.waypoints.map((waypoint) => getWaypoint(waypoint)),
  car: role === RolesEnum.PASSENGER ? null : getCarDetails(selectedCar),
});

const getBaseBody = (
  { role, other: { ladiesOnly, detour, businessTrip, repeat, claimsPayment, offersPayment } }: RideIntentState,
  userId: string,
) => ({
  userId,
  role,
  businessTrip,
  claimsPayment,
  offersPayment,
  onlyFemaleAccepted: ladiesOnly,
  maxDetourMinutes: detour,
  recurringIntent: repeat.frequency === TripFrequency.RECURRENT
    ? {
      schedule: {
        frequency: TripFrequency.RECURRENT,
        weekday: repeat.on.join(','),
        until: repeat.until ? repeat.until.format('YYYYMMDDTHHmmssZ') : undefined,
      },
    }
    : undefined,
});

export const updateIntent = async (
  userId: string,
  rideIntentState: RideIntentState,
  intent: Trip,
) => {
  const body = {
    id: intent.id,
    ...getBaseBody(rideIntentState, userId),
    ...getTripData(intent, rideIntentState.role, rideIntentState.selectedCar),
  };

  try {
    const { status } = await http.put(
      BASE_URL,
      body,
    );

    return status;
  } catch (error) {
    return error;
  }
};
export const updateRecurrence = async (
  userId: string,
  rideIntentState: RideIntentState,
  recurrence: Trip,
) => {
  const body = {
    id: recurrence.recurrenceId,
    ...getBaseBody(rideIntentState, userId),
    ...getTripData(recurrence, rideIntentState.role, rideIntentState.selectedCar),
  };

  try {
    const { status } = await http.put(
      `${BASE_URL}/recurrences`,
      body,
    );

    return status;
  } catch (error) {
    return error;
  }
};

export const postIntent = async (
  userId: string,
  rideIntentState: RideIntentState,
  isTwoWayTrip: boolean,
) => {
  const {
    outboundTrip,
    returnTrip,
    role,
    selectedCar,
  } = rideIntentState;

  const body = {
    ...getBaseBody(rideIntentState, userId),
    outward: getTripData(outboundTrip, role, selectedCar),
    returnTrip: !isTwoWayTrip
      ? undefined
      : getTripData(returnTrip, role, selectedCar),
  };
  // TODO: This fix is temporary until the backend is fixed
  if (body.recurringIntent?.schedule.until) {
    body.recurringIntent.schedule.until = rideIntentState.other.repeat.until?.minute(23 * 60 - rideIntentState.other.repeat.until?.utcOffset()).second(59).format('YYYYMMDDTHHmmssZ');
  }

  try {
    const { status, data } = await http.post(
      BASE_URL,
      body,
    );

    return { status, data: data.result };
  } catch (error) {
    return error.response;
  }
};

const getBaseBodyFromIntent = (
  { role, maxDetourMinutes, businessTrip, claimsPayment, offersPayment }: RideIntentModel,
  userId: string,
  specifiedRole?: RolesEnum,
) => ({
  userId,
  role: specifiedRole || role,
  businessTrip,
  claimsPayment,
  offersPayment,
  onlyFemaleAccepted: false,
  maxDetourMinutes,
  recurringIntent: undefined,
});

/**
 * Create and Ride Intent based on a Intent Object provided by the Backend
 * For example from the Invite service or Ride Search service
 *
 * @param userId UUID
 * @param intent RideIntentModel
 *
 * @returns void
 */
export const createIntent = async (
  userId: string,
  intent: RideIntentModel,
  specifiedRole?: RolesEnum,
) => {
  const body = {
    ...getBaseBodyFromIntent(intent, userId, specifiedRole),
    outward: {
      origin: {
        ...intent.origin,
        dateTime: intent.earliestDeparture.format('YYYY-MM-DDTHH:mm:ssZ'),
      },
      destination: {
        ...intent.destination,
        dateTime: intent.latestArrival.format('YYYY-MM-DDTHH:mm:ssZ'),
      },
      waypoints: [],
    },
  };

  try {
    const { status, data } = await http.post(
      BASE_URL,
      body,
    );

    return { status, data };
  } catch (error) {
    // console.log(111, error.response);
    return error.response;
  }
};

export const deleteIntentById = async (id: string): Promise<RideIntentResponse> => {
  try {
    const { status } = await http.delete(
      `${BASE_URL}/${id}`,
    );

    return {
      status,
    };
  } catch (error) {
    console.error(error);
    return {
      status: error.response.status || 404,
    };
  }
};

export const deleteRoundtripById = async (id: string): Promise<RideIntentResponse> => {
  try {
    const { status } = await http.delete(
      `${BASE_URL}/sequences/${id}`,
    );

    return {
      status,
    };
  } catch (error) {
    console.error(error);
    return {
      status: error.response.status || 404,
    };
  }
};

export const deleteRecurrencesById = async (id: string): Promise<RideIntentResponse> => {
  try {
    const { status } = await http.delete(
      `${BASE_URL}/recurrences/${id}`,
    );

    return {
      status,
    };
  } catch (error) {
    console.error(error);
    return {
      status: error.response.status || 404,
    };
  }
};

export const deleteRecurringRoundtripById = async (id: string): Promise<RideIntentResponse> => {
  try {
    const { status } = await http.delete(
      `${BASE_URL}/recurrences/sequences/${id}`,
    );

    return {
      status,
    };
  } catch (error) {
    console.error(error);
    return {
      status: error.response.status || 404,
    };
  }
};

import CarTypeEnum from '@/enums/car/CarTypeEnum';
import { CarModel } from './CarModel';
import { CarResponseModel } from './response/CarResponseModel';

const mapCarResponseToModel = async (car: CarResponseModel, fetchCarImage: Function): Promise<CarModel> => ({
  id: car?.id?.toString(),
  type: CarTypeEnum.OWN_CAR,
  licensePlate: car.licensePlate ? car.licensePlate : car.plate,
  description: car.description,
  engineType: car.engineType,
  freeSeats: car.freeSeats,
  imageURL: car.imageURL,
  decodedImageURL: await fetchCarImage(car.imageURL),
  isDefaultCar: car.isDefaultCar,
  isTemporary: car.isTemporary,
});

export default mapCarResponseToModel;


import Vue, { PropType } from 'vue';

interface InputRequirementDisplay {
  status: boolean;
  description: string;
  showOnValid: boolean;
  formatDescriptionAsHTML?: boolean;
}

export interface InputRequirement {
  description: string;
  showOnValid: boolean;
  validator: (input: string | null | undefined) => boolean;
  formatDescriptionAsHTML?: boolean;
}

const AtomBaseInput = Vue.extend({
  name: 'AtomBaseInput',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    info: {
      type: String,
      default: '',
    },
    instruction: {
      type: String,
      default: '',
    },
    validateOnInit: {
      type: Boolean,
      default: false,
    },
    requirements: {
      type: Array as PropType<InputRequirement[]>,
      default: () => [],
    },
    requiredError: {
      type: String,
      required: false,
    },
    value: {
      type: String,
    },
    upperCase: {
      type: Boolean,
      default: false,
    },
    length: {
      type: Number,
      default: Infinity,
    },
  },
  data() {
    return {
      isActive: false,
      isInvalid: false,
      inputValue: this.value,
      inputRequirementDisplay: [] as InputRequirementDisplay[],
    };
  },
  watch: {
    value: 'initInput',
    inputValue: 'initInput',
    required: 'validateInput',
  },
  methods: {
    initInput(val: string) {
      this.inputValue = val;
      if (this.inputValue) this.validateInput();
    },
    mustDisplaySuccessMsg(status: boolean, showOnValid: boolean) {
      if (showOnValid) {
        return true;
      }

      return !status;
    },
    onInputInput(event: InputEvent) {
      const target = event.target as HTMLInputElement;
      if (this.upperCase) {
        const selection = target.selectionStart;
        target.value = target.value.toUpperCase();
        if (selection) target.setSelectionRange(selection, selection);
      }
      this.$emit('input', target.value);
      this.validateInput();
    },
    onInputFocus(event: FocusEvent) {
      this.$emit('focus', event);
      this.isActive = true;
    },
    onInputClick(event: PointerEvent) {
      this.$emit('click', event);
      this.isActive = true;
    },
    onInputBlur(event: FocusEvent) {
      this.$emit('blur', event);
      this.isActive = false;
    },
    validateInput() {
      if (this.requirements.length !== 0) {
        this.inputRequirementDisplay = [];
        let failCount = 0;
        this.requirements.forEach((requirement) => {
          const status = requirement.validator(this.inputValue || '');
          if (!status) {
            failCount += 1;
          }
          this.inputRequirementDisplay.push({
            status,
            description: requirement.description,
            showOnValid: requirement.showOnValid,
            formatDescriptionAsHTML: requirement.formatDescriptionAsHTML,
          });
        });
        this.setInvalid(failCount > 0);
      } else if (this.required && !this.inputValue) {
        this.setInvalid(true);
      } else {
        this.setInvalid(false);
      }
    },
    setInvalid(isInvalid: boolean) {
      this.isInvalid = isInvalid;
      this.$emit('validate', !isInvalid);
    },
    getClasses() {
      return {
        'input-field': true,
        'input-field--active': this.isActive,
        'input-field--filled': !!this.inputValue,
        'input-field--disabled': this.disabled,
        'input-field--invalid': this.isInvalid,
      };
    },
  },
  mounted() {
    if (this.validateOnInit) {
      this.validateInput();
    }
  },
});

export type AtomBaseInputRef = InstanceType<typeof AtomBaseInput>;
export default AtomBaseInput;

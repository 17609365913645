import { ActionContext as DefaultActionContext, ActionTree } from 'vuex';
import {
  PoiState as State,
} from '@/store/modules/poi/state';
import { Mutations } from '@/store/modules/poi/mutations';
import { getPoi, postPoi, editPoi, deletePoi } from '@/api/geolocation/poi/poiApi';
import PoiPostModel from '@/models/geolocation/poi/PoiPostModel';
import PoiPutModel from '@/models/geolocation/poi/PoiPutModel';
import PoiGetModel from '@/models/geolocation/poi/PoiGetModel';
import { PoiModel } from '@/models/geolocation/poi/PoiModel';
import PoiTypesEnum from '@/enums/geolocation/PoiTypesEnum';

type ActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1]
  ): ReturnType<Actions[K]>;
} & Omit<DefaultActionContext<State, State>, 'commit | dispatch'>

type PromiseResponse = {
  status: number;
  data?: PoiModel;
}
export interface Actions {
  fetchPoi({ commit }: ActionContext, payload: PoiGetModel): void;
  addPoi({ commit }: ActionContext, payload: PoiPostModel): Promise<PromiseResponse>;
  editPoi({ commit }: ActionContext, payload: PoiPutModel): Promise<PromiseResponse>;
  deletePoi({ commit }: ActionContext, payload: { id: string; type: PoiTypesEnum }): Promise<PromiseResponse>;
}

const actions: ActionTree<State, State> & Actions = {
  async fetchPoi({ commit }, { ownerId, type }) {
    const { status, data } = await getPoi(ownerId, type);

    commit('setPoi', data);
    return Promise.resolve({ status, data });
  },
  async addPoi({ commit }, payload) {
    const { data: [poi], status } = await postPoi(payload);
    if (status === 201) {
      commit('addPoi', poi);
      return Promise.resolve({ status, data: poi });
    }

    return Promise.reject(Error('Poi creation failed.'));
  },
  async editPoi({ commit }, payload) {
    const { data: [poi], status } = await editPoi(payload);
    if (status === 200) {
      commit('editPoi', poi);

      return Promise.resolve({ status, data: poi });
    }

    return Promise.reject(Error('Poi edit failed.'));
  },
  async deletePoi({ commit }, { id, type }) {
    const { status } = await deletePoi(id, type);
    if (status === 200) {
      commit('deletePoi', id);

      return Promise.resolve({ status });
    }

    return Promise.reject(Error('Poi edit failed.'));
  },
};

export default actions;

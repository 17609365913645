
import Vue from 'vue';
import AtomResendEmailRouterLink from '@/components/atoms/AtomResendEmailRouterLink.vue';
import { resendVerification } from '@twogo/cidaas/src/lib/CidaasService';

export default Vue.extend({
  name: 'AtomVerifyEmailText',
  components: { AtomResendEmailRouterLink },
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  methods: {
    async resendEmail() {
      await resendVerification();
      await this.$router.push({ path: `/${this.$i18n.locale}/verification/resend` });
    },
  },
});

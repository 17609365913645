
import Vue from 'vue';
// TODO: Remove this component. It is identical with "AtomRouterLink" component.
export default Vue.extend({
  name: 'AtomResendEmailRouterLink',
  props: {
    to: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
});

import UnratedTripModel from '@/models/ratings/UnratedTripModel';
import RideIntentModel from '@/models/ride-intent/RideIntentModel';
import { RideModel } from '@/models/ride/RideModel';

export interface RidesState {
  intents: RideIntentModel[];
  communityIntents: RideIntentModel[];
  rides: RideModel[];
  unratedRides: UnratedTripModel[];
}

const state = (): RidesState => ({
  intents: [],
  communityIntents: [],
  rides: [],
  unratedRides: [],
});

export default state;

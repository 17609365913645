
import Vue from 'vue';
import TemplateLoginRegistration from '@/components/templates/login-register/TemplateLoginRegistration.vue';

export default Vue.extend({
  name: 'Login',
  components: { TemplateLoginRegistration },
  metaInfo: {
    title: ' - Login',
  },
});

var render = function render(_c,_vm){return _c('div',{class:{
    'action_text-wrapper': true,
    [_vm.props.className]: true,
    disabled: _vm.props.disabled
  },on:{"click":() => {
    if(!_vm.props.disabled) {
      _vm.props.action();
    }
  }}},[(_vm.props.icon)?_c('AtomSvgIcon',{attrs:{"imageSrc":_vm.props.icon,"className":"action_text-image"}}):_vm._e(),_c('span',{staticClass:"action_text-title"},[_vm._v(_vm._s(_vm.props.title))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import firebase from 'firebase/app';
import 'firebase/messaging';
import firebaseConfig from '@/common/FirebaseConfig';

/* eslint lines-between-class-members: ["error", "always", { "exceptAfterSingleLine": true }] */
export default class FirebaseService {
  private messaging: firebase.messaging.Messaging;
  private isTokenSendToServer = false;

  constructor() {
    firebase.initializeApp(firebaseConfig);
    this.messaging = firebase.messaging();
  }

  requestPermission(): void {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        this.getRegistrationToken();
      } else {
        console.log('Unable to get permission to notify.');
      }
    });
  }

  getRegistrationToken(): void {
    this.messaging.getToken({
      vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY,
    }).then((currentToken) => {
      if (currentToken) {
        this.sendTokenToServer(currentToken);
        console.log(currentToken);
        // updateUIForPushEnabled(currentToken);
        this.messaging.onMessage((payload) => {
          console.log('New Message.... ', payload);
        });
      } else {
        // Show permission request.
        console.log('No registration token available. Request permission to generate one.');
        this.setTokenSendToServer(false);
        // Show permission UI.
        // updateUIForPushPermissionRequired();
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      this.setTokenSendToServer(false);
      // showToken('Error retrieving registration token. ', err);
    });
  }

  sendTokenToServer(currentToken: string) {
    if (!this.isTokenSendToServer) {
      // TODO: Send currentToken to backend (future task)
      console.log(currentToken);
      this.setTokenSendToServer(true);
    } else {
      console.log('Token already send to server');
    }
  }

  setTokenSendToServer(isSend: boolean): void {
    this.isTokenSendToServer = isSend;
  }
}

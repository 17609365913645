
import Vue, { PropType } from 'vue';
import { BButton } from 'bootstrap-vue';
import VueLottiePlayer from 'vue-lottie-player';
import InlineSvg from 'vue-inline-svg';

export enum ButtonVariant {
  PRIMARY = 'tg-primary',
  SECONDARY = 'tg-secondary',
  SECONDARY_PLAIN = 'tg-secondary-plain',
  LINK = 'tg-link',
}

export enum ButtonSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  BIG = 'BIG',
}

export default Vue.extend({
  name: 'AtomButton',
  components: {
    BButton,
    InlineSvg,
    VueLottiePlayer,
  },
  props: {
    variant: {
      type: String as PropType<ButtonVariant>,
      validator: (val: ButtonVariant) => Object.values(ButtonVariant).includes(val),
      default: ButtonVariant.PRIMARY,
    },
    size: {
      type: String as PropType<ButtonSize>,
      validator: (val: ButtonSize) => Object.values(ButtonSize).includes(val),
      default: ButtonSize.SMALL,
    },
    content: {
      type: String,
      required: false,
    },
    prefix: {
      type: String,
      required: false,
    },
    suffix: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ButtonVariant,
    };
  },
  computed: {
    getIconSize(): number {
      switch (this.size) {
        case ButtonSize.MEDIUM:
          return 24;
        default:
          return 20;
      }
    },
  },
});

import moment from 'moment';
import RideDetailsType from '@/enums/rides/RideDetailsTypeEnum';
import RolesEnum from '@/enums/rides/RolesEnum';
import EventTypeEnum from '@/enums/rides/EventsTypeEnum';
import TransportModeEnum from '@/enums/rides/TransportModeEnum';
import { CoordinatesModel } from '../common/CoordinatesModel';
import { RideResponseModel, RideResponseSectionModel, RideResponseUserModel, RideResponseWaypointModel } from './response/RideResponse';
import { RideModel, RideSectionModel, RideUserModel, RideWaypointModel } from './RideModel';

const convertToShortLocation = ({ latitude: lat, longitude: lng }): CoordinatesModel => ({ lat, lng });

const getWayPoint = (waypoint: RideResponseWaypointModel): RideWaypointModel => ({
  label: waypoint.label,
  comment: waypoint.comment || '',
  poiName: waypoint.poiName || '',
  providesParkingPlace: waypoint.providesParkingPlace || false,
  address: waypoint.address,
  location: convertToShortLocation(waypoint.location),
  dateTime: moment(waypoint.dateTime),
  pickUpIds: waypoint.pickUpIds || [],
  dropOffIds: waypoint.dropOffIds || [],
});

const getUser = (user: RideResponseUserModel, driverId: string): RideUserModel => ({
  ...user,
  decodedImage: '',
  role: user.userId === driverId ? RolesEnum.DRIVER : RolesEnum.PASSENGER,
});

const getSections = (section: RideResponseSectionModel): RideSectionModel => ({
  ...section,
  origin: getWayPoint(section.origin),
  destination: getWayPoint(section.destination),
  stops: section.stops.map(getWayPoint),
  vehicle: {
    ...section.vehicle,
    decodedImage: '',
  },
  route: {
    ...section.route,
    decodedShape: '',
  },
});

const getEventType = (date: moment.Moment): EventTypeEnum => {
  const now = moment();

  return (date.isSameOrAfter(now, 'second')
    ? EventTypeEnum.FUTURE_MATCH
    : EventTypeEnum.PAST_MATCH);
};

const mapRideResponseToModel = (ride: RideResponseModel, userId: string): RideModel => ({
  role: userId === ride.sections[0].driverId ? RolesEnum.DRIVER : RolesEnum.PASSENGER,
  intentId: ride.intentId,
  matchId: ride.matchId,
  eventType: getEventType(moment(ride.destination.dateTime)),
  type: RideDetailsType.MATCHED_RIDE,
  origin: getWayPoint(ride.origin),
  destination: getWayPoint(ride.destination),
  sections: ride.sections.filter((s) => s.routeType === TransportModeEnum.CAR).map(getSections),
  users: ride.users.map(
    (u) => getUser(u, ride.sections.filter((s) => s.routeType === TransportModeEnum.CAR)[0].driverId),
  ),
  cost: ride.sections.filter((s) => s.routeType === TransportModeEnum.CAR)[0].cost,
  payment: ride.payment,
});

export default mapRideResponseToModel;

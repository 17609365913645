
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Route } from 'vue-router';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomCircularButton from '@/components/atoms/AtomCircularButton.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomAvatar, { AvatarSize } from '@/components/atoms/AtomAvatar.vue';
import AtomLogo from '@/components/atoms/AtomLogo.vue';
import MoleculeDropdown from '@/components/molecules/dropdown/MoleculeDropdown.vue';
import MoleculeModal from '@/components/molecules/modal/MoleculeModal.vue';
import { logout } from '@twogo/cidaas/src/lib/CidaasService';
import RouteNamesEnum from '@/router/RouteNamesEnum';
import GamificationTypeEnum from '@/enums/gamification/GamificationTypeEnum';
import isEnvReady, { Features } from '@/services/utilities/EnvFeatureService';
import { hasFeature } from '@/services/feature/FeatureService';
import AtomText from '@/components/atoms/AtomText.vue';
import { removeOptIn, setOptIn } from '@/api/gamification/gamificationApi';
import FeaturesEnum from '@/enums/settings/FeatureEnum';

export default Vue.extend({
  name: 'OrganismNavigation',
  components: {
    AtomCircularButton,
    AtomAvatar,
    AtomLogo,
    AtomSvgIcon,
    AtomText,
    AtomButton,
    MoleculeDropdown,
    MoleculeModal,
  },
  props: {
    logoSrc: {
      type: String,
    },
  },
  data() {
    return {
      AvatarSize,
      Features,
      RouteNamesEnum,
      ButtonVariant,
      navigationItems: [
        {
          icon: 'icons/icon-profile.svg',
          text: 'navigation.myProfile',
          click: () => this.$router.push({ name: RouteNamesEnum.PROFILE }),
        },
        {
          icon: 'icons/icon-car-front-1.svg',
          text: 'navigation.myCars',
          click: () => this.$router.push({ name: RouteNamesEnum.CARS }),
        },
        {
          icon: 'icons/icon-location-pin.svg',
          text: 'navigation.myLocations',
          click: () => this.$router.push({ name: RouteNamesEnum.LOCATIONS }),
        },
        {
          icon: 'icons/icon-settings.svg',
          text: 'navigation.settings',
          click: () => this.$router.push({ name: RouteNamesEnum.SETTINGS }),
        },
        {
          icon: 'icons/icon-logout.svg',
          text: 'navigation.logout',
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          click: () => this.userLogout(),
          extraClass: 'generic-dropdown-item--color',
        },
      ],
      showUserMenu: true,
      isLoading: false,
      isLoadingDecline: false,
    };
  },
  computed: {
    ...mapGetters('user', [
      'isUserLoggedIn',
      'decodedImage',
      'fullName',
      'hasMadeLotteryDecision',
      'features',
      'getAccessToken',
      'hasUserLoaded',
    ]),
  },
  methods: {
    ...mapActions('user', ['setGamificationStatus']),
    isEnvReady,
    userLogout() {
      sessionStorage.removeItem('authMiddlewareTo');
      logout(this.getAccessToken);
    },
    showLotteryNotification(): boolean {
      return (
        this.hasUserLoaded &&
        !hasFeature(this.features, FeaturesEnum.GAMIFICATIONOPTOUT_LOTTERY) &&
        !this.hasMadeLotteryDecision
      );
    },
    setShowUserMenu({ name }: Route): void {
      this.$data.showUserMenu = ![
        RouteNamesEnum.TIMETABLE_KIOSK,
        RouteNamesEnum.TIMETABLE_PORTAL,
      ].includes(name as RouteNamesEnum);
    },
    async toggleAcceptedLottery(status: boolean): Promise<void> {
      if (status) {
        this.isLoading = true;
        const { data } = await setOptIn(GamificationTypeEnum.LOTTERY);
        this.isLoading = false;
        if (data) this.setGamificationStatus(data.gamification.status);
      } else {
        this.isLoadingDecline = true;
        const { data } = await removeOptIn(GamificationTypeEnum.LOTTERY);
        this.isLoadingDecline = false;
        if (data) this.setGamificationStatus(data.gamification.status);
      }
      this.$bvModal.hide('lottery-terms-decision');
    },
  },
  watch: {
    $route: 'setShowUserMenu',
  },
});


import Vue from 'vue';

export default Vue.extend({
  name: 'AtomCheckbox',
  props: {
    id: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    infoText: {
      type: String,
      default: '',
    },
  },
  methods: {
    emitChange(event) {
      const { checked } = event.target;
      this.$emit('change', checked);
    },
  },
});

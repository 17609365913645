
import Vue from 'vue';
import { supportedLocales } from '@/services/translation/TranslationService';
import MoleculeModal, { ModalSize } from '@/components/molecules/modal/MoleculeModal.vue';
import MoleculeLanguageSelectButton from '@/components/molecules/language/MoleculeLanguageSelectButton.vue';

export interface LanguageSelector {
  locale: string;
  englishName: string;
  nameKey: string;
}

export default Vue.extend({
  name: 'OrganismLanguageSelectorModal',
  components: { MoleculeModal, MoleculeLanguageSelectButton },
  props: {
    id: {
      type: String,
      required: true,
    },
    currentLocale: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ModalSize,
    };
  },
  computed: {
    supportedLocales,
    sortedLocales(): {}[] {
      return supportedLocales().map((locale) => ({
        locale,
        englishName: this.$t(`dictionary.locales.${locale.replace('-', '_')}`, 'en').toString(),
        nameKey: `dictionary.locales.${locale.replace('-', '_')}`,
      })).sort((a, b) => {
        const nameA = { ...a }.englishName.toUpperCase();
        const nameB = { ...b }.englishName.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    },
  },
  methods: {
    emitLocale(locale: LanguageSelector) {
      this.$emit('change', locale);
      this.$bvModal.hide(this.id);
    },
  },
});

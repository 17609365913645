enum LoginError {
  NO_ERROR = 0,
  ALERT_ERROR = 1,
  WRONG_DATA = 2,
  ALERT_PROBLEMS = 3,
  WARNING_VERIFY_EMAIL = 4,
  INFO_VERIFICATION_SENT = 5,
  SUCCESS_VERIFICATION_SENT = 6,
  INFO_ALREADY_VERIFIED = 7,
  SUCCESS_PASSWORD_REST = 8,
  SUCCESS_EMAIL_VERIFY,
  SUCCESS_PASSWORD_CHANGED,
}

export default LoginError;

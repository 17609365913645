import GenericAddressModel from '@/models/geolocation/GenericAddressModel';

export const formatAddress = (option: GenericAddressModel): string => {
  const {
    street, houseNumber, postalCode, city,
  } = option.address;
  const values: string[] = [];
  if (street && houseNumber) {
    values.push(`${street} ${houseNumber}`);
  } else if (street) {
    values.push(`${street}`);
  }
  if (postalCode && city) {
    values.push(`${postalCode} ${city}`);
  } else if (city) {
    values.push(`${city}`);
  }

  return values.join(', ');
};

export const getSequence = (sequence: number) => {
  const sequencesString = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return sequence <= sequencesString.length ? sequencesString.substr(sequence, 1) : '';
};

export default formatAddress;

import brandingDev from '@/../config/branding.dev.json';
import brandingStaging from '@/../config/branding.staging.json';
import brandingProd from '@/../config/branding.prod.json';
import { BRANDING_DEFAULT_COMPANY_ID, ENVIRONMENT } from '../../common/config';

interface BrandingCompany {
  id: string;
  logo: string;
  lightColors: {
    accent100: string;
    accent80: string;
    accent60: string;
    accent40: string;
    accent20: string;
    accent10: string;
    accentGradientStart: string;
    accentGradientEnd: string;
    graphicGradientStart: string;
    graphicGradientEnd: string;
  };
}

const getBrandingJSON = (environment: string | undefined) => {
  switch (environment) {
    case 'prod': return brandingProd;
    case 'staging': return brandingStaging;
    default: return brandingDev;
  }
};

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : null;
};

export const getBrandingById = (id: string): BrandingCompany | undefined => {
  const branding = getBrandingJSON(ENVIRONMENT);
  const filteredCompany = branding.companies.find((company) => company.id === id);
  return filteredCompany;
};

export const injectBrandingIntoDocument = (company: BrandingCompany) => {
  document.documentElement.style.setProperty('--tgPrimaryColor', company.lightColors.accent100);
  document.documentElement.style.setProperty('--tgPrimaryColorRGB', hexToRgb(company.lightColors.accent100));
  document.documentElement.style.setProperty('--tgPrimaryColor80', company.lightColors.accent80);
  document.documentElement.style.setProperty('--tgPrimaryColor60', company.lightColors.accent60);
  document.documentElement.style.setProperty('--tgPrimaryColor40', company.lightColors.accent40);
  document.documentElement.style.setProperty('--tgPrimaryColor20', company.lightColors.accent20);
  document.documentElement.style.setProperty('--tgPrimaryColor10', company.lightColors.accent10);
  document.documentElement.style.setProperty('--tgPrimaryGradient', `linear-gradient(99deg, ${company.lightColors.accentGradientStart} 0%, ${company.lightColors.accentGradientEnd} 100%)`);
  document.documentElement.style.setProperty('--tgGraphicGradient', `linear-gradient(99deg, ${company.lightColors.graphicGradientStart} 0%, ${company.lightColors.graphicGradientEnd} 100%)`);
};

export const getBrandedCompanyLogo = (company: BrandingCompany) => `data:image/png;base64, ${company.logo}`;

// eslint-disable-next-line import/no-cycle
import { setAcceptedLanguage } from '@/services/http/HTTPService';
import { Route } from 'vue-router';
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from '@/common/config';
import i18n from '@/i18n';
import type Messages from '@/../config/locales/messages.json';

const loadLocaleFile = async (locale: string): Promise<typeof Messages> =>
  import(`@/../config/locales/messages_${locale.replace('-', '_')}.json`);

const defaultLocale = (): string => DEFAULT_LOCALE;

const currentLocale = (): string => i18n.locale;

const setCurrentLocale = (locale: string): void => {
  i18n.locale = locale;
};

const getUserLocale = () => {
  const locale = window.navigator.language || defaultLocale();

  return {
    locale,
    localeNoISO: locale.split('-')[0],
  };
};

const setI18nLocaleInServices = (locale: string) => {
  setCurrentLocale(locale);
  setAcceptedLanguage(locale);

  const html = document.querySelector('html');
  if (html) html.setAttribute('lang', locale);

  return locale;
};

export const supportedLocales = (): string[] => SUPPORTED_LOCALES;

export const isLocaleSupported = (locale: string) => supportedLocales().includes(locale);

export const getUserSupportedLocale = (): string => {
  const userPreferredLocale = getUserLocale();

  if (isLocaleSupported(userPreferredLocale.locale)) {
    return userPreferredLocale.locale;
  }
  if (isLocaleSupported(userPreferredLocale.localeNoISO)) {
    return userPreferredLocale.localeNoISO;
  }

  return defaultLocale();
};

export const changeLocale = async (locale: string) => {
  if (!isLocaleSupported(locale)) return Promise.reject(new Error('Locale not supported'));
  if (i18n.locale === locale) return Promise.resolve(locale);

  return loadLocaleFile(locale).then((translations: typeof Messages) => {
    i18n.setLocaleMessage(locale, translations);

    return setI18nLocaleInServices(locale);
  });
};

const i18nRoute = (to: Route) => ({
  ...to,
  params: {
    locale: currentLocale(),
    ...to.params,
  },
});

export default i18nRoute;

import {
  Location,
  OtherDetails,
  SelectedCar,
  RideIntentState as State,
  Step,
} from '@/store/modules/ride-intent/state';

import CoordinatesModel from '@twogo/geolocation/src/lib/models/CoordinatesModel';
import { GetterTree } from 'vuex';
import { MAX_NR_OF_WAYPOINTS } from '@/common/config';
import RolesEnum from '@/enums/rides/RolesEnum';
import StepStatusesEnum from '@/enums/stepper/StepperEnum';
import SwitchEnum from '@/enums/switch/SwitchEnum';
import CarTypeEnum from '@/enums/car/CarTypeEnum';
import moment from 'moment';

export type Getters = {
  getCurrentStep(_: State, g): Step;
  isEditMode(state: State): boolean;
  isPoolCarRequest(state: State): boolean;
  isRecurrenceTrip(state: State): boolean;
  isOutwardInThePast(state: State): boolean;
  getActiveSteps(state: State): Step[];
  getCurrentStepIndex(_: State, g): number;
  getNext(_: State, g): string;
  getPrevious(_: State, g): string;
  getSelectedRole(state: State): RolesEnum | null;
  getReturnTripStatus(state: State): SwitchEnum;
  getStartCoordinates(state: State): CoordinatesModel | null;
  getEndCoordinates(state: State): CoordinatesModel | null;
  getStartPoint(state: State): Location | null;
  getEndPoint(state: State): Location | null;
  getWaypoints(state: State): (Location | null)[];
  maxNrOfWaypointsReached(state: State): boolean;
  maxNrOfReturnWaypointsReached(state: State): boolean;
  getSelectedCar(state: State): SelectedCar | null;
  isPoolCar(state: State): boolean;
  getOtherDetails(state: State): OtherDetails;
  getOrganizationId(state: State): string;
  getDetour(state: State): number;
  getEarliestDeparture(state: State): moment.Moment;
  getLatestDeparture(state: State): moment.Moment;
  getEarliestInboundDeparture(state: State): moment.Moment;
  getLatestInboundDeparture(state: State): moment.Moment;
  getMeetingPoint(state: State): string;
  getReturnMeetingPoint(state: State): string;
  getErrorCode(state: State): string;
}

const getters: GetterTree<State | any, State> & Getters = {
  getActiveSteps: (state: State) => state.steps.filter(
    (step) => step.status !== StepStatusesEnum.INACTIVE,
  ),
  getCurrentStepIndex(_, g) {
    const currentStep = g.getCurrentStep;
    const activeSteps = g.getActiveSteps;

    return activeSteps.indexOf(currentStep);
  },
  getCurrentStep: (_, g) => {
    const activeSteps = g.getActiveSteps;

    return activeSteps.find((step: Step) => step.status === StepStatusesEnum.CURRENT);
  },
  getNext: (_, g) => {
    const activeSteps = g.getActiveSteps as Step[];
    const currentIndex = g.getCurrentStepIndex as number;
    const nextIndex = currentIndex > activeSteps.length - 1 ? currentIndex : currentIndex + 1;

    return activeSteps[nextIndex].id;
  },
  getPrevious: (_, g) => {
    const activeSteps = g.getActiveSteps as Step[];
    const currentIndex = g.getCurrentStepIndex as number;
    const prevIndex = currentIndex <= 0 ? currentIndex : currentIndex - 1;

    return activeSteps[prevIndex].id;
  },
  // Role selection
  isEditMode: (state) => !!state.outboundTrip.id || !!state.outboundTrip.recurrenceId,
  isRecurrenceTrip: (state) => !!state.outboundTrip.recurrenceId,
  isSequenceTrip: (state) => !!state.outboundTrip.sequenceId,
  isPoolCarRequest: (state) => state.poolCarRequest,
  isOutwardInThePast: (state) => state.outboundTrip.latestArrival.isBefore(moment(), 'minute'),
  isPoolCar: (state) => state.selectedCar?.type === CarTypeEnum.POOL_CAR,
  getSelectedRole: (state) => state.role,
  getReturnTripStatus: (state) => {
    const isDisabled = (
      state.steps[4].status === StepStatusesEnum.INACTIVE
    );

    return isDisabled ? SwitchEnum.OFF : SwitchEnum.ON;
  },
  getStartCoordinates: (state) => {
    const { startPoint: { location } } = state.outboundTrip;
    return location ? location.position : null;
  },
  getEndCoordinates: (state) => {
    const { endPoint: { location } } = state.outboundTrip;
    return location ? location.position : null;
  },
  getStartPoint: (state) => state.outboundTrip.startPoint,
  getEndPoint: (state) => state.outboundTrip.endPoint,
  getWaypoints: (state) => state.outboundTrip.waypoints,
  getReturnStartCoordinates: (state) => {
    const { startPoint: { location } } = state.returnTrip;
    return location ? location.position : null;
  },
  getReturnEndCoordinates: (state) => {
    const { endPoint: { location } } = state.returnTrip;
    return location ? location.position : null;
  },
  getReturnStartPoint: (state) => state.returnTrip.startPoint,
  getReturnEndPoint: (state) => state.returnTrip.endPoint,
  getReturnWaypoints: (state) => state.returnTrip.waypoints,
  maxNrOfWaypointsReached: (state) => state.outboundTrip.waypoints.length >= MAX_NR_OF_WAYPOINTS,
  maxNrOfReturnWaypointsReached: (state) =>
    state.returnTrip.waypoints.length >= MAX_NR_OF_WAYPOINTS,
  getSelectedCar: (state) => state.selectedCar,
  getOtherDetails: (state) => state.other,
  getOrganizationId: (state) => state.other.organizationId,
  getDetour: (state) => state.other.detour,
  getEarliestDeparture: (state) => state.outboundTrip.earliestDeparture,
  getLatestDeparture: (state) => state.outboundTrip.latestArrival,
  getEarliestInboundDeparture: (state) => state.returnTrip.earliestDeparture,
  getLatestInboundDeparture: (state) => state.returnTrip.latestArrival,
  getMeetingPoint: (state) => state.outboundTrip.meetingPoint,
  getReturnMeetingPoint: (state) => state.returnTrip.meetingPoint,
  getErrorCode: (state) => state.error,
};

export default getters;

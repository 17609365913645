
import Vue, { PropType } from 'vue';
import { BModal, BvModalEvent } from 'bootstrap-vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';

export enum ModalSize {
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  XLARGE = 'xl',
}

export default Vue.extend({
  name: 'MoleculeModal',
  components: {
    BModal,
    AtomSvgIcon,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    className: {
      type: String,
      required: false,
    },
    size: {
      type: String as PropType<ModalSize>,
      validator: (val: ModalSize) => Object.values(ModalSize).includes(val),
      default: ModalSize.MEDIUM,
    },
    sticky: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false,
    },
    isOverlayOpened: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleHide(event: BvModalEvent) {
      if (this.sticky) {
        event.preventDefault();
      }
      this.$emit('hide', event);
    },
  },
});

import { getUserSupportedLocale } from '@/services/translation/TranslationService';
import isMobileDevice from '@/services/utilities/deviceDetection';
import { NavigationGuardNext, Route } from 'vue-router';
import RouteNamesEnum from '../RouteNamesEnum';

const visibleOnMobile = ['LoginRegistration', 'MobileDevice', RouteNamesEnum.LOTTERY_TOP];

export const detectDevice = async (to: Route, _from: Route, next: NavigationGuardNext) => {
  const { name } = to;
  if (
    isMobileDevice()
    && !visibleOnMobile.includes(name || '')
  ) {
    const path = `/${getUserSupportedLocale()}/mobileDevice`;
    return next({ path });
  }

  return next();
};

export default detectDevice;

/* eslint-disable import/prefer-default-export */
import axios, { AxiosRequestConfig } from 'axios';
import i18n from '@/i18n';

const getToken = () => localStorage.getItem('access_token');

const setHeader = (config: AxiosRequestConfig) => {
  const { headers } = config;
  const AUTH_TOKEN = getToken();

  const h = {
    'Content-Type': 'application/json;charset=utf-8',
    Authorization: `Bearer ${AUTH_TOKEN}`,
  };

  return { ...config, headers: { ...headers, ...h } };
};

export const http = axios.create();
export const setAcceptedLanguage = (locale: string) => {
  http.defaults.headers.common['Accept-Language'] = locale;
};

http.interceptors.response.use((response) => response, (error) => {
  if (error.response && error.response.status === 401) {
    window.localStorage.setItem('locale', i18n.locale);
    window.localStorage.removeItem('access_token');
    window.location.reload();
  }
  return Promise.reject(error);
});

http.interceptors.request.use(
  setHeader,
  (error) => Promise.reject(error),
);

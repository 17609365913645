import moment from 'moment';
import LeaderBoardModel from './LeaderBoardModel';
import { LeaderBoardResponseModel } from './response/LeaderBoardResponse';

const mapLeaderBoardResponseToModel = (leaderBoard: LeaderBoardResponseModel): LeaderBoardModel => ({
  ...leaderBoard,
  memberSince: moment(leaderBoard.memberSince),
});

export default mapLeaderBoardResponseToModel;

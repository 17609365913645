import {
  CidaasUserData,
  UserState as State,
} from '@/store/modules/user/state';
import { Mutations } from '@/store/modules/user/mutations';
import { ActionContext as DefaultActionContext, ActionTree } from 'vuex';
import { fetchImage, getUserById } from '@/api/user/userApi';
import CommunityUserModel from '@/models/community/CommunityUserModel';
import { RideUserModel } from '@/models/ride/RideModel';
import { getBlockedUsers } from '@/api/community/communityApi';
import BlockedUserModel from '@/models/community/BlockedUserModel';
import UserModel from '@/models/user/UserModel';

type ActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1]
  ): ReturnType<Actions[K]>;
} & Omit<DefaultActionContext<State, State>, 'commit | dispatch'>

export interface Actions {
  fetchUser({ commit }: ActionContext, id: string): void;
  setAccessToken({ commit }: ActionContext, payload: string): void;
  setAccessCode({ commit }: ActionContext, payload: string): void;
  setCidaasUserData({ commit }: ActionContext, payload: CidaasUserData): void;
  updateUser({ commit }: ActionContext, payload: UserModel): void;
  setUserDecodedImage({ commit }: ActionContext, payload: string): void;
  setFollowingMembers({ commit }: ActionContext, payload: CommunityUserModel[]): void;
  removeFollowerById({ commit }: ActionContext, payload: string): void;
  fetchBlockedUsers({ commit }: ActionContext, id: string): void;
  deleteBlockedUser({ commit }: ActionContext, payload: string): void;
  addBlockedUser({ commit }: ActionContext, payload: BlockedUserModel): void;
  setPayment({ commit }: ActionContext, payload: UserModel['payment']): void;
  setGamificationStatus({ commit }: ActionContext, payload: UserModel['gamification']['status']): void;
  setAvatars({ commit }: ActionContext, payload: State['avatars']): void;
}

const actions: ActionTree<State, State> & Actions = {
  addBlockedUser({ commit }, payload: BlockedUserModel) {
    commit('addBlockedUser', payload);
  },
  async fetchUser({ commit, dispatch }, id) {
    const { status, data } = await getUserById(id);
    if (status === 200 && data) {
      commit('setUser', data);
      commit('cacheCommunityUsersAsOtherUsers', data.follower.members);
      commit('cacheCommunityUsersAsOtherUsers', data.following.members);
      const imageSource = await fetchImage(data.imageURL);
      commit('setUserDecodedImage', imageSource);

      dispatch('fetchCommunityImages');
      return { status };
    }
    return { status: 400 };
  },
  setAvatars({ commit }, payload) {
    commit('setAvatars', payload);
  },
  setPayment({ commit }, payload) {
    commit('setPayment', payload);
  },
  setGamificationStatus({ commit }, payload) {
    commit('setGamificationStatus', payload);
  },
  setAccessToken({ commit }, payload: string) {
    commit('setAccessToken', payload);
  },
  setAccessCode({ commit }, payload: string) {
    commit('setAccessCode', payload);
  },
  setCidaasUserData({ commit }, payload: CidaasUserData) {
    commit('setCidaasUserData', payload);
  },
  updateUser({ commit }, payload: UserModel) {
    commit('setUser', payload);
  },
  setUserSite({ commit }, payload: UserModel['site']) {
    commit('setUserSite', payload);
  },
  setUserDecodedImage({ commit }, payload?) {
    commit('setUserDecodedImage', payload || '');
  },
  setFollowingMembers({ commit, dispatch }, payload) {
    commit('setFollowingMembers', payload);
    commit('cacheCommunityUsersAsOtherUsers', payload);
    dispatch('fetchCommunityImages');
  },
  removeFollowerById({ commit }, payload) {
    commit('removeFollowerById', payload);
  },
  async fetchUsersImages({ commit, getters }) {
    const users = getters.getOtherUsers as RideUserModel[];
    users.forEach(async (user) => {
      if (!user.decodedImage) {
        const image = await fetchImage(user.imageURL);
        commit('setOtherUserImage', { user, image });
      }
    });
  },
  async fetchCommunityImages({ commit, getters }) {
    const followers = getters.followers as CommunityUserModel[];
    const following = getters.following as CommunityUserModel[];
    followers.forEach(async (user) => {
      if (!user.decodedImage) {
        const image = await fetchImage(user.imageURL);
        commit('setCommunityImages', { user, image, comType: 'follower' });
      }
    });
    following.forEach(async (user) => {
      if (!user.decodedImage) {
        const image = await fetchImage(user.imageURL);
        commit('setCommunityImages', { user, image, comType: 'following' });
      }
    });
  },
  async fetchBlockedUsers({ commit }, id) {
    const blockedUsers = await getBlockedUsers(id);
    commit('setBlockedUser', blockedUsers.data);
  },
  async deleteBlockedUser({ commit }, blacklistEntryId) {
    commit('removeBlockedUser', blacklistEntryId);
  },
  resetState({ commit }) {
    commit('resetState');
  },
};

export default actions;

import { ActionContext as DefaultActionContext, ActionTree } from 'vuex';
import { RidesState as State } from '@/store/modules/rides/state';
import { Mutations } from '@/store/modules/rides/mutations';
import { getIntents } from '@/api/ride-intent/rideIntentApi';
import { getRides } from '@/api/ride-intent/ridesApi';
import { RideModel, RideUserModel } from '@/models/ride/RideModel';
import { getUnratedTrips } from '@/api/ratings/ratingsApi';
import { fetchCommunityIntents } from '@/api/community/communityApi';

type ActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
  ): ReturnType<Actions[K]>;
} & Omit<DefaultActionContext<State, State>, 'commit | dispatch'>;

export interface Actions {
  fetchIntents({ commit }: ActionContext, userId: string): Promise<{ status: number }>;
  fetchCommunityIntents({ commit }: ActionContext, userId: string): Promise<{ status: number }>;
  fetchRides({ commit }: ActionContext, userId: string): Promise<{ status: number }>;
  fetchOtherUsers({ commit }: ActionContext, data: RideModel[]): Promise<void>;
  fetchUnratedRides({ commit }: ActionContext, userId: string): Promise<void>;
}

const actions: ActionTree<State, State> & Actions = {
  async fetchIntents({ commit }, userId) {
    const { data, status } = await getIntents(userId);
    commit('setIntents', data);
    return { status };
  },
  async fetchCommunityIntents({ commit }, communityId) {
    const { data, status } = await fetchCommunityIntents(communityId);
    commit('setCommunityIntents', data);
    return { status };
  },
  async fetchRides({ dispatch, commit }, userId) {
    const { data, status } = await getRides(userId);
    commit('setRides', data);
    dispatch('fetchOtherUsers', data);
    dispatch('user/fetchUsersImages', [], { root: true });
    return { status };
  },
  async fetchOtherUsers({ commit }, data) {
    let rideUsers = [] as RideUserModel[];
    data.forEach((ride) => {
      rideUsers = [...rideUsers, ...ride.users];
    });
    commit('user/setOtherUsers', rideUsers, { root: true });
  },
  async fetchUnratedRides({ commit }, userId) {
    const { data, status } = await getUnratedTrips(userId);

    if (status === 200) commit('setUnratedRides', data);
  },
  rateUser({ dispatch }, payload) {
    dispatch('removeRatedUser', payload);
  },
  skipUserRating({ dispatch }, payload) {
    dispatch('removeRatedUser', payload);
  },
  removeRatedUser({ commit }, payload) {
    commit('removeRatedUser', payload);
  },
};

export default actions;

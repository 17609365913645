import {
  Car,
  CarState as State,
} from '@/store/modules/car/state';
import { Mutations } from '@/store/modules/car/mutations';
import { ActionContext as DefaultActionContext, ActionTree } from 'vuex';
import { deleteCarById, getAllCars } from '@/api/car/carApi';

type ActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1]
  ): ReturnType<Actions[K]>;
} & Omit<DefaultActionContext<State, State>, 'commit | dispatch'>

export interface Actions {
  addCars({ commit }: ActionContext, payload: Car[]): void;
  addCar({ commit }: ActionContext, payload: Car): void;
  updateCar({ commit }: ActionContext, payload: Car): void;
  removeCar({ commit }: ActionContext, payload: string): void;
  removeAllCars({ commit }: ActionContext): void;
  setAvailableSeats({ commit }: ActionContext, payload: { id: string; value: number }): void;
  deleteCarById ({ commit }: ActionContext, payload: string): void;
}

const actions: ActionTree<State, State> & Actions = {
  addCars({ commit }, payload: Car[]) {
    commit('addCars', payload);
  },
  addCar({ commit }, payload: Car) {
    commit('addCar', payload);
  },
  updateCar({ commit }, payload: Car) {
    commit('updateCar', payload);
  },
  removeCar({ commit }: ActionContext, payload: string) {
    commit('removeCar', payload);
  },
  removeAllCars({ commit }: ActionContext) {
    commit('removeAllCars');
  },
  setAvailableSeats({ commit }: ActionContext, payload) {
    commit('setAvailableSeats', payload);
  },
  async fetchAllCars({ commit }: ActionContext, payload: string) {
    const { status, data } = await getAllCars(payload);

    if (status === 200) {
      commit('addCars', data || []);
      return Promise.resolve({ data });
    }
    return Promise.reject(Error('Fetch Cars failed.'));
  },
  async deleteCarById({ commit }, id) {
    const { status } = await deleteCarById(id);
    if (status === 200) {
      // commit('deleteCarById', id);

      return Promise.resolve({ status });
    }

    return Promise.reject(Error('Delete Car failed.'));
  },
};

export default actions;

import { MutationTree } from 'vuex';
import { RidesState as State } from '@/store/modules/rides/state';
import RideIntentModel from '@/models/ride-intent/RideIntentModel';
import { RideModel } from '@/models/ride/RideModel';
import UnratedTripModel from '@/models/ratings/UnratedTripModel';

export type Mutations<S = State> = {
  setIntents(state: S, payload: RideIntentModel[]): void;
  setCommunityIntents(state: S, payload: RideIntentModel[]): void;
  setRides(state: S, payload: RideModel[]): void;
  setUnratedRides(state: S, payload: UnratedTripModel[]): void;
};

const mutations: MutationTree<State> & Mutations = {
  setIntents(state, payload) {
    state.intents = payload;
  },
  setCommunityIntents(state, payload) {
    state.communityIntents = payload;
  },
  setRides(state, payload) {
    state.rides = payload;
  },
  setUnratedRides(state, payload) {
    state.unratedRides = payload;
  },
  removeRatedUser(state, payload) {
    state.unratedRides = state.unratedRides.filter(
      (element) => !(element.matchId === payload.matchId && element.userId === payload.userId),
    );
  },
};

export default mutations;

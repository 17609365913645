import CommunityUserModel from './CommunityUserModel';
import { CommunityUserResponseModel } from './response/CommunityUserResponseModel';

const mapCommunityUserResponseToModel = (c: CommunityUserResponseModel): CommunityUserModel => ({
  blocked: c.blocked || false,
  company: c.company ? {
    companyId: c.company.companyId,
    logo2URL: c.company.logo2URL,
    logoURL: c.company.logoURL,
    name: c.company.name,
  } : null,
  email: c.email,
  firstName: c.firstName,
  imageURL: c.imageURL,
  decodedImage: '',
  lastName: c.lastName,
  name: c.name,
  phoneNumber: c.phoneNumber,
  userId: c.userId,
});

export default mapCommunityUserResponseToModel;

import { GetterTree } from 'vuex';
import {
  PoiState as State,
} from '@/store/modules/poi/state';
import { PoiModel } from '@/models/geolocation/poi/PoiModel';
import DefaultPoiEnum from '@/enums/geolocation/DefaultPoiEnum';

export type Getters = {
  getPoi(state: State): PoiModel[];
  getHomePoi(state: State): PoiModel|undefined;
  getWorkPoi(state: State): PoiModel|undefined;
  getPoiByName(state: State): (name: string) => PoiModel|undefined;
  getPoiById(state: State): (name: string) => PoiModel|undefined;
  isPoiNameInUse(state: State): (name: string) => boolean;
  isPoiNameReserved(): (name: string) => boolean;
}

const getters: GetterTree<State, State> & Getters = {
  getPoi: (state) => state.poi,
  getHomePoi: (state) => state.poi.find((p) => p.name === DefaultPoiEnum.HOME),
  getWorkPoi: (state) => state.poi.find((p) => p.name === DefaultPoiEnum.WORK),
  getPoiByName: (state) => (name: string) => state.poi.find((p) => p.name === name),
  getPoiById: (state) => (id: string) => state.poi.find((p) => p.id === id),
  isPoiNameInUse: (state) => (name: string) => state.poi.some((p) => p.name.toUpperCase() === name.toUpperCase()),
  isPoiNameReserved: () => (name: string) =>
    [DefaultPoiEnum.HOME, DefaultPoiEnum.WORK].includes(name.toUpperCase() as DefaultPoiEnum),
};

export default getters;

import { GetterTree } from 'vuex';
import { SitesState, TimetableState as State } from '@/store/modules/timetable/state';
import { TimetableModel } from '@/models/timetable/TimetableModel';

const matchedRides = (ride: TimetableModel) => (
  (ride.role === 'Passenger' && !ride.isMatched)
  || (ride.role === 'Driver' && ride.freeSeats > 0)
  || (ride.role === 'DriverOrPassenger' && (!ride.isMatched || ride.freeSeats > 0))
);

export type Getters = {
  getSiteName(state: State): string;
  getSiteAddress(state: State): string;
  getArrivals(state: State): TimetableModel[];
  getDepartures(state: State): TimetableModel[];
  getSites(state: State): SitesState[];
};

const getters: GetterTree<State, State> & Getters = {
  getSiteName: (state) => state.data.location.name,
  getSiteAddress: (state) => state.data.location.address,
  getArrivals: (state) => state.data.arrivals.filter(matchedRides),
  getDepartures: (state) => state.data.departures.filter(matchedRides),
  getSites: (state) => state.data.location.sites,
};

export default getters;

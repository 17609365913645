import { NavigationGuardNext, Route } from 'vue-router';
import isEnvReady from '@/services/utilities/EnvFeatureService';

export const enablingMiddleware = async (to: Route, _from: Route, next: NavigationGuardNext) => {
  const { meta } = to;

  if ((meta && meta.feature) && !isEnvReady(meta.feature)) {
    return next({ name: 'Home' });
  }
  return next();
};

export default enablingMiddleware;

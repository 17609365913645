import RolesEnum from '@/enums/rides/RolesEnum';
import moment from 'moment';
import { TimetableResponseModel } from './response/TimetableResponseModel';
import { TimetableModel } from './TimetableModel';

const mapTimetableResponseToModel = (data: TimetableResponseModel): TimetableModel => ({
  createUrl: data.createUrl,
  date: moment(data.date),
  freeSeats: data.freeSeats,
  id: data.id,
  isBusinessTrip: data.isBusinessTrip,
  isMatched: data.isMatched,
  location: {
    zip: data.location.zip,
    city: data.location.city,
    district: data.location.district || '',
  },
  role: data.role as RolesEnum,
});

export default mapTimetableResponseToModel;

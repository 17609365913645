import { MutationTree } from 'vuex';
import DefaultState, {
  CidaasUserData,
  UserState as State,
} from '@/store/modules/user/state';
import { RideUserModel } from '@/models/ride/RideModel';
import CommunityUserModel from '@/models/community/CommunityUserModel';
import GenderEnum from '@/enums/settings/GenderEnum';
import RolesEnum from '@/enums/rides/RolesEnum';
import BlockedUserModel from '@/models/community/BlockedUserModel';
import UserModel from '@/models/user/UserModel';

export type Mutations<S = State> = {
  addBlockedUser(state: S, payload: BlockedUserModel): void;
  setAccessToken(state: S, payload: string): void;
  setAccessCode(state: S, payload: string): void;
  setBlockedUser(state: S, payload: BlockedUserModel[]): void;
  setCidaasUserData(state: S, payload: CidaasUserData): void;
  setUser(state: S, payload: UserModel): void;
  setUserDecodedImage(state: S, payload: string): void;
  setOtherUsers(state: S, payload: RideUserModel[]): void;
  setFollowingMembers(state: S, payload: CommunityUserModel[]): void;
  cacheCommunityUsersAsOtherUsers(state: S, payload: CommunityUserModel[]): void;
  removeFollowerById(state: S, payload: string): void;
  removeBlockedUser(state: S, payload: string): void;
  setPayment(state: S, payload: UserModel['payment']): void;
  setGamificationStatus(state: S, payload: UserModel['gamification']['status']): void;
  setUserSite(state: S, payload: UserModel['site']): void;
  setAvatars(state: S, payload: State['avatars']): void;
}

const mutations: MutationTree<State> & Mutations = {
  addBlockedUser(state, blockedUser: BlockedUserModel) {
    if (!state.blockedUsers.some((b) => b.id === blockedUser.id)) {
      state.blockedUsers.push(blockedUser);
    }
  },
  setUser(state, user: UserModel) {
    state.user = user;
  },
  setPayment(state, payment) {
    if (state.user) {
      state.user.payment = payment;
    }
  },
  setAvatars(state, payload) {
    state.avatars = { ...state.avatars, ...payload };
  },
  setGamificationStatus(state, payment) {
    if (state.user) {
      state.user.gamification.status = payment;
    }
  },
  setAccessToken(state, payload: string) {
    state.accessToken = payload;
  },
  setAccessCode(state, payload: string) {
    state.accessCode = payload;
  },
  setBlockedUser(state, payload) {
    state.blockedUsers = payload;
  },
  setCidaasUserData(state, payload: CidaasUserData) {
    state.cidaasUserData = {
      ...payload,
      customFields: {
        twogoRegistrationCompleted: { state: payload.customFields?.twogoRegistrationCompleted === 'true' },
        registrationToken: payload.customFields.registrationToken || '',
      },
    };
  },
  setUserDecodedImage(state, payload) {
    if (state.user) {
      state.user.decodedImage = payload;
    }
  },
  setOtherUsers(state, payload) {
    payload.forEach((user: RideUserModel) => {
      if (!state.otherUsers.some((u) => u.userId === user.userId)) {
        state.otherUsers.push(user);
      }
    });
  },
  async cacheCommunityUsersAsOtherUsers(state, data = []) {
    if (!state.user) return;

    const users: RideUserModel[] = data.map((comUser) => ({
      userId: comUser.userId,
      firstName: comUser.firstName,
      lastName: comUser.lastName,
      role: RolesEnum.DRIVER_AND_PASSENGER,
      email: comUser.email,
      gender: GenderEnum.UNSPECIFIED,
      phoneNumber: comUser.phoneNumber,
      imageURL: comUser.imageURL,
      decodedImage: '',
      companyLogoURL: comUser.company?.logoURL || null,
      companyName: comUser.company?.name || null,
      companyLogo2URL: comUser.company?.logo2URL || null,
      cost: { currency: '', value: 0 },
    }));

    users.forEach((user: RideUserModel) => {
      if (!state.otherUsers.some((u) => u.userId === user.userId)) {
        state.otherUsers.push(user);
      }
    });
  },
  setOtherUserImage(state, payload: { user: RideUserModel; image: string }) {
    if (!state.user) return;

    const users = state.otherUsers.filter((u) => u.userId !== payload.user.userId);
    state.otherUsers = [...users, { ...payload.user, decodedImage: payload.image }];
  },
  setCommunityImages(state, payload: { user: RideUserModel; image: string; comType: 'follower|following' }) {
    if (!state.user) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const users = state.user[payload.comType].members.filter((u) => u.userId !== payload.user.userId);
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    state.user[payload.comType].members = [...users, { ...payload.user, decodedImage: payload.image }];
  },
  setUserSite(state, payload) {
    if (state.user) state.user.site = payload;
  },
  setFollowingMembers(state, payload) {
    if (state.user) state.user.following.members = payload;
  },
  removeFollowerById(state, payload) {
    if (!state.user) return;

    const followers = state.user.following.members.filter((f) => f.userId !== payload);
    state.user.following.members = followers;
  },
  removeBlockedUser(state, payload) {
    if (!state.blockedUsers) return;

    const blockedUsers = state.blockedUsers.filter((b) => b.id !== payload);
    state.blockedUsers = blockedUsers;
  },
  resetState(state) {
    Object.assign(state, DefaultState());
  },
};

export default mutations;

/* eslint-disable @typescript-eslint/camelcase */
const CidaasConfig = {
  authority: process.env.VUE_APP_CIDAAS_AUTHORITY,
  client_id: process.env.VUE_APP_CIDAAS_CLIENT_ID,
  redirect_uri: process.env.VUE_APP_CIDAAS_REDIRECT_URI,
  post_logout_redirect_uri: process.env.VUE_APP_CIDAAS_POST_LOGOUT_REDIRECT_URI,
  popup_post_logout_redirect_uri: process.env.VUE_APP_CIDAAS_POPUP_POST_LOGOUT_REDIRECT_URI,
  silent_redirect_uri: process.env.VUE_APP_CIDAAS_SILENT_REDIRECT_URI,
  response_type: process.env.VUE_APP_CIDAAS_RESPONSE_TYPE,
  scope: process.env.VUE_APP_CIDAAS_SCOPE,
  mode: process.env.VUE_APP_CIDAAS_MODE,
  consents: process.env.VUE_APP_CIDAAS_CONSENTS?.split(','),
};

export default CidaasConfig;

import { v4 as uuidv4 } from 'uuid';
import GenericAddressModel from '@/models/geolocation/GenericAddressModel';
import { PoiModel } from '@/models/geolocation/poi/PoiModel';
import SourceEnum, { SourceSortOrder } from '@/enums/geolocation/SourceEnum';
import PoiTypesEnum from '@/enums/geolocation/PoiTypesEnum';
import QuicksearchModel from '@/models/geolocation/quicksearch/QuicksearchModel';

const getSourceType = (g: GenericAddressModel): SourceEnum => {
  // console.log(g.poiType);
  if (g.name === SourceEnum.HOME || g.name === SourceEnum.WORK) return g.name;
  if (g.poiType === PoiTypesEnum.SITE) return SourceEnum.SITE;

  return g.source;
};

const getFromQuicksearch = (
  { address, position, source, poiType, comment, name }: QuicksearchModel,
): GenericAddressModel => ({
  id: uuidv4(),
  name: poiType ? name : undefined,
  address,
  position,
  source: source === SourceEnum.POI ? SourceEnum.FAVORITES : source as SourceEnum,
  poiType: poiType as PoiTypesEnum,
  comment: comment || null,
  parkingSpaces: [],
});

const getFromPOI = (
  { id, name, address, location, poiType, comment, parkingSpaces }: PoiModel,
): GenericAddressModel => ({
  id,
  name,
  address,
  position: location,
  source: SourceEnum.FAVORITES,
  poiType,
  comment,
  parkingSpaces,
});

export const getGenericAddress = (geolocation): GenericAddressModel => {
  if (Object.prototype.hasOwnProperty.call(geolocation, 'source')) {
    if (geolocation.source === SourceEnum.FAVORITES) {
      return getFromPOI(geolocation as PoiModel);
    }
    if (geolocation.source === SourceEnum.HISTORY || geolocation.source === SourceEnum.POI) {
      return getFromQuicksearch(geolocation as QuicksearchModel);
    }
  }

  return getFromQuicksearch(geolocation as QuicksearchModel);
};

const getGenericAddresses = (geolocation: any[]): GenericAddressModel[] => geolocation
  .map((g) => getGenericAddress(g))
  .sort((prevG, currG) => SourceSortOrder[getSourceType(prevG)] - SourceSortOrder[getSourceType(currG)]);

export default getGenericAddresses;

import state from '@/store/modules/create-car/state';
import actions from '@/store/modules/create-car/actions';
import getters from '@/store/modules/create-car/getters';
import mutations from '@/store/modules/create-car/mutations';

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};


import Vue, { PropType } from 'vue';
import { Route } from 'vue-router';

export default Vue.extend({
  name: 'AtomRouterLink',
  props: {
    to: {
      type: String || (Object as PropType<string|Route>),
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
});

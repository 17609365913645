import GenderEnum from '@/enums/settings/GenderEnum';
import RolesEnum from '@/enums/rides/RolesEnum';
import UnitOfMeasurementEnum from '@/enums/settings/UnitOfMeasurementEnum';
import { PoiModel } from '@/models/geolocation/poi/PoiModel';
import { RideUserModel } from '@/models/ride/RideModel';
import UserContactModel from '@/models/user/ContactModel';
import SiteModel from '@/models/user/SiteModel';
import UserModel from '@/models/user/UserModel';
import {
  CidaasUserData,
  UserState as State,
} from '@/store/modules/user/state';
import { GetterTree } from 'vuex';
import BlockedUserModel from '@/models/community/BlockedUserModel';
import PaymentTypesEnum from '@/enums/payment/PaymentTypesEnum';
import AgreementEnum from '@/enums/AgreementEnum';

export type Getters = {
  getAccessToken(state: State): (string | null);
  getAccessCode(state: State): (string | null);
  getCidaasUserData(state: State): (CidaasUserData | null);
  isRegistrationCompleted(state: State): boolean;
  isUserLoggedIn(state: State): boolean;
  user(state: State): UserModel|null;
  // Twogo User details:
  registrationToken(state: State): string;
  logonName(state: State): string;
  firstName(state: State): string;
  lastName(state: State): string;
  displayName(state: State): string;
  email(state: State): string;
  phoneNumber(state: State): string;
  gender(state: State): string;
  homePOI(state: State): PoiModel | {};
  followers(state: State): UserModel['follower']['members'];
  following(state: State): UserModel['following']['members'];
  isPhoneVerified(state: State): boolean;
  isEmailVerified(state: State): boolean;
  hasHomePoi(state: State): boolean;
  gamification(state: State): UserModel['gamification']['points'] | {};
  unitOfLengthLocale(state: State): UnitOfMeasurementEnum | string;
  sms(state: State): UserContactModel | undefined;
  site(state: State): SiteModel | undefined;
  siteCompanyName(state: State): string;
  siteName(state: State): string;
  imageURL(state: State): string;
  decodedImage(state: State): string;
  rideSettings(state: State): UserModel['rideSettings'] | {};
  locale(state: State): string;
  features(state: State): UserModel['features'] | [];
  getRideUser(state: State): RideUserModel;
  fullName(state: State, g: Getters): string;
  getOtherUsers(state: State): RideUserModel[];
  properties(state: State): UserModel['properties'];
  getBlockedUsers(state: State): BlockedUserModel[];
  hasPaymentTypeEnabled(state: State): (type: PaymentTypesEnum) => boolean;
  getUserAvatar(state: State): (userId: string) => string;
  hasPaypalEmail(state: State): boolean;
  getPaypalEmail(state: State): string;
  hasAcceptedLeaderBoard(state: State): boolean;
}

const getters: GetterTree<State, State> & Getters = {
  getAccessToken: (state) => state.accessToken,
  getAccessCode: (state) => state.accessCode,
  getCidaasUserData: (state) => state.cidaasUserData,
  getUserId: (state) => state.cidaasUserData?.sub,
  getIdentityId: (state) => state.cidaasUserData?.lastUsedIdentityId,
  isRegistrationCompleted: (state) => state.cidaasUserData?.customFields?.twogoRegistrationCompleted.state,
  isUserLoggedIn: (state) => !!state.accessToken,
  registrationToken: (state) => state.cidaasUserData?.customFields?.registrationToken || '',
  user: (state) => state.user,
  // Twogo User details:
  hasUserLoaded(state) {
    return !!state.user;
  },
  hasPaypalEmail(state) {
    if (!state.user || !state.user.payment.paypalDonation) return false;

    return true;
  },
  hasAcceptedLeaderBoard(state) {
    return !!(state.user?.gamification.status?.leaderboard === AgreementEnum.ACCEPT);
  },
  hasAcceptedExperience(state) {
    return !!(state.user?.gamification.status?.experience === AgreementEnum.ACCEPT);
  },
  hasAcceptedLottery(state) {
    return !!(state.user?.gamification.status?.lottery === AgreementEnum.ACCEPT);
  },
  hasMadeGamingDecision(state) {
    if (!state.user?.gamification.status) return false;
    const { leaderboard, experience } = state.user.gamification.status;

    return leaderboard !== null && experience !== null;
  },
  hasMadeLotteryDecision(state) {
    if (!state.user?.gamification.status) return false;
    const { lottery } = state.user.gamification.status;

    return lottery !== null;
  },
  getPaypalEmail(state) {
    if (!state.user || !state.user.payment.paypalDonation) return '';

    return state.user.payment.paypalDonation.username;
  },
  hasPaymentTypeEnabled(state) {
    return (type: PaymentTypesEnum): boolean => {
      if (!state.user) return false;
      return state.user.payment.availablePaymentTypes.includes(type);
    };
  },
  logonName: (state) => state.user?.logonName || '',
  firstName: (state) => state.user?.firstName || '',
  lastName: (state) => state.user?.lastName || '',
  displayName: (state) => state.user?.displayName || '',
  email: (state) => state.user?.email.detail || '',
  gender: (state) => state.user?.gender || '',
  phoneNumber: (state) => state.user?.sms?.detail || '',
  homePOI: (state) => state.user?.homePOI || {},
  followers: (state) => state.user?.follower.members || [],
  following: (state) => state.user?.following.members || [],
  followingIntents: (state) => state.user?.following.intents || [],
  communityId: (state) => state.user?.following.communityId || '',
  hasPhoneNumber: (state) => !!state.user?.sms?.detail,
  isPhoneVerified: (state) => state.user?.sms !== null && !!state.user?.sms.verified,
  isEmailVerified: ({ user }) => !!user?.email.verified,
  hasHomePoi: ({ user }) => !!user?.homePOI,
  gamification: ({ user }) => user?.gamification.points || {},
  unitOfLengthLocale: (state) => state.user?.unitOfLengthLocale || '',
  sms: (state) => state.user?.sms,
  site: (state) => state.user?.site,
  siteCompanyName: (state) => state.user?.site.companyName || '',
  siteName: (state) => state.user?.site.name || '',
  imageURL: (state) => state.user?.imageURL || '',
  decodedImage: (state) => state.user?.decodedImage || '',
  rideSettings: (state) => state.user?.rideSettings || {},
  locale: (state) => state.user?.locale || '',
  features: (state) => state.user?.features || [],
  getRideUser: (state) => ({
    role: RolesEnum.DRIVER,
    userId: state.cidaasUserData?.sub || '',
    firstName: state.user?.firstName || '',
    lastName: state.user?.lastName || '',
    email: state.user?.email.detail || '',
    gender: state.user?.gender as GenderEnum || GenderEnum.UNSPECIFIED,
    phoneNumber: state.user?.sms?.detail || '',
    imageURL: state.user?.imageURL || '',
    decodedImage: state.user?.decodedImage || '',
    companyName: state.user?.site.companyName || '',
    companyLogoURL: null,
    companyLogo2URL: null,
    cost: {
      value: 0,
      currency: '',
    },
  }),
  fullName: (state, g) => `${g.firstName} ${g.lastName}`,
  availableMatchingGroups: (state) => state.user?.availableMatchingGroups || {},
  b2cFree: (state) => state.user?.b2cFree || false,
  getOtherUsers: (state) => state.otherUsers,
  getOtherUserById: (state) => (id: string) => state.otherUsers.find((u) => u.userId === id),
  getBlockedUsers: (state) => state.blockedUsers || [],
  properties: (state) => state.user?.properties || {},
  dateLocale: (state) => state.user?.dateLocale,
  timeLocale: (state) => state.user?.timeLocale,
  getUserAvatar: (state) => (userId) => state.avatars[userId] || '',
};

export default getters;

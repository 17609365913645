
import Vue from 'vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import OrganismLogin from '@/components/organisms/login-registration/OrganismLogin.vue';
import OrganismRegister, { RegisterComplete } from '@/components/organisms/login-registration/OrganismRegister.vue';
import OrganismForgotPassword from '@/components/organisms/login-registration/OrganismForgotPassword.vue';
import OrganismResetPassword from '@/components/organisms/login-registration/OrganismResetPassword.vue';
import OrganismVerificationSent from '@/components/organisms/login-registration/OrganismVerificationSent.vue';
import OrganismVerifyEmail from '@/components/organisms/login-registration/OrganismVerifyEmail.vue';

export default Vue.extend({
  name: 'TemplateLogin',
  components: {
    AtomButton,
    AtomText,
    OrganismLogin,
    OrganismRegister,
    OrganismForgotPassword,
    OrganismResetPassword,
    OrganismVerificationSent,
    OrganismVerifyEmail,
  },
  data: () => ({
    ButtonVariant,
    email: '',
    verifyEmailActive: false,
    newsletterError: false,
  }),
  methods: {
    emailUpdateHandler(update: RegisterComplete) {
      const { email, newsletterError } = update;
      this.email = email;
      this.newsletterError = newsletterError;
      this.verifyEmailActive = true;
    },
    hideVerifyEmailActive() {
      this.verifyEmailActive = false;
    },
    redirectToFAQ() {
      // Note: at the moment twogo.com FAQ is only available in 2 languages
      let link = 'https://www.twogo.com/de/privatpendler/';
      if (this.$i18n.locale !== 'de') {
        link = 'https://www.twogo.com/en/private-commuting/';
      }
      window.open(link);
    },
  },
  computed: {
    loginLink(): string {
      return `/${this.$i18n.locale}/login`;
    },
    registerLink(): string {
      return `/${this.$i18n.locale}/register`;
    },
    loginActive(): boolean {
      return this.$route.path.indexOf('login') >= 0;
    },
    registerActive(): boolean {
      return this.$route.path.indexOf('register') >= 0 && !this.verifyEmailActive;
    },
    verifyEmailScreenActive(): boolean {
      return this.$route.path.indexOf('register') >= 0;
    },
    forgotPasswordActive(): boolean {
      return this.$route.path.indexOf('forgotpassword') >= 0;
    },
    resetPasswordActive(): boolean {
      return this.$route.path.indexOf('resetpassword') >= 0;
    },
    verificationSentActive(): boolean {
      return (
        this.$route.path.indexOf('verification') >= 0 && this.$route.path.indexOf('resend') >= 0
      );
    },
  },
});
